var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col relative" },
    [
      _c(
        "div",
        { staticClass: "preview-button-card-right" },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                right: "10px",
                top: "10px",
                "z-index": "99",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "close-box flex justify-center items-center",
                  staticStyle: { background: "none" },
                },
                [
                  _c("close-icon", {
                    attrs: {
                      width: 25,
                      height: 25,
                      color: _vm.dialog.step2ButtonColor,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.showDialogDefaultMedia
            ? [
                _vm.dialog && !_vm.dialog.showVideo
                  ? _c(
                      "div",
                      { staticClass: "preview-button-card-right-image" },
                      [_c("img", { attrs: { src: _vm.imageURL } })]
                    )
                  : _c(
                      "div",
                      { staticClass: "preview-button-card-right-video" },
                      [
                        _vm.embedVideoURL
                          ? _c(
                              "video",
                              {
                                key: _vm.mediaKey,
                                staticStyle: {
                                  "border-radius": "13px",
                                  "object-fit": "cover",
                                  width: "280px",
                                  height: "566px",
                                  position: "relative",
                                  top: "0",
                                  left: "0",
                                },
                                attrs: {
                                  playsinline: "",
                                  autoplay: "",
                                  muted: "",
                                  loop: "",
                                },
                                domProps: { muted: true },
                              },
                              [
                                _vm.embedVideoURL.indexOf(".webm") > -1
                                  ? _c("source", {
                                      attrs: {
                                        src: _vm.embedVideoURL,
                                        type: _vm.webm,
                                      },
                                    })
                                  : _vm._e(),
                                _vm.embedVideoURL.indexOf(".mp4") > -1
                                  ? _c("source", {
                                      attrs: {
                                        src: _vm.embedVideoURL,
                                        type: _vm.mp4,
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
              ]
            : [
                _vm.mediaImg
                  ? [
                      _vm.mediaImg.indexOf(".webm") > -1 ||
                      _vm.mediaImg.indexOf(".mp4") > -1
                        ? _c(
                            "div",
                            { staticClass: "preview-button-card-right-video" },
                            [
                              _vm.id === "s2-display-options" &&
                              _vm.dialog.activateVideoSound
                                ? _c(
                                    "video",
                                    {
                                      key: _vm.uploadType,
                                      ref: "embedVideo",
                                      staticClass: "welcome-screen-media",
                                      staticStyle: {
                                        "border-radius": "13px",
                                        "object-fit": "cover",
                                        width: "280px",
                                        height: "566px",
                                        position: "relative",
                                        top: "0",
                                        left: "0",
                                      },
                                      attrs: {
                                        id: _vm.id + "-welcome-screen",
                                        autoplay: "",
                                      },
                                    },
                                    [
                                      _vm.mediaImg.indexOf(".webm") > -1
                                        ? _c("source", {
                                            attrs: {
                                              src: _vm.mediaImg,
                                              type: _vm.webm,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.mediaImg.indexOf(".mp4") > -1
                                        ? _c("source", {
                                            attrs: {
                                              src: _vm.mediaImg,
                                              type: _vm.mp4,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : _c(
                                    "video",
                                    {
                                      key: _vm.uploadType,
                                      ref: "embedVideo",
                                      staticClass: "welcome-screen-media",
                                      staticStyle: {
                                        "border-radius": "13px",
                                        "object-fit": "cover",
                                        width: "280px",
                                        height: "566px",
                                        position: "relative",
                                        top: "0",
                                        left: "0",
                                      },
                                      attrs: {
                                        id: _vm.id + "-welcome-screen",
                                        playsinline: "",
                                        autoplay: "",
                                        muted: "",
                                        loop: "",
                                      },
                                      domProps: { muted: true },
                                    },
                                    [
                                      _vm.mediaImg.indexOf(".webm") > -1
                                        ? _c("source", {
                                            attrs: {
                                              src: _vm.mediaImg,
                                              type: _vm.webm,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.mediaImg.indexOf(".mp4") > -1
                                        ? _c("source", {
                                            attrs: {
                                              src: _vm.mediaImg,
                                              type: _vm.mp4,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "preview-button-card-right-image" },
                            [_c("img", { attrs: { src: _vm.mediaImg } })]
                          ),
                    ]
                  : _vm._e(),
              ],
          _c(
            "div",
            {
              staticClass: "preview-button-card-right-content",
              style: _vm.step2TextColor,
            },
            [
              _c("div", {
                staticClass: "flex preview-button-card-right-content-step2",
                style: _vm.chatSelectionStyle,
                domProps: { innerHTML: _vm._s(_vm.dialogTextStep2) },
              }),
              _vm.showRoutingScreenPreview
                ? _c(
                    "div",
                    { staticStyle: { width: "280px" } },
                    _vm._l(
                      _vm.routeCommunicationOptions,
                      function (row, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "preview-button-card-routing",
                            staticStyle: { padding: "0px 17px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "button-container" },
                              _vm._l(row, function (item, i) {
                                return _c(
                                  "button",
                                  {
                                    key: i,
                                    staticClass:
                                      "preview-button-card-right-content-action-button",
                                    style: Object.assign(
                                      {},
                                      { width: item.width },
                                      _vm.step2ButtonColor,
                                      _vm.step2IconColor
                                    ),
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.displayText) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _c(
                    "div",
                    {
                      staticClass: "flex justify-evenly",
                      staticStyle: {
                        width: "280px",
                        "justify-content": "space-evenly",
                        padding: "0px 17px",
                      },
                    },
                    [
                      !_vm.dialog.communicationOptions ||
                      _vm.dialog.communicationOptions.chat
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "preview-button-card-right-content-action-button flex flex-column justify-center items-center",
                              style: _vm.step2ButtonColor,
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("chat-icon-2", {
                                    attrs: {
                                      width: 20,
                                      height: 20,
                                      color: _vm.dialog.step2IconColor,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { style: _vm.step2IconColor }, [
                                _vm._v("Chat"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.dialog.communicationOptions ||
                      _vm.dialog.communicationOptions.audio
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "preview-button-card-right-content-action-button flex flex-column justify-center items-center",
                              style: _vm.step2ButtonColor,
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("vertical-phone-icon", {
                                    attrs: {
                                      width: 20,
                                      height: 20,
                                      color: _vm.dialog.step2IconColor,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { style: _vm.step2IconColor }, [
                                _vm._v("Phone"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.dialog.communicationOptions ||
                      _vm.dialog.communicationOptions.video
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "preview-button-card-right-content-action-button flex flex-column justify-center items-center",
                              style: _vm.step2ButtonColor,
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("video-call-icon", {
                                    attrs: {
                                      width: 20,
                                      height: 20,
                                      color: _vm.dialog.step2IconColor,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { style: _vm.step2IconColor }, [
                                _vm._v("Video"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
              _vm.dialog.isMoreInformationEnabled
                ? _c(
                    "div",
                    {
                      staticClass: "flex justify-center items-center",
                      staticStyle: { width: "280px", "margin-top": "20px" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.dialog.moreInformationLink,
                            target: "_blank",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "preview-button-card-right-content-more-information flex justify-center items-center",
                              style: _vm.moreInformationColor,
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.dialogMoreInformationButtonText ||
                                      _vm.$t("vue.moreInformation")
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        2
      ),
      _c("powered-by", { staticClass: "absolute bottom-0" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }