var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configure-dialog-step-six" }, [
    _c("div", { staticClass: "flex-box-column-start" }, [
      _c(
        "div",
        { staticClass: "vx-row w-full" },
        [
          _c(
            "vs-col",
            {
              staticClass: "flex-box-row-start w-full",
              attrs: { "vs-w": "12" },
            },
            [
              _c("div", { staticClass: "headingfont mr-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.contactForm"))),
              ]),
              _c(
                "vx-tooltip",
                {
                  attrs: { text: _vm.$t("info.contactForm"), position: "top" },
                },
                [_c("img", { attrs: { src: _vm.infoImg } })]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "flex-view" }, [
      _c(
        "div",
        {
          staticClass: "mt-5 mr-6 flex flex-column flex-grow",
          staticStyle: { "max-width": "640px" },
        },
        [
          _vm.canUpdateTextFields
            ? _c("div", [
                _vm._v(_vm._s(_vm.$t("vue.messageIfNobodyAvailable"))),
              ])
            : _vm._e(),
          !_vm.canUpdateTextFields
            ? _c("div", { staticClass: "w-full bold" }, [
                _vm._v(
                  _vm._s(_vm.$t("vue.messageIfNobodyAvailable")) + " (English)"
                ),
              ])
            : _vm._e(),
          _c("vs-textarea", {
            staticClass: "w-full p-1",
            attrs: { placeholder: "", counter: "300", height: "100px" },
            model: {
              value: _vm.contactFormMessage,
              callback: function ($$v) {
                _vm.contactFormMessage = $$v
              },
              expression: "contactFormMessage",
            },
          }),
          !_vm.canUpdateTextFields
            ? _c("div", { staticClass: "w-full bold" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("vue.messageIfNobodyAvailable")) +
                    " (" +
                    _vm._s(this.selectedLanguageForWebConnect.lnFullText) +
                    ")\n      "
                ),
              ])
            : _vm._e(),
          !_vm.canUpdateTextFields
            ? _c("vs-textarea", {
                staticClass: "w-full p-1",
                attrs: { placeholder: "", counter: "300", height: "100px" },
                model: {
                  value: _vm.tWebConnectContactFormText,
                  callback: function ($$v) {
                    _vm.tWebConnectContactFormText = $$v
                  },
                  expression: "tWebConnectContactFormText",
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "feedback-form-colors flex flex-row flex-wrap justify-between w-full",
            },
            [
              _c("div", { staticClass: "flex flex-column flex-grow" }, [
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("vue.contactFormBackgroundColor"))),
                ]),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.backgroundColor,
                        expression: "backgroundColor",
                      },
                    ],
                    staticClass: "pa-color-picker",
                    attrs: {
                      type: "color",
                      name: "contactFormBackgroundColor",
                    },
                    domProps: { value: _vm.backgroundColor },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.backgroundColor = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "flex flex-column flex-grow" }, [
                _c("div", [_vm._v(_vm._s(_vm.$t("vue.fontColor")))]),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fontColor,
                        expression: "fontColor",
                      },
                    ],
                    staticClass: "pa-color-picker",
                    attrs: { type: "color", name: "contactFormFontColor" },
                    domProps: { value: _vm.fontColor },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.fontColor = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "mt-4 flex flex-row items-center justify-center",
              class: !_vm.HAS_SCHEDULING_ACCESS ? "feature-disabled" : "",
            },
            [
              _c(
                "div",
                { staticClass: "mt-4 flex justify-center items-center" },
                [
                  _vm.HAS_SCHEDULING_ACCESS
                    ? _c(
                        "div",
                        {
                          staticClass: "mr-2",
                          on: {
                            click: function ($event) {
                              _vm.contactFormIsBookingEnabled =
                                !_vm.contactFormIsBookingEnabled
                            },
                          },
                        },
                        [
                          _c("toggle-switch-icon", {
                            attrs: {
                              enabled: _vm.contactFormIsBookingEnabled,
                              width: 40,
                              height: 40,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "mr-2" }, [
                    _vm._v(_vm._s(_vm.$t("vue.meetingSlotBooking"))),
                  ]),
                  _c(
                    "div",
                    [
                      _vm.HAS_SCHEDULING_ACCESS
                        ? _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text: _vm.$t("info.meetingSlotBooking"),
                                position: "top",
                              },
                            },
                            [_c("img", { attrs: { src: _vm.infoImg } })]
                          )
                        : _c(
                            "vx-tooltip",
                            {
                              staticStyle: { height: "20px" },
                              attrs: {
                                text: _vm.$t("upgrade-messages.locked-feature"),
                                position: "top",
                              },
                            },
                            [_c("small-lock-icon")],
                            1
                          ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm.contactFormIsBookingEnabled && _vm.HAS_SCHEDULING_ACCESS
            ? _c(
                "div",
                { staticClass: "mt-4 flex items-center" },
                [
                  _c("div", { staticClass: "mr-2" }, [
                    _vm._v(_vm._s(_vm.$t("vue.bookableAppointments"))),
                  ]),
                  _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: _vm.$t("info.bookableAppointments"),
                        position: "top",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.infoImg } })]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.contactFormIsBookingEnabled && _vm.HAS_SCHEDULING_ACCESS
            ? _c("v-select", {
                staticClass: "mb-4 md:mb-0 w-full",
                attrs: {
                  options: _vm.appointments,
                  clearable: false,
                  dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  disabled: !_vm.HAS_SCHEDULING_ACCESS,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(option.label) +
                                "\n          "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "no-options",
                      fn: function () {
                        return [_vm._v(_vm._s(""))]
                      },
                      proxy: true,
                    },
                    {
                      key: "list-footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "configure-dialog-step-six__select-link-message",
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({
                                    name: "company-setup",
                                    params: { action: "create-appointment" },
                                  })
                                },
                              },
                            },
                            [
                              _c("CalendarIcon", {
                                staticClass:
                                  "configure-dialog-step-six__select-link-message__icon",
                                attrs: {
                                  color: "#3B86F7",
                                  width: 13,
                                  height: 13,
                                },
                              }),
                              _vm._v(
                                "\n\n            " +
                                  _vm._s(
                                    _vm.$t("vue.createNewBookableAppointments")
                                  ) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1644341396
                ),
                model: {
                  value: _vm.selectedAppointment,
                  callback: function ($$v) {
                    _vm.selectedAppointment = $$v
                  },
                  expression: "selectedAppointment",
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "mt-4" }, [
            _vm._v(_vm._s(_vm.$t("vue.contactFormFields"))),
          ]),
          _c(
            "div",
            {
              staticClass:
                "contact-form-fields flex flex-row flex-wrap justify-between w-full",
            },
            [
              _c("div", { staticClass: "mt-2 flex flex-column" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "contact-form-fields-item flex-grow flex items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "contact-form-fields-item-text" },
                      [_vm._v(_vm._s(_vm.$t("vue.name")))]
                    ),
                    !_vm.contactFormIsBookingEnabled
                      ? _c("div", { staticStyle: { "margin-left": "auto" } }, [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.toggleFields("name")
                                },
                              },
                            },
                            [
                              _c("toggle-switch-icon", {
                                attrs: {
                                  enabled: _vm.contactFormFields.name.enabled,
                                  width: 40,
                                  height: 40,
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        attrs: { disabled: _vm.contactFormIsBookingEnabled },
                        model: {
                          value: _vm.contactFormFields.name.required,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contactFormFields.name,
                              "required",
                              $$v
                            )
                          },
                          expression: "contactFormFields.name.required",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "contact-form-fields-item-required" },
                          [_vm._v(_vm._s(_vm.$t("vue.requiredInformation")))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "mt-2 flex flex-column" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "contact-form-fields-item flex-grow flex items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "contact-form-fields-item-text" },
                      [_vm._v(_vm._s(_vm.$t("vue.email")))]
                    ),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.contactFormFields.email.required,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contactFormFields.email,
                              "required",
                              $$v
                            )
                          },
                          expression: "contactFormFields.email.required",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "contact-form-fields-item-required" },
                          [_vm._v(_vm._s(_vm.$t("vue.requiredInformation")))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm.contactFormIsBookingEnabled && _vm.HAS_SCHEDULING_ACCESS
                ? _c("div", { staticClass: "mt-2 flex flex-column" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "contact-form-fields-item flex-grow flex items-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "contact-form-fields-item-text" },
                          [_vm._v(_vm._s(_vm.$t("vue.date")))]
                        ),
                        !_vm.contactFormIsBookingEnabled
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "auto" } },
                              [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.contactFormFields.date.enabled =
                                          !_vm.contactFormFields.date.enabled
                                      },
                                    },
                                  },
                                  [
                                    _c("toggle-switch-icon", {
                                      attrs: {
                                        enabled:
                                          _vm.contactFormFields.date.enabled,
                                        width: 40,
                                        height: 40,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            attrs: {
                              disabled: _vm.contactFormIsBookingEnabled,
                            },
                            model: {
                              value: _vm.contactFormFields.date.required,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contactFormFields.date,
                                  "required",
                                  $$v
                                )
                              },
                              expression: "contactFormFields.date.required",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "contact-form-fields-item-required",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("vue.requiredInformation"))
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              !_vm.contactFormIsBookingEnabled
                ? _c("div", { staticClass: "mt-2 flex flex-column" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "contact-form-fields-item flex-grow flex items-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "contact-form-fields-item-text" },
                          [_vm._v(_vm._s(_vm.$t("vue.phone")))]
                        ),
                        _c("div", { staticStyle: { "margin-left": "auto" } }, [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.contactFormFields.phone.enabled =
                                    !_vm.contactFormFields.phone.enabled
                                },
                              },
                            },
                            [
                              _c("toggle-switch-icon", {
                                attrs: {
                                  enabled: _vm.contactFormFields.phone.enabled,
                                  width: 40,
                                  height: 40,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            model: {
                              value: _vm.contactFormFields.phone.required,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contactFormFields.phone,
                                  "required",
                                  $$v
                                )
                              },
                              expression: "contactFormFields.phone.required",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "contact-form-fields-item-required",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("vue.requiredInformation"))
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "mt-2 flex flex-column" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "contact-form-fields-item flex-grow flex items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "contact-form-fields-item-text" },
                      [_vm._v(_vm._s(_vm.$t("inputs.country")))]
                    ),
                    _c("div", { staticStyle: { "margin-left": "auto" } }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              _vm.contactFormFields.country.enabled =
                                !_vm.contactFormFields.country.enabled
                            },
                          },
                        },
                        [
                          _c("toggle-switch-icon", {
                            attrs: {
                              enabled: _vm.contactFormFields.country.enabled,
                              width: 40,
                              height: 40,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        model: {
                          value: _vm.contactFormFields.country.required,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contactFormFields.country,
                              "required",
                              $$v
                            )
                          },
                          expression: "contactFormFields.country.required",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "contact-form-fields-item-required" },
                          [_vm._v(_vm._s(_vm.$t("vue.requiredInformation")))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "mt-2 flex flex-column" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "contact-form-fields-item flex-grow flex items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "contact-form-fields-item-text" },
                      [_vm._v(_vm._s(_vm.$t("inputs.company")))]
                    ),
                    _c("div", { staticStyle: { "margin-left": "auto" } }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              _vm.contactFormFields.company.enabled =
                                !_vm.contactFormFields.company.enabled
                            },
                          },
                        },
                        [
                          _c("toggle-switch-icon", {
                            attrs: {
                              enabled: _vm.contactFormFields.company.enabled,
                              width: 40,
                              height: 40,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        model: {
                          value: _vm.contactFormFields.company.required,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contactFormFields.company,
                              "required",
                              $$v
                            )
                          },
                          expression: "contactFormFields.company.required",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "contact-form-fields-item-required" },
                          [_vm._v(_vm._s(_vm.$t("vue.requiredInformation")))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "mt-2 flex flex-column" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "contact-form-fields-item flex-grow flex items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "contact-form-fields-item-text" },
                      [_vm._v(_vm._s(_vm.$t("inputs.message")))]
                    ),
                    _c("div", { staticStyle: { "margin-left": "auto" } }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              _vm.contactFormFields.message.enabled =
                                !_vm.contactFormFields.message.enabled
                            },
                          },
                        },
                        [
                          _c("toggle-switch-icon", {
                            attrs: {
                              enabled: _vm.contactFormFields.message.enabled,
                              width: 40,
                              height: 40,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        model: {
                          value: _vm.contactFormFields.message.required,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.contactFormFields.message,
                              "required",
                              $$v
                            )
                          },
                          expression: "contactFormFields.message.required",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "contact-form-fields-item-required" },
                          [_vm._v(_vm._s(_vm.$t("vue.requiredInformation")))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "mt-4 flex flex-row items-center justify-center" },
            [
              _c("div", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.$t("vue.privacyInformation"))),
              ]),
              _c(
                "div",
                { staticClass: "ml-2" },
                [
                  _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: _vm.$t("info.privacyInformation"),
                        position: "top",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.infoImg } })]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "mt-4 flex items-center" }, [
            _c("div", { staticClass: "mr-2" }, [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.contactFormIsPrivacyInformationEnabled =
                        !_vm.contactFormIsPrivacyInformationEnabled
                    },
                  },
                },
                [
                  _c("toggle-switch-icon", {
                    attrs: {
                      enabled: _vm.contactFormIsPrivacyInformationEnabled,
                      width: 40,
                      height: 40,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mr-2" }, [
              _vm._v(_vm._s(_vm.$t("vue.enablePrivacyInformation"))),
            ]),
          ]),
          _vm.contactFormIsPrivacyInformationEnabled
            ? _c(
                "div",
                { staticClass: "mt-4 mr-2 w-full" },
                [
                  _vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full" }, [
                        _vm._v(_vm._s(_vm.$t("vue.privacyInformationText"))),
                      ])
                    : _vm._e(),
                  !_vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full bold" }, [
                        _vm._v(
                          _vm._s(_vm.$t("vue.privacyInformationText")) +
                            " (English)"
                        ),
                      ])
                    : _vm._e(),
                  _c("quill-editor", {
                    ref: "quillEditorA",
                    attrs: { options: _vm.editorOption },
                    model: {
                      value: _vm.contactFormPrivacyInformationHtml,
                      callback: function ($$v) {
                        _vm.contactFormPrivacyInformationHtml = $$v
                      },
                      expression: "contactFormPrivacyInformationHtml",
                    },
                  }),
                  !_vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full bold" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("vue.privacyInformationText")) +
                            " (" +
                            _vm._s(
                              this.selectedLanguageForWebConnect.lnFullText
                            ) +
                            ")\n        "
                        ),
                      ])
                    : _vm._e(),
                  !_vm.canUpdateTextFields
                    ? _c("quill-editor", {
                        ref: "quillEditorA",
                        attrs: { options: _vm.editorOption },
                        model: {
                          value: _vm.tWebConnectPrivacyInformationHtml,
                          callback: function ($$v) {
                            _vm.tWebConnectPrivacyInformationHtml = $$v
                          },
                          expression: "tWebConnectPrivacyInformationHtml",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "mt-4 flex flex-row items-center justify-center" },
            [
              _c("div", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.$t("vue.sendMessageEndMessage"))),
              ]),
              _c(
                "div",
                { staticClass: "ml-2" },
                [
                  _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: _vm.$t("info.sendMessageEndMessage"),
                        position: "top",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.infoImg } })]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "mt-4 flex items-center" }, [
            _c("div", { staticClass: "mr-2" }, [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.showSendMessagePreview = !_vm.showSendMessagePreview
                    },
                  },
                },
                [
                  _c("toggle-switch-icon", {
                    attrs: {
                      enabled: _vm.showSendMessagePreview,
                      width: 40,
                      height: 40,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mr-2" }, [
              _vm._v(_vm._s(_vm.$t("vue.showPreview"))),
            ]),
          ]),
          _vm.canUpdateTextFields
            ? _c("div", { staticClass: "mt-4 w-full" }, [
                _vm._v(_vm._s(_vm.$t("inputs.title"))),
              ])
            : _vm._e(),
          !_vm.canUpdateTextFields
            ? _c("div", { staticClass: "mt-4 w-full bold" }, [
                _vm._v(_vm._s(_vm.$t("inputs.title")) + " (English)"),
              ])
            : _vm._e(),
          _c("vs-input", {
            staticClass: "w-full mr-2",
            model: {
              value: _vm.contactFormSendMessageTitle,
              callback: function ($$v) {
                _vm.contactFormSendMessageTitle = $$v
              },
              expression: "contactFormSendMessageTitle",
            },
          }),
          !_vm.canUpdateTextFields
            ? _c("div", { staticClass: "w-ful bold" }, [
                _vm._v(
                  _vm._s(_vm.$t("inputs.title")) +
                    " (" +
                    _vm._s(this.selectedLanguageForWebConnect.lnFullText) +
                    ")"
                ),
              ])
            : _vm._e(),
          !_vm.canUpdateTextFields
            ? _c("vs-input", {
                staticClass: "w-full bold mr-2",
                model: {
                  value: _vm.tWebConnectSendMessageDefaultTitle,
                  callback: function ($$v) {
                    _vm.tWebConnectSendMessageDefaultTitle = $$v
                  },
                  expression: "tWebConnectSendMessageDefaultTitle",
                },
              })
            : _vm._e(),
          _vm.canUpdateTextFields
            ? _c("div", { staticClass: "mt-4 w-full" }, [
                _vm._v(_vm._s(_vm.$t("inputs.message"))),
              ])
            : _vm._e(),
          !_vm.canUpdateTextFields
            ? _c("div", { staticClass: "mt-4 w-full bold" }, [
                _vm._v(_vm._s(_vm.$t("inputs.message")) + " (English)"),
              ])
            : _vm._e(),
          _c("vs-textarea", {
            staticClass: "w-full p-1",
            attrs: { counter: "300", height: "100px" },
            model: {
              value: _vm.contactFormSendMessageCopy,
              callback: function ($$v) {
                _vm.contactFormSendMessageCopy = $$v
              },
              expression: "contactFormSendMessageCopy",
            },
          }),
          !_vm.canUpdateTextFields
            ? _c("div", { staticClass: "w-full bold" }, [
                _vm._v(
                  _vm._s(_vm.$t("inputs.message")) +
                    " (" +
                    _vm._s(this.selectedLanguageForWebConnect.lnFullText) +
                    ")"
                ),
              ])
            : _vm._e(),
          !_vm.canUpdateTextFields
            ? _c("vs-textarea", {
                staticClass: "w-full p-1",
                attrs: { counter: "300", height: "100px" },
                model: {
                  value: _vm.tWebConnectSendMessageDefaultCopy,
                  callback: function ($$v) {
                    _vm.tWebConnectSendMessageDefaultCopy = $$v
                  },
                  expression: "tWebConnectSendMessageDefaultCopy",
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "mt-4 flex flex-row items-center justify-center" },
            [
              _c("div", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.$t("vue.contactFormInsideChatTitle"))),
              ]),
            ]
          ),
          _c("div", { staticClass: "mt-4 flex items-center" }, [
            _c("div", { staticClass: "mr-2" }, [_vm._v("Custom")]),
            _c("div", { staticClass: "mr-2" }, [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.contactFormIsDefaultContactFormInsideChat =
                        !_vm.contactFormIsDefaultContactFormInsideChat
                    },
                  },
                },
                [
                  _c("toggle-switch-icon", {
                    attrs: {
                      enabled: _vm.contactFormIsDefaultContactFormInsideChat,
                      width: 40,
                      height: 40,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mr-2" }, [_vm._v("Default")]),
          ]),
          _vm.contactFormIsBookingEnabled && _vm.HAS_SCHEDULING_ACCESS
            ? _c(
                "div",
                {
                  staticClass: "mt-4 flex flex-row items-center justify-center",
                  class: !_vm.HAS_SCHEDULING_ACCESS ? "feature-disabled" : "",
                },
                [
                  _c("div", { staticClass: "headline" }, [
                    _vm._v(_vm._s(_vm.$t("vue.bookingSlotEndMessage"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-2" },
                    [
                      _vm.HAS_SCHEDULING_ACCESS
                        ? _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text: _vm.$t("info.bookingSlotEndMessage"),
                                position: "top",
                              },
                            },
                            [_c("img", { attrs: { src: _vm.infoImg } })]
                          )
                        : _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text: _vm.$t("upgrade-messages.locked-feature"),
                                position: "top",
                              },
                            },
                            [
                              _c("vs-icon", {
                                staticStyle: { display: "inline-block" },
                                attrs: { icon: "lock" },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.contactFormIsBookingEnabled && _vm.HAS_SCHEDULING_ACCESS
            ? _c("div", { staticClass: "mt-4 flex items-center" }, [
                _c("div", { staticClass: "mr-2" }, [
                  _c(
                    "div",
                    { on: { click: _vm.displayAppointmentBookedPreview } },
                    [
                      _c("toggle-switch-icon", {
                        attrs: {
                          enabled: _vm.showBookingSlotPreview,
                          width: 40,
                          height: 40,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "mr-2" }, [
                  _vm._v(_vm._s(_vm.$t("vue.showPreview"))),
                ]),
              ])
            : _vm._e(),
          _vm.contactFormIsBookingEnabled &&
          _vm.HAS_SCHEDULING_ACCESS &&
          _vm.canUpdateTextFields
            ? _c("div", { staticClass: "mt-4 w-full" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("inputs.title")) + "\n      "
                ),
              ])
            : _vm._e(),
          _vm.contactFormIsBookingEnabled &&
          _vm.HAS_SCHEDULING_ACCESS &&
          !_vm.canUpdateTextFields
            ? _c("div", { staticClass: "mt-4 w-full bold" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("inputs.title")) +
                    " (English)\n      "
                ),
              ])
            : _vm._e(),
          _vm.contactFormIsBookingEnabled && _vm.HAS_SCHEDULING_ACCESS
            ? _c("vs-input", {
                staticClass: "w-full mr-2",
                attrs: { disabled: !_vm.HAS_SCHEDULING_ACCESS },
                model: {
                  value: _vm.contactFormBookingSlotTitle,
                  callback: function ($$v) {
                    _vm.contactFormBookingSlotTitle = $$v
                  },
                  expression: "contactFormBookingSlotTitle",
                },
              })
            : _vm._e(),
          _vm.contactFormIsBookingEnabled &&
          _vm.HAS_SCHEDULING_ACCESS &&
          !_vm.canUpdateTextFields
            ? _c("div", { staticClass: "w-full bold" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("inputs.title")) +
                    " (" +
                    _vm._s(this.selectedLanguageForWebConnect.lnFullText) +
                    ")\n      "
                ),
              ])
            : _vm._e(),
          _vm.contactFormIsBookingEnabled &&
          _vm.HAS_SCHEDULING_ACCESS &&
          !_vm.canUpdateTextFields
            ? _c("vs-input", {
                staticClass: "w-full mr-2",
                attrs: { disabled: !_vm.HAS_SCHEDULING_ACCESS },
                model: {
                  value: _vm.tWebConnectBookingSlotDefaultTitle,
                  callback: function ($$v) {
                    _vm.tWebConnectBookingSlotDefaultTitle = $$v
                  },
                  expression: "tWebConnectBookingSlotDefaultTitle",
                },
              })
            : _vm._e(),
          _vm.contactFormIsBookingEnabled &&
          _vm.HAS_SCHEDULING_ACCESS &&
          _vm.canUpdateTextFields
            ? _c("div", { staticClass: "mt-4 w-full" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("inputs.message")) + "\n      "
                ),
              ])
            : _vm._e(),
          _vm.contactFormIsBookingEnabled &&
          _vm.HAS_SCHEDULING_ACCESS &&
          !_vm.canUpdateTextFields
            ? _c("div", { staticClass: "mt-4 bold w-full" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("inputs.message")) +
                    " (English)\n      "
                ),
              ])
            : _vm._e(),
          _vm.contactFormIsBookingEnabled && _vm.HAS_SCHEDULING_ACCESS
            ? _c("vs-textarea", {
                staticClass: "w-full p-1",
                class: !_vm.HAS_SCHEDULING_ACCESS ? "feature-disabled" : "",
                attrs: {
                  counter: "300",
                  height: "100px",
                  disabled: !_vm.HAS_SCHEDULING_ACCESS,
                },
                model: {
                  value: _vm.contactFormBookingSlotCopy,
                  callback: function ($$v) {
                    _vm.contactFormBookingSlotCopy = $$v
                  },
                  expression: "contactFormBookingSlotCopy",
                },
              })
            : _vm._e(),
          _vm.contactFormIsBookingEnabled &&
          _vm.HAS_SCHEDULING_ACCESS &&
          !_vm.canUpdateTextFields
            ? _c("div", { staticClass: "w-full bold" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("inputs.message")) +
                    " (" +
                    _vm._s(this.selectedLanguageForWebConnect.lnFullText) +
                    ")\n      "
                ),
              ])
            : _vm._e(),
          _vm.contactFormIsBookingEnabled &&
          _vm.HAS_SCHEDULING_ACCESS &&
          !_vm.canUpdateTextFields
            ? _c("vs-textarea", {
                staticClass: "w-full p-1",
                class: !_vm.HAS_SCHEDULING_ACCESS ? "feature-disabled" : "",
                attrs: {
                  name: "bookingslotMessage",
                  counter: "300",
                  height: "100px",
                  disabled: !_vm.HAS_SCHEDULING_ACCESS,
                },
                model: {
                  value: _vm.tWebConnectBookingSlotDefaultCopy,
                  callback: function ($$v) {
                    _vm.tWebConnectBookingSlotDefaultCopy = $$v
                  },
                  expression: "tWebConnectBookingSlotDefaultCopy",
                },
              })
            : _vm._e(),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("bookingslotMessage"),
                  expression: "errors.has('bookingslotMessage')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("bookingslotMessage")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex flex-column",
          staticStyle: { "margin-left": "auto" },
        },
        [
          _c("div", { staticClass: "flex" }, [
            _vm._v("\n        " + _vm._s(_vm.$t("vue.preview")) + "\n      "),
          ]),
          _vm.showContactFormPreview
            ? _c(
                "div",
                {
                  staticClass:
                    "relative flex flex-column overflow-hidden rounded-lg default-contact-form-preview",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex justify-center items-center",
                      style: _vm.contactFormBackgroundStyle,
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "message-container",
                          style: _vm.contactFormStyle,
                        },
                        [
                          _c(
                            "scroll-view",
                            {
                              ref: "chatScrollArea",
                              staticClass: "contact-form-scroll-area",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    right: "0px",
                                    top: "0px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "close-box flex justify-center items-center",
                                      style: _vm.closeButton,
                                    },
                                    [
                                      _c("close-icon", {
                                        attrs: {
                                          width: 16,
                                          height: 16,
                                          color: _vm.fontColor,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "message-container-title" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("vue.contactForm")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "mt-4 message-container-copy" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "white-space": "pre-line",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.dialogContactFormMessage)
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex mt-4 justify-center items-center",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "message-container-upper" },
                                    [_vm._v(_vm._s(_vm.$t("vue.sendAMessage")))]
                                  ),
                                  _vm.contactFormIsBookingEnabled &&
                                  _vm.HAS_SCHEDULING_ACCESS
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ml-2 mr-2 message-container-upper pointer",
                                          staticStyle: { "margin-top": "5px" },
                                          on: {
                                            click: function ($event) {
                                              _vm.isBookingEnabled =
                                                !_vm.isBookingEnabled
                                            },
                                          },
                                        },
                                        [
                                          _vm.isBookingEnabled
                                            ? _c("switch-on-icon", {
                                                attrs: {
                                                  color: "#12598D",
                                                  width: 41,
                                                  height: 20,
                                                },
                                              })
                                            : _vm._e(),
                                          !_vm.isBookingEnabled
                                            ? _c("switch-off-icon", {
                                                attrs: {
                                                  color: "#B2B2B2",
                                                  width: 41,
                                                  height: 20,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.contactFormIsBookingEnabled &&
                                  _vm.HAS_SCHEDULING_ACCESS
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "message-container-upper",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("vue.bookAMeeting"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.dialog.contactForm.fields.name
                                          .enabled,
                                      expression:
                                        "dialog.contactForm.fields.name.enabled",
                                    },
                                  ],
                                  staticClass: "mt-4 message-container-fields",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mr-2",
                                      staticStyle: { "margin-top": "1px" },
                                    },
                                    [
                                      _c("user-icon", {
                                        attrs: {
                                          width: 15,
                                          height: 15,
                                          color: "#262629",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "upper-case" }, [
                                    _vm._v(_vm._s(_vm.$t("vue.name"))),
                                  ]),
                                  _vm.dialog.contactForm.fields.name.required
                                    ? _c("span", { staticClass: "ml-1" }, [
                                        _vm._v("*"),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm.dialog.contactForm.fields.email.enabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 message-container-fields",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mr-2",
                                          staticStyle: { "margin-top": "1px" },
                                        },
                                        [
                                          _c("mail-icon", {
                                            attrs: {
                                              width: 15,
                                              height: 15,
                                              color: "#262629",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "upper-case" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("vue.emailAddress"))
                                        ),
                                      ]),
                                      _vm.dialog.contactForm.fields.email
                                        .required
                                        ? _c("span", { staticClass: "ml-1" }, [
                                            _vm._v("*"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.dialog.contactForm.fields.date.enabled &&
                              _vm.contactFormIsBookingEnabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 message-container-fields",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mr-2",
                                          staticStyle: { "margin-top": "1px" },
                                        },
                                        [
                                          _c("calendar-icon", {
                                            attrs: {
                                              width: 15,
                                              height: 15,
                                              color: "#262629",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "upper-case" }, [
                                        _vm._v(_vm._s(_vm.$t("vue.date"))),
                                      ]),
                                      _vm.dialog.contactForm.fields.date
                                        .required
                                        ? _c("span", { staticClass: "ml-1" }, [
                                            _vm._v("*"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.dialog.contactForm.fields.phone.enabled &&
                              !_vm.contactFormIsBookingEnabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 message-container-fields",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mr-2",
                                          staticStyle: { "margin-top": "1px" },
                                        },
                                        [
                                          _c("VerticalPhoneIcon", {
                                            attrs: {
                                              width: 15,
                                              height: 15,
                                              color: "#262629",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "upper-case" }, [
                                        _vm._v(_vm._s(_vm.$t("vue.phone"))),
                                      ]),
                                      _vm.dialog.contactForm.fields.phone
                                        .required
                                        ? _c("span", { staticClass: "ml-1" }, [
                                            _vm._v("*"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.dialog.contactForm.fields.country.enabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 message-container-fields",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mr-2",
                                          staticStyle: { "margin-top": "1px" },
                                        },
                                        [
                                          _c("FlagIcon", {
                                            attrs: {
                                              width: 15,
                                              height: 15,
                                              color: "#262629",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "upper-case" }, [
                                        _vm._v(_vm._s(_vm.$t("vue.country"))),
                                      ]),
                                      _vm.dialog.contactForm.fields.country
                                        .required
                                        ? _c("span", { staticClass: "ml-1" }, [
                                            _vm._v("*"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.dialog.contactForm.fields.company.enabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 message-container-fields",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mr-2",
                                          staticStyle: { "margin-top": "1px" },
                                        },
                                        [
                                          _c("CompanyIcon", {
                                            attrs: {
                                              width: 15,
                                              height: 15,
                                              color: "#262629",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "upper-case" }, [
                                        _vm._v(_vm._s(_vm.$t("vue.company"))),
                                      ]),
                                      _vm.dialog.contactForm.fields.company
                                        .required
                                        ? _c("span", { staticClass: "ml-1" }, [
                                            _vm._v("*"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.dialog.contactForm.fields.message.enabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 message-container-fields ht-100",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mr-2",
                                          staticStyle: { "margin-top": "1px" },
                                        },
                                        [
                                          _c("message-square-icon", {
                                            attrs: {
                                              width: 15,
                                              height: 15,
                                              color: "#262629",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "upper-case" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("inputs.message"))
                                        ),
                                      ]),
                                      _vm.dialog.contactForm.fields.message
                                        .required
                                        ? _c("span", { staticClass: "ml-1" }, [
                                            _vm._v("*"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.contactFormIsPrivacyInformationEnabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "flex flex-row",
                                      staticStyle: { "margin-top": "40px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("vs-checkbox", {
                                            staticClass:
                                              "configure-dialog-step-six-checkbox",
                                            style: {
                                              "--contact-form-darken-background":
                                                _vm.dialog.contactForm
                                                  .sendButtonBackgroundColor,
                                              "--contact-form-font-color":
                                                _vm.fontColor,
                                            },
                                            model: {
                                              value: _vm.hasAgreedToPrivacy,
                                              callback: function ($$v) {
                                                _vm.hasAgreedToPrivacy = $$v
                                              },
                                              expression: "hasAgreedToPrivacy",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", {
                                        staticClass:
                                          "message-container-privacy-policy",
                                        style: {
                                          "--contact-form-font-color":
                                            _vm.fontColor,
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.dialogContactFormPrivacyInformationHtml
                                          ),
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "message-container-action-button flex items-center justify-center",
                                  style:
                                    _vm.contactFormSendButtonBackgroundStyle,
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.isBookingEnabled
                                          ? _vm.$t("vue.bookMeeting")
                                          : _vm.$t("vue.sendMessage")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("powered-by"),
                ],
                1
              )
            : _vm._e(),
          _vm.showSendMessagePreview
            ? _c(
                "div",
                {
                  staticClass: "flex flex-column relative",
                  staticStyle: { "margin-left": "auto" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "20px",
                        top: "20px",
                        cursor: "pointer",
                      },
                    },
                    [_c("close-icon", { attrs: { width: 16, height: 16 } })],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex justify-center items-center",
                      style: _vm.contactFormFeedbackStyle,
                    },
                    [
                      _c("div", { staticClass: "feedback-message-container" }, [
                        _c(
                          "div",
                          { staticClass: "feedback-message-container-title" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.dialogContactFormSendMessageTitle) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-4 feedback-message-container-copy",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.dialogContactFormSendMessageCopy) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c("powered-by"),
                ],
                1
              )
            : _vm._e(),
          _vm.showBookingSlotPreview
            ? _c(
                "div",
                {
                  staticClass: "flex flex-column relative",
                  staticStyle: { "margin-left": "auto" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "20px",
                        top: "20px",
                        cursor: "pointer",
                      },
                    },
                    [_c("close-icon", { attrs: { width: 16, height: 16 } })],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex justify-center items-center",
                      style: _vm.contactFormFeedbackStyle,
                    },
                    [
                      _c("div", { staticClass: "feedback-message-container" }, [
                        _c(
                          "div",
                          { staticClass: "feedback-message-container-title" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.dialogBookingSlotDefaultTitle) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-4feedback-message-container-copy",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.dialogBookingSlotDefaultCopy) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c("powered-by"),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "flex mt-4" }, [
            _vm._v(
              "\n        " +
                _vm._s("Contact form inside chat preview") +
                "\n      "
            ),
          ]),
          _c("div", { staticClass: "relative flex flex-column" }, [
            _c(
              "div",
              { staticClass: "inside-chat-cf" },
              [
                _c(
                  "div",
                  {
                    staticClass: "inside-chat-cf__heading",
                    style: {
                      background: _vm.toolBarBackground,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "inside-chat-cf__heading__minimize" },
                      [_c("back-icon", { attrs: { color: "#fff" } })],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "inside-chat-cf__heading__title" },
                      [
                        _c("back-icon", { attrs: { color: "#fff" } }),
                        _c("span", [_vm._v("Let's Connect")]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "inside-chat-cf__heading__buttons" },
                      [
                        _c(
                          "div",
                          { staticClass: "inside-chat-cf__heading__button" },
                          [
                            _c("phone-call-icon", {
                              staticStyle: { "margin-right": "5px" },
                              attrs: { width: 15, color: "#262629" },
                            }),
                            _vm._v("Phone call\n              "),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "inside-chat-cf__heading__button" },
                          [
                            _c("video-call-icon", {
                              staticStyle: { "margin-right": "5px" },
                              attrs: { width: 15, color: "#262629" },
                            }),
                            _vm._v("Video call\n              "),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "inside-chat-cf__heading__endcall" },
                          [_c("phone-icon", { attrs: { color: "#fff" } })],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "inside-chat-cf__content",
                    style: {
                      "--contact-form-inside-background": _vm.dialog.contactForm
                        .isDefaultContactFormInsideChat
                        ? "#EFF1F5"
                        : _vm.toolBarBackground,
                    },
                  },
                  [
                    _c(
                      "scroll-view",
                      {
                        ref: "insideChatArea",
                        staticStyle: { height: "350px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "inside-chat-cf__content__first-message",
                          },
                          [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("vue.waitingRoomDefaultMessage")
                                ),
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "inside-chat-cf__content__second-message",
                          },
                          [
                            _c("span", {
                              staticClass: "flex",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("vue.waitingRoomVisitorMessage")
                                ),
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "flex justify-center items-center" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "message-container",
                                style: _vm.contactFormInsideChatStyle,
                              },
                              [
                                _c(
                                  "scroll-view",
                                  {
                                    ref: "chatScrollAreaView",
                                    staticClass:
                                      "inside-chat-cf__content--inner-scroll-view",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mt-4 message-container-copy",
                                        style:
                                          "color: " +
                                          (_vm.dialog.contactForm
                                            .isDefaultContactFormInsideChat
                                            ? "#262629"
                                            : "#fff"),
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.dialogContactFormMessage
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex mt-4 justify-center items-center",
                                      },
                                      [
                                        _vm.contactFormIsBookingEnabled &&
                                        _vm.HAS_SCHEDULING_ACCESS
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "message-container-upper",
                                                style:
                                                  "color: " +
                                                  (_vm.dialog.contactForm
                                                    .isDefaultContactFormInsideChat
                                                    ? "#262629"
                                                    : "#fff"),
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t("vue.sendAMessage")
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.contactFormIsBookingEnabled &&
                                        _vm.HAS_SCHEDULING_ACCESS
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ml-2 mr-2 message-container-upper pointer",
                                                staticStyle: {
                                                  "margin-top": "5px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isBookingEnabled =
                                                      !_vm.isBookingEnabled
                                                  },
                                                },
                                              },
                                              [
                                                _vm.isBookingEnabled
                                                  ? _c("switch-on-icon", {
                                                      attrs: {
                                                        color: _vm.dialog
                                                          .contactForm
                                                          .isDefaultContactFormInsideChat
                                                          ? "#494A4E"
                                                          : _vm.contactFormInsideChatSendButtonBackgroundColor,
                                                        width: 41,
                                                        height: 20,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                !_vm.isBookingEnabled
                                                  ? _c("switch-off-icon", {
                                                      attrs: {
                                                        color: "#B2B2B2",
                                                        width: 41,
                                                        height: 20,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.contactFormIsBookingEnabled &&
                                        _vm.HAS_SCHEDULING_ACCESS
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "message-container-upper",
                                                style:
                                                  "color: " +
                                                  (_vm.dialog.contactForm
                                                    .isDefaultContactFormInsideChat
                                                    ? "#262629"
                                                    : "#fff"),
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t("vue.bookAMeeting")
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.dialog.contactForm.fields.name
                                                .enabled,
                                            expression:
                                              "dialog.contactForm.fields.name.enabled",
                                          },
                                        ],
                                        staticClass:
                                          "mt-4 message-container-fields",
                                        style:
                                          "background: " +
                                          (_vm.dialog.contactForm
                                            .isDefaultContactFormInsideChat
                                            ? "#CFD2D7"
                                            : "rgba(230, 236, 241, 0.6)"),
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mr-2",
                                            staticStyle: {
                                              "margin-top": "1px",
                                            },
                                          },
                                          [
                                            _c("user-icon", {
                                              attrs: {
                                                width: 15,
                                                height: 15,
                                                color: "#262629",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "upper-case" },
                                          [_vm._v(_vm._s(_vm.$t("vue.name")))]
                                        ),
                                        _vm.dialog.contactForm.fields.name
                                          .required
                                          ? _c(
                                              "span",
                                              { staticClass: "ml-1" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm.dialog.contactForm.fields.email.enabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 message-container-fields",
                                            style:
                                              "background: " +
                                              (_vm.dialog.contactForm
                                                .isDefaultContactFormInsideChat
                                                ? "#CFD2D7"
                                                : "rgba(230, 236, 241, 0.6)"),
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mr-2",
                                                staticStyle: {
                                                  "margin-top": "1px",
                                                },
                                              },
                                              [
                                                _c("mail-icon", {
                                                  attrs: {
                                                    width: 15,
                                                    height: 15,
                                                    color: "#262629",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "upper-case" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("vue.emailAddress")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm.dialog.contactForm.fields.email
                                              .required
                                              ? _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [_vm._v("*")]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.dialog.contactForm.fields.date
                                      .enabled &&
                                    _vm.contactFormIsBookingEnabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 message-container-fields",
                                            style:
                                              "background: " +
                                              (_vm.dialog.contactForm
                                                .isDefaultContactFormInsideChat
                                                ? "#CFD2D7"
                                                : "rgba(230, 236, 241, 0.6)"),
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mr-2",
                                                staticStyle: {
                                                  "margin-top": "1px",
                                                },
                                              },
                                              [
                                                _c("calendar-icon", {
                                                  attrs: {
                                                    width: 15,
                                                    height: 15,
                                                    color: "#262629",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "upper-case" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("vue.date"))
                                                ),
                                              ]
                                            ),
                                            _vm.dialog.contactForm.fields.date
                                              .required
                                              ? _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [_vm._v("*")]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.dialog.contactForm.fields.phone
                                      .enabled &&
                                    !_vm.contactFormIsBookingEnabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 message-container-fields",
                                            style:
                                              "background: " +
                                              (_vm.dialog.contactForm
                                                .isDefaultContactFormInsideChat
                                                ? "#CFD2D7"
                                                : "rgba(230, 236, 241, 0.6)"),
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mr-2",
                                                staticStyle: {
                                                  "margin-top": "1px",
                                                },
                                              },
                                              [
                                                _c("VerticalPhoneIcon", {
                                                  attrs: {
                                                    width: 15,
                                                    height: 15,
                                                    color: "#262629",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "upper-case" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("vue.phone"))
                                                ),
                                              ]
                                            ),
                                            _vm.dialog.contactForm.fields.phone
                                              .required
                                              ? _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [_vm._v("*")]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.dialog.contactForm.fields.country
                                      .enabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 message-container-fields ht-100",
                                            style:
                                              "background: " +
                                              (_vm.dialog.contactForm
                                                .isDefaultContactFormInsideChat
                                                ? "#CFD2D7"
                                                : "rgba(230, 236, 241, 0.6)"),
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mr-2",
                                                staticStyle: {
                                                  "margin-top": "1px",
                                                },
                                              },
                                              [
                                                _c("flag-icon", {
                                                  attrs: {
                                                    width: 15,
                                                    height: 15,
                                                    color: "#262629",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "upper-case" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("inputs.country")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm.dialog.contactForm.fields
                                              .country.required
                                              ? _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [_vm._v("*")]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.dialog.contactForm.fields.company
                                      .enabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 message-container-fields ht-100",
                                            style:
                                              "background: " +
                                              (_vm.dialog.contactForm
                                                .isDefaultContactFormInsideChat
                                                ? "#CFD2D7"
                                                : "rgba(230, 236, 241, 0.6)"),
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mr-2",
                                                staticStyle: {
                                                  "margin-top": "1px",
                                                },
                                              },
                                              [
                                                _c("company-icon", {
                                                  attrs: {
                                                    width: 15,
                                                    height: 15,
                                                    color: "#262629",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "upper-case" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("inputs.company")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm.dialog.contactForm.fields
                                              .company.required
                                              ? _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [_vm._v("*")]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.dialog.contactForm.fields.message
                                      .enabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 message-container-fields ht-100",
                                            style:
                                              "background: " +
                                              (_vm.dialog.contactForm
                                                .isDefaultContactFormInsideChat
                                                ? "#CFD2D7"
                                                : "rgba(230, 236, 241, 0.6)"),
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mr-2",
                                                staticStyle: {
                                                  "margin-top": "1px",
                                                },
                                              },
                                              [
                                                _c("message-square-icon", {
                                                  attrs: {
                                                    width: 15,
                                                    height: 15,
                                                    color: "#262629",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "upper-case" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("inputs.message")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm.dialog.contactForm.fields
                                              .message.required
                                              ? _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [_vm._v("*")]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.contactFormIsPrivacyInformationEnabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "flex flex-row",
                                            staticStyle: {
                                              "margin-top": "40px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c("vs-checkbox", {
                                                  staticClass:
                                                    "configure-dialog-step-six-checkbox",
                                                  style: {
                                                    "--contact-form-darken-background":
                                                      _vm.contactFormInsideChatSendButtonBackgroundColor,
                                                    "--contact-form-font-color":
                                                      _vm.contactFormInsideChatSendButtonBackgroundColor,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.hasAgreedToPrivacy,
                                                    callback: function ($$v) {
                                                      _vm.hasAgreedToPrivacy =
                                                        $$v
                                                    },
                                                    expression:
                                                      "hasAgreedToPrivacy",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c("div", {
                                              staticClass:
                                                "message-container-privacy-policy",
                                              style:
                                                "color: " +
                                                (_vm.dialog.contactForm
                                                  .isDefaultContactFormInsideChat
                                                  ? "#262629"
                                                  : "#fff"),
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.dialogContactFormPrivacyInformationHtml
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "message-container-action-button flex items-center justify-center",
                                        staticStyle: { color: "#fff" },
                                        style:
                                          _vm.contactFormSendButtonInsideBackgroundStyle,
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.isBookingEnabled
                                                ? _vm.$t("vue.bookMeeting")
                                                : _vm.$t("vue.sendMessage")
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "inside-chat-cf__footer" }, [
                  _c(
                    "div",
                    {
                      staticClass: "inside-chat-cf__footer__toolbar",
                      style: {
                        background: _vm.toolBarBackground,
                      },
                    },
                    [
                      _c("clip-icon", {
                        staticClass: "toolbar-container-icon",
                        attrs: { width: 20, height: 20 },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "inside-chat-cf__footer__message",
                      class: {
                        "inside-chat-cf__footer__message--bottom-space":
                          !_vm.hasWhiteLabel,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "inside-chat-cf__footer__message__text",
                        },
                        [_vm._v(_vm._s(_vm.$t("vue.writeMessage")) + " ...")]
                      ),
                      _c("send-icon", {
                        attrs: {
                          width: 25,
                          height: 25,
                          color: _vm.toolBarBackground,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("powered-by", { staticClass: "absolute bottom-0" }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }