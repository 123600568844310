var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 35 47",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: { y: "23", width: "34", height: "23", rx: "6", fill: _vm.color },
      }),
      _c("path", {
        attrs: {
          d: "M25 46V44C25 42.9391 24.5786 41.9217 23.8284 41.1716C23.0783 40.4214 22.0609 40 21 40H13C11.9391 40 10.9217 40.4214 10.1716 41.1716C9.42143 41.9217 9 42.9391 9 44V46",
          stroke: "white",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17 36C19.2091 36 21 34.2091 21 32C21 29.7909 19.2091 28 17 28C14.7909 28 13 29.7909 13 32C13 34.2091 14.7909 36 17 36Z",
          stroke: "white",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34 14.3333C34 14.9227 33.7308 15.4879 33.2515 15.9047C32.7722 16.3214 32.1222 16.5556 31.4444 16.5556H16.1111L11 21V3.22222C11 2.63285 11.2692 2.06762 11.7485 1.65087C12.2278 1.23413 12.8778 1 13.5556 1H31.4444C32.1222 1 32.7722 1.23413 33.2515 1.65087C33.7308 2.06762 34 2.63285 34 3.22222V14.3333Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M34 14.3333C34 14.9227 33.7308 15.4879 33.2515 15.9047C32.7722 16.3214 32.1222 16.5556 31.4444 16.5556H16.1111L11 21V3.22222C11 2.63285 11.2692 2.06762 11.7485 1.65087C12.2278 1.23413 12.8778 1 13.5556 1H31.4444C32.1222 1 32.7722 1.23413 33.2515 1.65087C33.7308 2.06762 34 2.63285 34 3.22222V14.3333Z",
          stroke: _vm.color,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34 14.3333C34 14.9227 33.7308 15.4879 33.2515 15.9047C32.7722 16.3214 32.1222 16.5556 31.4444 16.5556H16.1111L11 21V3.22222C11 2.63285 11.2692 2.06762 11.7485 1.65087C12.2278 1.23413 12.8778 1 13.5556 1H31.4444C32.1222 1 32.7722 1.23413 33.2515 1.65087C33.7308 2.06762 34 2.63285 34 3.22222V14.3333Z",
          stroke: _vm.color,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }