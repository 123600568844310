var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style:
        "position: relative; display: inline-block; width: " +
        _vm.width +
        "px; height: " +
        _vm.height +
        "px",
    },
    [
      _c(
        "svg",
        {
          attrs: {
            width: _vm.width,
            height: _vm.height,
            viewBox: "0 0 22 23",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M18.9882 0.492188H3.0106C1.5439 0.492188 0.347656 1.71402 0.347656 3.17384V15.3752C0.347656 16.8732 1.5439 18.0568 3.0106 18.0568H7.005V21.5884C7.005 22.0065 7.46353 22.2493 7.80056 22.0007L12.9975 18.0186H18.9891C20.4558 18.0186 21.652 16.7967 21.652 15.3369V3.17384C21.6512 1.71402 20.4903 0.492188 18.9882 0.492188ZM6.43913 7.97192C5.70058 7.97192 5.14511 8.57753 5.14511 9.33188C5.14511 10.0862 5.73803 10.6918 6.43913 10.6918C7.14023 10.6918 7.73315 10.0862 7.73315 9.33188C7.73315 8.57753 7.17976 7.97192 6.43913 7.97192ZM11.0993 7.97192C10.3607 7.97192 9.76822 8.57753 9.76822 9.33188C9.76822 10.0862 10.3611 10.6918 11.0993 10.6918C11.8378 10.6918 12.3933 10.0862 12.3933 9.33188C12.3933 8.57753 11.8399 7.97192 11.0993 7.97192ZM14.4654 9.33188C14.4654 8.57753 15.0209 7.97192 15.7594 7.97192C16.5001 7.97192 17.0534 8.57753 17.0534 9.33188C17.0534 10.0862 16.4605 10.6918 15.7594 10.6918C15.0583 10.6918 14.4654 10.0862 14.4654 9.33188ZM19.0257 16.1316C19.3898 16.1316 19.6914 15.8235 19.6914 15.4517V3.21209C19.6914 2.84023 19.3898 2.53211 19.0257 2.53211H3.04804C2.68397 2.53211 2.38231 2.84023 2.38231 3.21209V15.4517C2.38231 15.8235 2.68397 16.1316 3.04804 16.1316H9.03966V18.6816L12.3683 16.1316H19.0257Z",
              fill: _vm.color,
            },
          }),
        ]
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }