var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        key: _vm.previewKey,
        staticClass: "pa-dialog-popup-container w-full",
        style:
          "background-image: url(" +
          _vm.previewUrl +
          ");min-width:" +
          _vm.buttonContainerMinWidth +
          "px;position:relative;",
        attrs: { id: "paPreviewEmbedCode" },
      },
      [
        _c("div", { staticClass: "blur-img" }),
        _c(
          "div",
          { staticClass: "pa-button-container" },
          [
            _vm.dialog &&
            _vm.dialog.type === "button" &&
            _vm.dialog.useNewButtonPlusTextStyle
              ? _c("button-plus-text-new-preview", {
                  attrs: {
                    isForPreviewScreen: true,
                    uploadType: _vm.uploadType,
                    uploadSubtype: _vm.uploadSubtype,
                    dialog: _vm.dialog,
                    canShowMinimizeIcon: _vm.canShowMinimizeIcon,
                    imageURL: _vm.imageURL,
                    embedVideoURL: _vm.embedVideoURL,
                    smallButtonImage: _vm.smallButtonImage,
                    isDisplayOptions: true,
                  },
                })
              : _c("button-preview", {
                  attrs: {
                    isForPreviewScreen: true,
                    uploadType: _vm.uploadType,
                    uploadSubtype: _vm.uploadSubtype,
                    dialog: _vm.dialog,
                    canShowMinimizeIcon: _vm.canShowMinimizeIcon,
                    imageURL: _vm.imageURL,
                    embedVideoURL: _vm.embedVideoURL,
                    smallButtonImage: _vm.smallButtonImage,
                    isDisplayOptions: true,
                  },
                }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }