<template>
  <div>
    <vs-popup v-if="showOwnMedia" fullscreen :active.sync="showOwnMedia" button-close-hidden class="media-popup">
      <div class="relative" :key="ownMediaKey">
        <div disabled class="popover-action flex justify-end items-center">
          <div class="popover-action-button mr-2" @click="onOwnMediaSave">
            <check-icon color="#00ff00" :width="35" :height="35"></check-icon>
          </div>
          <div class="popover-action-button ml-2" @click="showOwnMedia = false">
            <close-icon color="#ff0000" :width="35" :height="35"></close-icon>
          </div>
        </div>
        <div>
          <div class="media-popup__wrapper">
            <div class="media-popup__left">
              <div class="flex flex-row items-center media-popup__toggle">
                <div @click="toggleUseOneMediaForAllSteps">
                  <toggle-switch-icon :enabled="useOneMediaForAllSteps" :width="40" :height="40" />
                </div>
                <div class="media-popup__toggle-label">{{ $t('uploadMedia.useOneImageForAllSteps') }}</div>
                <vx-tooltip class="media-popup__toggle-tooltip" :text="$t('uploadMedia.useOneImageForAllStepsInfoIcon')" position="top">
                  <img :src="infoImg" class="media-popup__toggle-tooltip-img" />
                </vx-tooltip>
              </div>
              <template v-if="useOneMediaForAllSteps">
                <div class="media-popup__upload-holder">
                  <div class="media-popup__headline">
                    {{ $t('uploadMedia.allSteps') }}
                  </div>
                  <upload-image-or-video :uploadType="'allMedia'" v-if="!dialog.allMedia" @upload-image-or-video="onUploadImageOrVideo" />
                  <media-preview :ownMediaKey="ownMediaKey" :uploadType="'allMedia'" :dialog="dialog" @media-delete="() => deleteMedia('allMedia')" v-else />
                </div>
              </template>
              <template v-else>
                <template v-if="dialog && dialog.type === 'button' && dialog.useNewButtonPlusTextStyle">
                  <div class="media-popup__upload-holder">
                    <div class="media-popup__headline flex items-center">
                      {{ $t('vue.step1Headline2') }}
                      <vx-tooltip class="media-popup__toggle-tooltip ml-2" :text="$t('uploadMedia.step1ButtonInfoIcon')" position="top">
                        <img :src="infoImg" class="media-popup__toggle-tooltip-img" />
                      </vx-tooltip>
                    </div>
                    <upload-image-or-video
                      :uploadType="'buttonPlusTextNewMedia'"
                      v-if="!dialog.buttonPlusTextNewMedia"
                      @upload-image-or-video="onUploadImageOrVideo"
                    />
                    <media-preview
                      :ownMediaKey="ownMediaKey"
                      :uploadType="useOneMediaForAllSteps ? 'allMedia' : 'buttonPlusTextNewMedia'"
                      :dialog="dialog"
                      @media-delete="() => deleteMedia('buttonPlusTextNewMedia')"
                      v-else
                    />
                  </div>
                </template>
                <template v-else>
                  <div class="media-popup__upload-holder">
                    <div class="media-popup__headline flex items-center">
                      {{ dialog.type === 'button-card' ? $t('vue.step1Headline') : $t('vue.step1Headline2') }}
                      <vx-tooltip class="media-popup__toggle-tooltip ml-2" :text="$t('uploadMedia.step1ButtonInfoIcon')" position="top">
                        <img :src="infoImg" class="media-popup__toggle-tooltip-img" />
                      </vx-tooltip>
                    </div>
                    <upload-image-or-video :uploadType="'buttonMedia'" v-if="!dialog.buttonMedia" @upload-image-or-video="onUploadImageOrVideo" />
                    <media-preview
                      :ownMediaKey="ownMediaKey"
                      :uploadType="useOneMediaForAllSteps ? 'allMedia' : 'buttonMedia'"
                      :dialog="dialog"
                      @media-delete="() => deleteMedia('buttonMedia')"
                      v-else
                    />
                  </div>
                  <div class="media-popup__upload-holder">
                    <div class="media-popup__headline">
                      {{ $t('vue.step1SmallHeadline') }}
                    </div>
                    <upload-image-or-video :uploadType="'smallButtonMedia'" v-if="!dialog.smallButtonMedia" @upload-image-or-video="onUploadImageOrVideo" />
                    <media-preview
                      :ownMediaKey="ownMediaKey"
                      :uploadType="useOneMediaForAllSteps ? 'allMedia' : 'smallButtonMedia'"
                      :dialog="dialog"
                      @media-delete="() => deleteMedia('smallButtonMedia')"
                      v-else
                    />
                  </div>
                </template>
                <div class="media-popup__upload-holder">
                  <div class="media-popup__headline">{{ $t('vue.step2Headline') }}</div>
                  <upload-image-or-video :uploadType="'welcomeScreenMedia'" v-if="!dialog.welcomeScreenMedia" @upload-image-or-video="onUploadImageOrVideo" />
                  <media-preview
                    :ownMediaKey="ownMediaKey"
                    :uploadType="useOneMediaForAllSteps ? 'allMedia' : 'welcomeScreenMedia'"
                    :dialog="dialog"
                    @media-delete="() => deleteMedia('welcomeScreenMedia')"
                    v-else
                  />
                </div>
              </template>
            </div>
            <div class="media-popup__right">
              <div class="media-popup__title">Preview</div>
              <div class="flex w-full">
                <div class="flex items-start">
                  <div class="flex flex-col mr-6" v-if="dialog && dialog.type === 'button' && dialog.useNewButtonPlusTextStyle">
                    <button-plus-text-new-preview
                      :uploadType="useOneMediaForAllSteps ? 'allMedia' : 'buttonPlusTextNewMedia'"
                      :uploadSubtype="useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'buttonPlusTextNewMedia' : ''"
                      :dialog="dialog"
                      :imageURL="defaultImageURL"
                      :embedVideoURL="defaultVideoURL"
                    />
                  </div>
                  <div class="flex flex-col mr-6" v-else>
                    <div class="flex flex-col mb-8">
                      <div class="media-popup__headline">{{ dialog.type === 'button-card' ? $t('vue.step1Headline') : $t('vue.step1Headline2') }}</div>
                      <div class="flex flex-col">
                        <button-preview
                          :ownMediaKey="ownMediaKey"
                          :uploadType="useOneMediaForAllSteps ? 'allMedia' : 'buttonMedia'"
                          :uploadSubtype="useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'buttonMedia' : ''"
                          :dialog="dialog"
                          :imageURL="defaultImageURL"
                          :embedVideoURL="defaultVideoURL"
                        />
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <div class="media-popup__headline">{{ $t('vue.step1SmallHeadline') }}</div>
                      <button-preview
                        :ownMediaKey="ownMediaKey"
                        previewType="small"
                        :uploadType="useOneMediaForAllSteps ? 'allMedia' : 'smallButtonMedia'"
                        :uploadSubtype="useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'smallButtonMedia' : ''"
                        :dialog="dialog"
                        :imageURL="defaultImageURL"
                        :embedVideoURL="defaultVideoURL"
                      />
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <div class="media-popup__headline">{{ $t('vue.step2Headline') }}</div>
                    <div v-if="isWelcomeScreenMediaVideo" class="flex flex-row justify-start items-center">
                      <div @click="toggleActivateVideoSound">
                        <toggle-switch-icon :enabled="activateVideoSound" />
                      </div>
                      <div>
                        <volume-icon :height="12" class="ml-2" :stroke="activateVideoSound ? '#3B86F7' : '#d3d3d3'"></volume-icon>
                      </div>
                      <div class="ml-2">{{ $t('vue.activateVideoSound') }}</div>
                      <div class="ml-2" style="height: 17px">
                        <vx-tooltip :text="$t('info.activateVideoSound')">
                          <img :src="infoImg" width="17" height="17" />
                        </vx-tooltip>
                      </div>
                    </div>
                    <welcome-screen-preview
                      :id="`${id}-display-options`"
                      :uploadType="useOneMediaForAllSteps ? 'allMedia' : 'welcomeScreenMedia'"
                      :uploadSubtype="useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'welcomeScreenMedia' : ''"
                      :dialog="dialog"
                      :imageURL="defaultImageURL"
                      :embedVideoURL="defaultVideoURL"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
    <vs-popup :title="$t('vue.cropImage')" :active.sync="showCroppa" button-close-hidden class="image-popup">
      <div class="relative">
        <div disabled class="popover-action flex justify-end items-center">
          <div class="popover-action-button mr-2" @click="onCroppaSave">
            <check-icon color="#00ff00" :width="35" :height="35"></check-icon>
          </div>
          <div class="popover-action-button ml-2" @click="showCroppa = false">
            <close-icon color="#ff0000" :width="35" :height="35"></close-icon>
          </div>
        </div>
        <div class="croppa-uploaded-pics">
          <template v-if="uploadType === 'allMedia'">
            <croppa
              v-for="(type, i) in allMediaTypes"
              :key="i"
              v-model="allMediaTypes[i].myPhoto"
              :width="type.croppaWidth"
              :height="type.croppaHeight"
              placeholder="Click here"
              :show-remove-button="false"
              canvas-color="transparent"
              prevent-white-space
              :initial-image="initialImage"
              :image-border-radius="10"
              accept=".jpeg,.jpg,.gif,.png"
              :disable-drag-to-move="false"
              :file-size-limit="2048000"
              :placeholder-font-size="14"
              :quality="2"
              :disabled="false"
              class="resizable-croppa"
            ></croppa>
          </template>
          <template v-else>
            <croppa
              v-show="myPhoto.img"
              v-model="myPhoto"
              :width="croppaWidth"
              :height="croppaHeight"
              placeholder="Click here"
              :show-remove-button="false"
              canvas-color="transparent"
              prevent-white-space
              :initial-image="initialImage"
              :image-border-radius="10"
              accept=".jpeg,.jpg,.gif,.png"
              :disable-drag-to-move="false"
              :file-size-limit="2048000"
              :placeholder-font-size="14"
              :quality="2"
              :disabled="false"
              class="resizable-croppa"
            ></croppa>
          </template>
        </div>
      </div>
    </vs-popup>
    <div class="vx-row w-full mt-5 ml-2">
      <div class="vs-col w-full mb-2">
        <div class="flex flex-row items-center">
          <img class="pa-number" :src="require('@/assets/images/elements/first.svg')" />
          <div class="mx-2" style="font-size: 1.2rem">{{ $t('vue.buttonStyle') }}</div>
        </div>
      </div>

      <div class="vx-row w-full mt-2 ml-2">
        <div class="flex flex-col justify-between items-center mt-5">
          <div class="media-type w-full">
            <div class="media-type__item _dialog-type flex flex-col">
              <div
                class="media-type__check"
                :class="{
                  _active: dialog.type === 'button-card'
                }"
              >
                <check-icon :width="15" :height="15" />
              </div>
              <div class="media-type__wrapper" @click="updateDialogType('button-card')">
                <dialog-type-image-icon class="absolute" style="top: -2px" />
                <div class="media-type__icon-holder flex items-center">
                  <button-dialog-icon :color="dialog.type === 'button-card' ? '#3B86F7' : '#575757'" />
                </div>
              </div>
              <div
                class="media-type__title"
                :class="{
                  _active: dialog.type === 'button-card'
                }"
              >
                Button
              </div>
            </div>
            <vs-dropdown vs-trigger-click class="cursor-pointer" style="max-width: 168px">
              <small class="flex cursor-pointer">
                <div class="media-type__item _dialog-type flex flex-col">
                  <div
                    class="media-type__check"
                    :class="{
                      _active: dialog.type === 'button'
                    }"
                  >
                    <check-icon :width="15" :height="15" />
                  </div>
                  <div class="media-type__wrapper" @click="updateDialogType('button')">
                    <dialog-type-image-icon class="absolute" style="top: -2px" />
                    <div class="media-type__icon-holder flex items-center">
                      <talk-icon :color="dialog.type === 'button' ? '#3B86F7' : '#575757'" />
                    </div>
                  </div>
                  <div
                    class="media-type__title"
                    :class="{
                      _active: dialog.type === 'button'
                    }"
                  >
                    Button + Text
                  </div>
                </div>
              </small>
              <vs-dropdown-menu class="w-32">
                <vs-dropdown-item @click="() => updateNewButtonPlusTextStyle(false)">{{ $t('vue.buttonOldStyle') }}</vs-dropdown-item>
                <vs-dropdown-item @click="() => updateNewButtonPlusTextStyle(true)">{{ $t('vue.buttonNewStyle') }}</vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
        <div class="mt-5 w-full flex flex-row items-center">
          <div class="mx-2" style="font-size: 1.2rem">{{ $t('vue.position') }}:</div>
          <vs-radio v-model="widgetPosition" vs-value="left" class="mr-4" vs-name="widget-position">{{ $t('vue.widgetLeftPosition') }}</vs-radio>
          <vs-radio v-model="widgetPosition" vs-value="right" class="mr-4" vs-name="widget-position">{{ $t('vue.widgetRightPosition') }}</vs-radio>
        </div>

        <div v-if="!dialog.useNewButtonPlusTextStyle" class="mt-2 w-full flex flex-row items-center">
          <div class="mx-2" style="font-size: 1.2rem">{{ $t('vue.squareButton') }}</div>
          <div @click="toggleAlwaysShowButton">
            <toggle-switch-icon :enabled="isAlwaysShowButtonEnabled" :width="40" :height="40" />
          </div>
          <vx-tooltip :text="$t('info.alwaysVisibileSmallButtonInfo')" position="top">
            <img :src="infoImg" />
          </vx-tooltip>
        </div>
      </div>
    </div>

    <div class="vx-row w-full mt-5 ml-2">
      <div class="vs-col w-full mb-2">
        <div class="flex flex-row items-center">
          <img class="pa-number" :src="require('@/assets/images/elements/second.svg')" />
          <div class="mx-2" style="font-size: 1.2rem">{{ $t('uploadMedia.media') }}</div>
          <vx-tooltip :text="$t('uploadMedia.mediaInfoIcon')" position="top">
            <img :src="infoImg" />
          </vx-tooltip>
        </div>
      </div>
      <div class="flex flex-col justify-between items-center mt-2 ml-2">
        <div class="media-type w-full">
          <div class="media-type__item flex flex-col">
            <div
              class="media-type__check"
              :class="{
                _active: dialog.showDefaultMedia
              }"
            >
              <check-icon :width="15" :height="15" />
            </div>
            <div class="media-type__wrapper" @click="updateShowMedia(true)">
              <div class="flex items-center">
                <div
                  class="media-type__subtype _video flex-center pointer"
                  :class="{
                    _active: dialog.showVideo && dialog.showDefaultMedia
                  }"
                  @click.stop="updateShowVideo(true)"
                >
                  <video-call-icon />
                </div>
                <div
                  class="media-type__subtype _images flex-center pointer"
                  :class="{
                    _active: !dialog.showVideo && dialog.showDefaultMedia
                  }"
                  @click.stop="updateShowVideo(false)"
                >
                  <image-icon />
                </div>
              </div>
            </div>
            <div
              class="media-type__title"
              :class="{
                _active: dialog.showDefaultMedia
              }"
            >
              {{ $t('uploadMedia.useDefaultMedia') }}
            </div>
          </div>
          <div class="media-type__item flex flex-col">
            <div
              class="media-type__check"
              :class="{
                _active: !dialog.showDefaultMedia
              }"
            >
              <check-icon :width="15" :height="15" />
            </div>
            <div class="media-type__wrapper" @click="updateShowMedia(false)">
              <div
                class="media-type__upload"
                :class="{
                  _active: !dialog.showDefaultMedia
                }"
              >
                <edit-icon :width="25" :height="25" v-if="isAnyOwnMediaUploaded" />
                <upload-icon :width="25" :height="25" v-else />
              </div>
            </div>
            <div
              class="media-type__title"
              :class="{
                _active: !dialog.showDefaultMedia
              }"
            >
              {{ isAnyOwnMediaUploaded ? $t('uploadMedia.editYourOwnMedia') : $t('uploadMedia.uploadYourOwnMedia') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dialog.type === 'button-card'" class="vx-row w-full mt-5 ml-2">
      <div class="vs-col w-full mb-2">
        <div class="flex items-center">
          <img class="pa-number" :src="require('@/assets/images/elements/third.svg')" />
          <div class="flex flex-row justify-center items-center">
            <div @click="toggleMinimizeSmallButton">
              <toggle-switch-icon :enabled="dialog.isMinimizeSmallButtonEnabled" :width="40" :height="40" />
            </div>

            <div class="mx-2" style="font-size: 1.2rem">{{ $t('vue.minimizeSmallButtonText') }}</div>
            <vx-tooltip :text="$t('info.minimizeSmallButtonInfo')" position="top">
              <img :src="infoImg" />
            </vx-tooltip>
          </div>
        </div>
      </div>
      <div class="vs-col w-full display-options__small-button-col" v-show="dialog.isMinimizeSmallButtonEnabled">
        <div class="vx-row w-full">
          <vs-col vs-w="12">
            <div class="flex flex-col display-options__small-button-holder">
              <div class="flex">
                <div class="flex flex-col">
                  <div class="display-options__small-button-img-wrapper">
                    <img
                      class="display-options__small-button-img w-full h-full pointer"
                      slot="initial"
                      crossorigin="anonymous"
                      :src="dialog.smallButtonImage || defaultSmallButtonImage"
                    />
                  </div>
                  <vs-button
                    v-if="dialog.smallButtonImage"
                    class="delete-btn-padding mt-6 ml-auto mr-auto"
                    type="border"
                    color="danger"
                    size="large"
                    @click="removeSmallButtonImage"
                    icon-pack="material-icons"
                    icon="photo"
                    icon-after
                  >
                    {{ $t('vue.delete') }}
                  </vs-button>
                </div>
                <div>
                  <div class="flex items-center display-options__small-button-upload-wrapper">
                    <label for="svg-file-input" class="upload-photo mr-2 sm:mb-0 mb-2 upload p-0 pointer display-options__small-button-upload">
                      <input
                        type="file"
                        id="svg-file-input"
                        style="display: none"
                        accept="image/png, image/jpeg, image/svg+xml"
                        @change="selectMinimizedSmallButtonImage($event)"
                        @click="onFileInputClick"
                      />
                      <div class="con-upload">
                        <div class="con-img-upload mt-0">
                          <div class="con-input-upload">
                            <span class="text-input" style="color: #000">{{ $t('vue.selectPicture') }}</span>
                            <button disabled="disabled" type="button" title="Upload" class="btn-upload-all vs-upload--button-upload">
                              <i translate="translate" class="material-icons notranslate"> cloud_upload </i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </label>
                    <span style="max-width: 116px; padding-left: 3px; font-size: 0.75rem">{{ $t('vue.uploadSVGSmallButtonIconText') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </vs-col>
        </div>
        <div class="vx-row w-full mt-5 -ml-4">
          <div class="vs-col w-full mb-2 display-options__small-button-preview-col">
            <div class="flex flex-row items-center">
              <div @click="toggleSmallButtonPreview">
                <toggle-switch-icon :enabled="dialog.isSmallButtonPreviewVisible" :width="40" :height="40" />
              </div>
              <div class="mx-2" style="font-size: 1.2rem">{{ $t('vue.showSmallButtonPreviewText') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import imageCompression from 'browser-image-compression'

import MediaPreview from '@/components/dialog/media/MediaPreview'
import ButtonPreview from '@/components/dialog/media/ButtonPreview'
import ButtonPlusTextNewPreview from '@/components/dialog/media/ButtonPlusTextNewPreview'
import WelcomeScreenPreview from '@/components/dialog/media/WelcomeScreenPreview'
import UploadImageOrVideo from '@/components/UploadImageOrVideo'
import DialogTypeImageIcon from '@/components/icons/DialogTypeImageIcon'
import ButtonDialogIcon from '@/components/icons/ButtonDialogIcon.vue'
import TalkIcon from '@/components/icons/TalkIcon.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import ImageIcon from '@/components/icons/ImageIcon.vue'
import VideoCallIcon from '@/components/icons/VideoCallIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import UploadIcon from '@/components/icons/UploadIcon.vue'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
import VolumeIcon from '@/components/icons/VolumeIcon.vue'

export default {
  components: {
    MediaPreview,
    ButtonPreview,
    ButtonPlusTextNewPreview,
    WelcomeScreenPreview,
    UploadImageOrVideo,
    DialogTypeImageIcon,
    ButtonDialogIcon,
    TalkIcon,
    CheckIcon,
    CloseIcon,
    ImageIcon,
    VideoCallIcon,
    EditIcon,
    UploadIcon,
    ToggleSwitchIcon,
    VolumeIcon
  },
  props: {
    id: {
      type: String
    },
    infoImg: {
      type: String,
      required: true
    },
    dialogId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      videoTranscodeStatusRef: null,
      defaultImageURL: require('@/assets/images/dialog/contact-form-background.jpeg'),

      smallButtonImage: '',
      defaultSmallButtonImage: require('@/assets/images/dialog/default-small-button-image.svg'),

      showOwnMedia: false,
      showCroppa: false,
      croppaWidth: 280,
      croppaHeight: 566,
      myPhoto: {},
      uploadingVideo: false,
      transcodingVideo: false,
      videoFileName: '',
      webm: 'video/webm',
      mp4: 'video/mp4',

      currentAllMediaType: null,
      allMediaTypes: {
        buttonPlusTextNewMedia: {
          croppaWidth: 180,
          croppaHeight: 180,
          myPhoto: {}
        },
        buttonMedia: {
          croppaWidth: 250,
          croppaHeight: 160,
          myPhoto: {}
        },
        smallButtonMedia: {
          croppaWidth: 80,
          croppaHeight: 80,
          myPhoto: {}
        },
        welcomeScreenMedia: {
          croppaWidth: 280,
          croppaHeight: 566,
          myPhoto: {}
        }
      },
      initialImage: null,
      file: null,
      uploadType: '',

      ownMediaKey: Math.random().toString(36).substring(2, 15),
      activateVideoSound: false,
      useOneMediaForAllSteps: false,
      widgetPosition: 'right',
      isAlwaysShowButtonEnabled: false
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      dialog: 'dialog'
    }),
    isWelcomeScreenMediaVideo() {
      try {
        if (this.useOneMediaForAllSteps) {
          if (
            this.dialog &&
            this.dialog.allMedia &&
            typeof this.dialog.allMedia !== 'object' &&
            (this.dialog.allMedia.indexOf('.mp4') > -1 || this.dialog.allMedia.indexOf('.webm') > -1)
          ) {
            return true
          } else {
            return false
          }
        }

        if (!this.useOneMediaForAllSteps) {
          if (
            (this.dialog && this.dialog.welcomeScreenMedia && this.dialog.welcomeScreenMedia.indexOf('.webm') > -1) ||
            (this.dialog && this.dialog.welcomeScreenMedia && this.dialog.welcomeScreenMedia.indexOf('.mp4') > -1)
          ) {
            return true
          } else {
            return false
          }
        }

        return false
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
        return false
      }
    },
    uploadSubtype() {
      return this.useOneMediaForAllSteps && typeof this.dialog.allMedia === 'object' && this.dialog.allMedia !== null ? 'buttonPlusTextNewMedia' : ''
    },
    isAnyOwnMediaUploaded() {
      if (
        !this.dialog.allMedia &&
        !this.dialog.buttonMedia &&
        !this.dialog.smallButtonMedia &&
        !this.dialog.welcomeScreenMedia &&
        !this.dialog.buttonPlusTextNewMedia
      ) {
        return false
      }
      return true
    },
    defaultVideoURL() {
      return '/Mann-Winken-SD.mp4'
    }
  },
  watch: {
    widgetPosition() {
      this.updateDialogPartial({ position: this.widgetPosition })
    },
    activateVideoSound() {
      if (this.activateVideoSound) {
        setTimeout(() => {
          const video = document.getElementById('s2-display-options-welcome-screen')
          if (video) {
            video.muted = false
            video.loop = false
            video.currentTime = 0
            if (video.paused) {
              video.play()
            }
          }
        }, 500)
      } else {
        const video = document.getElementById('s2-display-options-welcome-screen')
        if (video) {
          if (video.paused) {
            video.play()
          }
          video.muted = true
          video.loop = true
        }
      }
    },
    showOwnMedia() {
      if (this.showOwnMedia) {
        const _update = {}
        if (this.dialog) {
          _update.activateVideoSound = Boolean(this.dialog.activateVideoSound)
          _update.useOneMediaForAllSteps = Boolean(this.dialog.useOneMediaForAllSteps)
          this.updateDialogPartial(_update)
          this.activateVideoSound = Boolean(_update.activateVideoSound)
          this.useOneMediaForAllSteps = Boolean(_update.useOneMediaForAllSteps)
        }
      }
    },
    'dialog.useOneMediaForAllSteps'(after, before) {
      if (before !== after) {
        this.ownMediaKey = Math.random().toString(36).substring(2, 15)
      }
    }
  },
  mounted() {
    const vm = this
    const _update = {}
    if (this.dialog && typeof this.dialog.activateVideoSound === 'undefined') {
      _update.activateVideoSound = false
      this.updateDialogPartial(_update)
    }
    this.activateVideoSound = Boolean(this.dialog.activateVideoSound)

    if (this.dialog && typeof this.dialog.useOneMediaForAllSteps === 'undefined') {
      _update.useOneMediaForAllSteps = false
      this.updateDialogPartial(_update)
    }
    this.useOneMediaForAllSteps = Boolean(this.dialog.useOneMediaForAllSteps)

    this.$serverBus.$on('update-button-preview', () => {
      this.ownMediaKey = Math.random().toString(36).substring(2, 15)
    })

    let rdVideoTranscodePath = ''
    if (this.dialog.default) {
      rdVideoTranscodePath = `video-transcode/${vm.dialogId}`
    } else {
      rdVideoTranscodePath = `video-transcode/${vm.dialogId}/ab-tests/${vm.dialog.id}`
    }

    this.widgetPosition = vm.dialog && vm.dialog.position ? vm.dialog.position : 'right'
    this.isAlwaysShowButtonEnabled = vm.dialog && vm.dialog.isAlwaysShowButtonEnabled ? vm.dialog.isAlwaysShowButtonEnabled : false

    this.videoTranscodeStatusRef = this.$firebase.database().ref(rdVideoTranscodePath)
    this.videoTranscodeStatusRef.set({ success: false })
    this.videoTranscodeStatusRef.on('value', async (snapshot) => {
      const val = await snapshot.val()
      if (val && !val.success && val.error && !vm.displayedMessage) {
        vm.displayedMessage = true
        vm.transcodingVideo = false
        if (val.error.indexOf('Cannot determine format of input stream') === -1) {
          vm.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.transcodingVideoError'),
            text: val.error,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
      if (val && val.started) {
        vm.transcodingVideo = true
      }
      if (val && (val.success || val.uploaded) && val.signedUrl && vm.transcodingVideo) {
        vm.transcodingVideo = false
        this.$store.commit('UPDATE_DIALOG', {
          ...vm.dialog,
          allMedia: val.allMedia ? val.allMedia : vm.dialog.allMedia ? vm.dialog.allMedia : null,
          buttonMedia: val.buttonMedia ? val.buttonMedia : vm.dialog.buttonMedia ? vm.dialog.buttonMedia : null,
          smallButtonMedia: val.smallButtonMedia ? val.smallButtonMedia : vm.dialog.smallButtonMedia ? vm.dialog.smallButtonMedia : null,
          welcomeScreenMedia: val.welcomeScreenMedia ? val.welcomeScreenMedia : vm.dialog.welcomeScreenMedia ? vm.dialog.welcomeScreenMedia : null,
          buttonPlusTextNewMedia: val.buttonPlusTextNewMedia
            ? val.buttonPlusTextNewMedia
            : vm.dialog.buttonPlusTextNewMedia
            ? vm.dialog.buttonPlusTextNewMedia
            : null
        })

        await vm.$vs.loading.close()

        if (vm.success) {
          vm.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.success'),
            text: vm.$i18n.t('vue.transcodedVideoSuccessfully'),
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
        }
      }
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('update-button-preview')
    if (this.dialogId) {
      if (this.dialog.default) {
        this.$firebase.database().ref(`video-transcode/${this.dialogId}`).off('value')
      } else {
        this.$firebase.database().ref(`video-transcode/${this.dialogId}/ab-tests/${this.dialog.id}`).off('value')
      }
    }
    if (this.videoTranscodeStatusRef) {
      this.videoTranscodeStatusRef.off('value')
    }
  },
  methods: {
    ...mapActions({
      updateDialogPartial: 'updateDialogPartial'
    }),
    toggleActivateVideoSound() {
      if (!this.dialog) return
      this.activateVideoSound = !this.activateVideoSound
    },
    updateNewButtonPlusTextStyle(type) {
      const _update = {
        type: 'button',
        useNewButtonPlusTextStyle: type,
        isSmallButtonPreviewVisible: false
      }
      this.updateDialogPartial(_update)
      this.$serverBus.$emit('toggle-use-new-button-plus-text-style', this.dialog.useNewButtonPlusTextStyle)
      this.$serverBus.$emit('update-button-preview')
    },
    onUploadImageOrVideo(event, uploadType) {
      const { files } = event.target
      const file = files[0]
      const isVideoType = this.detectVideoFile(event)
      const isFileSizeExceed = this.detectFileSizeExceed(file)
      if (isFileSizeExceed) {
        return
      }
      if (isVideoType) {
        this.uploadMedia(file, uploadType, isVideoType, false)
      } else {
        this.uploadMedia(file, uploadType, isVideoType, true)
      }
    },
    async uploadMedia(file, uploadType, isVideoType, showCroppaView) {
      this.file = file
      this.uploadType = uploadType
      if (isVideoType) {
        this.uploadMediaVideo(file, uploadType)
        return
      }

      if (uploadType === 'buttonMedia') {
        this.croppaWidth = 250
        this.croppaHeight = 160
      } else if (uploadType === 'smallButtonMedia') {
        this.croppaWidth = 80
        this.croppaHeight = 80
      } else if (uploadType === 'welcomeScreenMedia') {
        this.croppaWidth = 280
        this.croppaHeight = 566
      } else if (uploadType === 'buttonPlusTextNewMedia') {
        this.croppaWidth = 180
        this.croppaHeight = 180
      }

      const vm = this
      vm.$vs.loading()

      const fileExtension = file && !!file.name ? file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2) : 'png'

      let storagePath = ''

      if (this.dialog.default) {
        storagePath = `images/dialogs/${this.dialogId}/${uploadType}.${fileExtension}`
      } else {
        storagePath = `images/dialogs/${this.dialogId}/ab-tests/${this.dialog.id}/${uploadType}.${fileExtension}`
      }

      try {
        const storageService = this.$firebase.storage()
        const storageRef = storageService.ref()

        let uploadTask = null

        uploadTask = storageRef.child(storagePath).put(file)
        this.uploadTask = true

        uploadTask.on(
          this.$firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

            this.progressUpload = progress
          },
          function (error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                vm.text = 'fields.storage/unauthorized'
                break
              case 'storage/canceled':
                // User canceled the upload
                vm.text = 'fields.storage/canceled'
                break
              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                vm.text = 'fields.storage/unknown'
                break
            }

            vm.$vs.notify({
              time: 8800,
              title: vm.$i18n.t('vue.error'),
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })

            vm.uploadTask = false
          },
          async () => {
            this.uploadTask = false
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

            const mediaToUpdate = {}

            mediaToUpdate[uploadType] = downloadURL

            if (vm.dialog.default) {
              const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)
              dialogRef.set(mediaToUpdate, { merge: true })
            } else {
              const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId).collection('ab-tests').doc(this.dialog.id)

              mediaToUpdate.showVideo = this.dialog.showVideo

              dialogRef.set(mediaToUpdate, { merge: true })
            }

            this.$vs.loading.close()
            this.$store.commit('UPDATE_DIALOG', {
              ...this.dialog,
              [uploadType]: downloadURL
            })

            this.$vs.notify({
              time: 8800,
              title: vm.$i18n.t('vue.success'),
              text: vm.$i18n.t('vue.photoSavedSuccessfully'),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            })
            if (showCroppaView) {
              this.initialImage = downloadURL
              if (uploadType === 'allMedia') {
                this.allMediaTypes.buttonMedia.myPhoto.refresh()
                this.allMediaTypes.smallButtonMedia.myPhoto.refresh()
                this.allMediaTypes.welcomeScreenMedia.myPhoto.refresh()
                this.allMediaTypes.buttonPlusTextNewMedia.myPhoto.refresh()
                this.showCroppa = true
              } else {
                this.myPhoto.refresh()
                this.showCroppa = true
              }
            }
          }
        )
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: vm.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },
    async deleteMediaType(uploadType, uploadSubtype) {
      const url = uploadSubtype ? this.dialog[uploadType][uploadSubtype] : this.dialog[uploadType]
      const fileRef = this.$firebase.storage().refFromURL(url)
      const mediaToUpdate = {}
      mediaToUpdate[uploadType] = null

      try {
        await fileRef.delete()

        if (this.dialog.default) {
          const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)
          dialogRef.set(mediaToUpdate, { merge: true })
        } else {
          const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId).collection('ab-tests').doc(this.dialog.id)

          dialogRef.set(mediaToUpdate, { merge: true })
        }
        this.$serverBus.$emit('on-own-media-delete', {
          newValue: this.dialog,
          data: this.dialog
        })
        this.$store.commit('UPDATE_DIALOG', {
          ...this.dialog,
          [uploadType]: null
        })
        this.$vs.loading.close()
        this.$vs.notify({
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('uploads.deleteFile'),
          type: 'success',
          duration: 3000,
          speed: 100
        })
      } catch (err) {
        if (err.code === 'storage/object-not-found') {
          if (this.dialog.default) {
            const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)
            await dialogRef.set({ [uploadType]: null }, { merge: true })
          } else {
            const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId).collection('ab-tests').doc(this.dialog.id)
            await dialogRef.set({ [uploadType]: null, duplicate_video: false }, { merge: true })
          }
          this.$store.commit('UPDATE_DIALOG', {
            ...this.dialog,
            [uploadType]: null
          })
        }
        this.$vs.loading.close()
      }
    },
    async deleteOtherMediaType(uploadType) {
      await this.deleteMediaType(uploadType)
    },
    async deleteAllMediaType(uploadType, uploadSubtype) {
      await this.deleteMediaType(uploadType, uploadSubtype)
    },
    async deleteMedia(uploadType) {
      this.$vs.loading()
      if (uploadType === 'allMedia' && typeof this.dialog[uploadType] === 'object' && this.dialog[uploadType] !== null) {
        const uploadSubtypes = Object.keys(this.allMediaTypes)
        await Promise.all(
          uploadSubtypes.map(async (uploadSubtype) => {
            await this.deleteAllMediaType(uploadType, uploadSubtype)
          })
        )
        return
      }
      await this.deleteOtherMediaType(uploadType)
    },
    async onOwnMediaSave() {
      this.$serverBus.$emit('toggle-use-one-media-for-all-steps', this.useOneMediaForAllSteps)
      const _update = {
        activateVideoSound: this.activateVideoSound,
        useOneMediaForAllSteps: this.useOneMediaForAllSteps
      }
      this.updateDialogPartial(_update)

      if (this.dialog.default) {
        const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)
        dialogRef.update({ activateVideoSound: this.dialog.activateVideoSound, useOneMediaForAllSteps: this.dialog.useOneMediaForAllSteps })
      } else {
        const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId).collection('ab-tests').doc(this.dialog.id)
        dialogRef.update({ activateVideoSound: this.dialog.activateVideoSound, useOneMediaForAllSteps: this.dialog.useOneMediaForAllSteps })
      }

      this.$serverBus.$emit('on-own-media-save', {
        newValue: this.dialog,
        data: this.dialog
      })
      this.showOwnMedia = false
    },
    async uploadCroppedImage() {
      const vm = this
      vm.$vs.loading()

      const file = this.file
      const uploadType = this.uploadType

      const fileExtension = file && !!file.name ? file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2) : 'png'
      let storagePath = ''

      if (uploadType !== 'allMedia') {
        if (this.dialog.default) {
          storagePath = `images/dialogs/${this.dialogId}/${uploadType}.${fileExtension}`
        } else {
          storagePath = `images/dialogs/${this.dialogId}/ab-tests/${this.dialog.id}/${uploadType}.${fileExtension}`
        }

        try {
          this.myPhoto.generateBlob(
            async (blob) => {
              const storageService = this.$firebase.storage()
              const storageRef = storageService.ref()
              let file = blob

              const options = {
                useWebWorker: true,
                initialQuality: 1,
                alwaysKeepResolution: true
              }
              try {
                const compressedFile = await imageCompression(file, options)
                if (compressedFile) {
                  file = compressedFile
                }
              } catch (error) {
                /* eslint-disable-next-line */
                console.log(error.message)
              }

              let uploadTask = null

              uploadTask = storageRef.child(storagePath).put(file)
              this.uploadTask = true

              uploadTask.on(
                this.$firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                (snapshot) => {
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

                  this.progressUpload = progress
                },
                function (error) {
                  // A full list of error codes is available at
                  // https://firebase.google.com/docs/storage/web/handle-errors
                  switch (error.code) {
                    case 'storage/unauthorized':
                      // User doesn't have permission to access the object
                      vm.text = 'fields.storage/unauthorized'
                      break
                    case 'storage/canceled':
                      // User canceled the upload
                      vm.text = 'fields.storage/canceled'
                      break
                    case 'storage/unknown':
                      // Unknown error occurred, inspect error.serverResponse
                      vm.text = 'fields.storage/unknown'
                      break
                  }

                  vm.$vs.notify({
                    time: 8800,
                    title: vm.$i18n.t('vue.error'),
                    text: error.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                  })

                  vm.uploadTask = false
                },
                async () => {
                  // Upload completed successfully, now we can get the download URL
                  this.uploadTask = false
                  const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

                  const mediaToUpdate = {}

                  mediaToUpdate[uploadType] = downloadURL

                  if (vm.dialog.default) {
                    const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)
                    dialogRef.set(mediaToUpdate, { merge: true })
                  } else {
                    const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId).collection('ab-tests').doc(this.dialog.id)

                    mediaToUpdate.showVideo = this.dialog.showVideo

                    dialogRef.set(mediaToUpdate, { merge: true })
                  }

                  this.$vs.loading.close()
                  this.$store.commit('UPDATE_DIALOG', {
                    ...this.dialog,
                    [uploadType]: downloadURL
                  })

                  this.$vs.notify({
                    time: 8800,
                    title: vm.$i18n.t('vue.success'),
                    text: vm.$i18n.t('vue.photoSavedSuccessfully'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                  })
                }
              )
            },
            'image/jpg',
            0.8
          ) // 80% compressed jpeg file
        } catch (err) {
          this.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.error'),
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          // eslint-disable-next-line
          console.error(err.message)
        }
      } else {
        const result = Object.keys(this.allMediaTypes).map((key) => {
          return {
            type: key,
            myPhoto: this.allMediaTypes[key].myPhoto
          }
        })
        await Promise.all(
          result.map(async (el) => {
            try {
              el.myPhoto.generateBlob(
                async (blob) => {
                  const storageService = this.$firebase.storage()
                  const storageRef = storageService.ref()
                  let file = blob

                  const options = {
                    useWebWorker: true,
                    initialQuality: 1,
                    alwaysKeepResolution: true
                  }
                  try {
                    const compressedFile = await imageCompression(file, options)
                    if (compressedFile) {
                      file = compressedFile
                    }
                  } catch (error) {
                    /* eslint-disable-next-line */
                    console.log(error.message)
                  }

                  let uploadTask = null

                  if (this.dialog.default) {
                    storagePath = `images/dialogs/${this.dialogId}/${uploadType}/${el.type}.${fileExtension}`
                  } else {
                    storagePath = `images/dialogs/${this.dialogId}/ab-tests/${this.dialog.id}/${uploadType}/${el.type}.${fileExtension}`
                  }

                  uploadTask = storageRef.child(storagePath).put(file)
                  this.uploadTask = true
                  uploadTask.on(
                    this.$firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                    (snapshot) => {
                      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

                      this.progressUpload = progress
                    },
                    function (error) {
                      // A full list of error codes is available at
                      // https://firebase.google.com/docs/storage/web/handle-errors
                      switch (error.code) {
                        case 'storage/unauthorized':
                          // User doesn't have permission to access the object
                          vm.text = 'fields.storage/unauthorized'
                          break
                        case 'storage/canceled':
                          // User canceled the upload
                          vm.text = 'fields.storage/canceled'
                          break
                        case 'storage/unknown':
                          // Unknown error occurred, inspect error.serverResponse
                          vm.text = 'fields.storage/unknown'
                          break
                      }

                      vm.$vs.notify({
                        time: 8800,
                        title: vm.$i18n.t('vue.error'),
                        text: error.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                      })

                      vm.uploadTask = false
                    },
                    async () => {
                      // Upload completed successfully, now we can get the download URL
                      this.uploadTask = false
                      const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
                      const mediaToUpdate = `${uploadType}.${el.type}`
                      if (vm.dialog.default) {
                        const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)
                        dialogRef.update({ [mediaToUpdate]: downloadURL })
                      } else {
                        const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId).collection('ab-tests').doc(this.dialog.id)
                        dialogRef.update({ [mediaToUpdate]: downloadURL })
                      }

                      this.$store.commit('UPDATE_DIALOG', {
                        ...this.dialog,
                        [uploadType]: {
                          buttonMedia:
                            el.type === 'buttonMedia' && downloadURL
                              ? downloadURL
                              : this.dialog.allMedia && this.dialog.allMedia.buttonMedia
                              ? this.dialog.allMedia.buttonMedia
                              : null,
                          smallButtonMedia:
                            el.type === 'smallButtonMedia' && downloadURL
                              ? downloadURL
                              : this.dialog.allMedia && this.dialog.allMedia.smallButtonMedia
                              ? this.dialog.allMedia.smallButtonMedia
                              : null,
                          welcomeScreenMedia:
                            el.type === 'welcomeScreenMedia' && downloadURL
                              ? downloadURL
                              : this.dialog.allMedia && this.dialog.allMedia.welcomeScreenMedia
                              ? this.dialog.allMedia.welcomeScreenMedia
                              : null,
                          buttonPlusTextNewMedia:
                            el.type === 'buttonPlusTextNewMedia' && downloadURL
                              ? downloadURL
                              : this.dialog.allMedia && this.dialog.allMedia.buttonPlusTextNewMedia
                              ? this.dialog.allMedia.buttonPlusTextNewMedia
                              : null
                        }
                      })

                      this.$vs.loading.close()

                      this.$vs.notify({
                        time: 8800,
                        title: vm.$i18n.t('vue.success'),
                        text: vm.$i18n.t('vue.photoSavedSuccessfully'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                      })
                    }
                  )
                },
                'image/jpg',
                0.8
              ) // 80% compressed jpeg file
            } catch (err) {
              this.$vs.notify({
                time: 8800,
                title: vm.$i18n.t('vue.error'),
                text: err.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
              // eslint-disable-next-line
              console.error(err.message)
            }
          })
        )
      }
    },
    onCroppaSave() {
      this.showCroppa = false
      this.uploadCroppedImage()
    },
    detectFileSizeExceed(file) {
      if (file.size / (1024 * 1024) > 25) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.fileSizeLimitExceeded'),
          text: this.$i18n.t('vue.maximumVideoSize'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return true
      }
      return false
    },
    detectVideoFile(e) {
      const fileList = e.target.files || e.dataTransfer.files
      const file = fileList[0]
      if (file.type.indexOf('webm') === -1 && file.type.indexOf('mp4') === -1) {
        return false
      }
      return true
    },
    uploadMediaVideo(file, uploadType) {
      this.uploadVideo(file, uploadType)
    },
    uploadVideo(file, uploadType) {
      this.ownMediaKey = Math.random().toString(36).substring(2, 15)
      this.$vs.loading()
      const fileExtension = file && !!file.name ? file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2) : 'mp4'
      const fileContentType = file && !!file.type ? file.type : 'video/mp4'
      this.videoFileName = `${this.ownMediaKey}_${uploadType}.${fileExtension}`

      let storagePath = ''

      if (this.dialog.default) {
        storagePath = `videos/dialogs/${this.dialogId}/`
      } else {
        storagePath = `videos/dialogs/${this.dialogId}/ab-tests/${this.dialog.id}/`
      }

      this.uploadingVideo = true
      const storageService = this.$firebase.storage()
      const storageRef = storageService.ref()
      const metadata = {
        contentType: fileContentType,
        cacheControl: 'max-age=604800, public'
      }

      if (this.dialog.default) {
        metadata.customMetadata = {
          uid: this.activeUserInfo.uid,
          dialogId: this.dialogId,
          uploadType
        }
      } else {
        metadata.customMetadata = {
          uid: this.activeUserInfo.uid,
          dialogId: this.dialogId,
          abTestId: this.dialog.id,
          uploadType
        }
      }
      storageRef.child(`${storagePath}/${this.videoFileName}`).put(file, metadata)
    },
    toggleUseOneMediaForAllSteps() {
      this.useOneMediaForAllSteps = !this.useOneMediaForAllSteps
      //this.$serverBus.$emit('toggle-use-one-media-for-all-steps', this.useOneMediaForAllSteps)
      this.$serverBus.$emit('update-button-preview')
    },
    updateShowMedia(show) {
      const _update = {
        showDefaultMedia: show,
        isSmallButtonPreviewVisible: false
      }
      this.updateDialogPartial(_update)
      this.$serverBus.$emit('update-dialog-show-media', show)
      if (!show) {
        this.showOwnMedia = true
        this.$serverBus.$emit('update-button-preview')
      }
    },
    updateShowVideo(show) {
      const _update = {
        showVideo: show,
        showDefaultMedia: true,
        isSmallButtonPreviewVisible: false
      }
      this.updateDialogPartial(_update)
      this.$serverBus.$emit('update-dialog-show-media', true)
      this.$serverBus.$emit('update-dialog-show-video', show)
      this.$serverBus.$emit('update-button-preview')
    },
    updateDialogType(newDialogType) {
      const updatedDialog = {
        type: newDialogType,
        isSmallButtonPreviewVisible: false
      }
      if (newDialogType === 'button-card') {
        updatedDialog.useNewButtonPlusTextStyle = false
      }

      this.updateDialogPartial(updatedDialog)
    },

    /* smallButtonImage */
    toggleAlwaysShowButton() {
      this.isAlwaysShowButtonEnabled = !this.isAlwaysShowButtonEnabled
      const updatedDialog = {
        isAlwaysShowButtonEnabled: this.isAlwaysShowButtonEnabled
      }
      if (this.dialog.isSmallButtonPreviewVisible) {
        updatedDialog.isSmallButtonPreviewVisible = false
      }
      this.updateDialogPartial(updatedDialog)
      this.$serverBus.$emit('update-button-preview')
    },
    toggleMinimizeSmallButton() {
      const _update = {
        isMinimizeSmallButtonEnabled: !this.dialog.isMinimizeSmallButtonEnabled
      }
      if (this.dialog.isSmallButtonPreviewVisible) {
        _update.isSmallButtonPreviewVisible = false
      }
      this.updateDialogPartial(_update)
    },
    toggleSmallButtonPreview() {
      const _update = {
        isSmallButtonPreviewVisible: !this.dialog.isSmallButtonPreviewVisible
      }
      this.updateDialogPartial(_update)
    },
    async selectMinimizedSmallButtonImage(event) {
      const { files } = event.target

      if (files && files.length) {
        await this.uploadSmallButtonImage(files)
      }
    },

    onFileInputClick(event) {
      /* makes it possible to send the same file twice,
      one after another
      (the browser caches the change event for performance purposes) */
      event.target.value = ''
    },

    async uploadSmallButtonImage(files) {
      const vm = this
      vm.$vs.loading()

      let storagePath = ''

      if (this.dialog.default) {
        storagePath = `images/dialogs/small-button/${this.dialogId}`
      } else {
        storagePath = `images/dialogs/small-button/${this.dialogId}/ab-tests/${this.dialog.testId}`
      }

      try {
        const storageService = this.$firebase.storage()
        const storageRef = storageService.ref()

        let uploadTask = null

        uploadTask = storageRef.child(storagePath).put(files[0])
        this.uploadTask = true

        uploadTask.on(
          this.$firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

            this.progressUpload = progress
          },
          function (error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                vm.text = 'fields.storage/unauthorized'
                break
              case 'storage/canceled':
                // User canceled the upload
                vm.text = 'fields.storage/canceled'
                break
              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                vm.text = 'fields.storage/unknown'
                break
            }

            vm.$vs.notify({
              time: 8800,
              title: vm.$i18n.t('vue.error'),
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })

            vm.uploadTask = false
          },
          async () => {
            // Upload completed successfully, now we can get the download URL
            this.uploadTask = false
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

            if (vm.dialog.default) {
              const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)

              dialogRef.set({ smallButtonImage: downloadURL }, { merge: true })
            } else {
              const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId).collection('ab-tests').doc(this.dialog.testId)

              dialogRef.set({ smallButtonImage: downloadURL, showVideo: this.dialog.showVideo }, { merge: true })
            }
            this.$store.commit('UPDATE_DIALOG', {
              ...this.dialog,
              smallButtonImage: downloadURL
            })

            this.$vs.loading.close()
            this.smallButtonImage = downloadURL

            this.$vs.notify({
              time: 8800,
              title: vm.$i18n.t('vue.success'),
              text: vm.$i18n.t('vue.photoSavedSuccessfully'),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            })
          }
        )
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: vm.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },
    async removeSmallButtonImage() {
      this.$vs.loading()

      const storageService = this.$firebase.storage()

      try {
        this.smallButtonImage = this.defaultSmallButtonImage
        if (this.dialog.default) {
          const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)

          await dialogRef.set({ smallButtonImage: null }, { merge: true })
        } else {
          const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId).collection('ab-tests').doc(this.dialog.testId)

          await dialogRef.set({ smallButtonImage: null, duplicate_image: false }, { merge: true })
        }
        if (!this.dialog.duplicate_image) {
          await storageService.refFromURL(this.dialog.smallButtonImage).delete()
          this.smallButtonImage = ''
          this.$store.commit('UPDATE_DIALOG', {
            ...this.dialog,
            smallButtonImage: null
          })
        }
      } catch (err) {
        let message = err.message

        if (err.code === 'storage/object-not-found') {
          message = this.$i18n.t('vue.objectDoesNotExists')
        }
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      this.$vs.loading.close()
      this.$store.commit('UPDATE_DIALOG', {
        ...this.dialog,
        smallButtonImage: null
      })
    }
    /* smallButtonImage end */
  }
}
</script>
<style lang="scss">
.croppa-uploaded-pics {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: center;
}
.resizable-croppa {
  margin: 5px;
}
.con-vs-popup.fullscreen .vs-popup {
  width: 100% !important;
}
.image-popup {
  z-index: 55000 !important;
  .popover-action-button {
    cursor: pointer;
  }
  .vs-popup {
    width: fit-content;
    height: fit-content;

    &--header {
      display: none !important;
    }
  }
}
.media-popup {
  z-index: 54999 !important;
  .popover-action-button {
    cursor: pointer;
  }
  .vs-tooltip {
    z-index: 55000;
  }
  &__title {
    min-height: 40px;
    font-weight: 500;
    font-size: 14px;
    color: #575757;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 92px;
  }
  &__left,
  &__right {
    display: flex;
    flex-direction: column;
  }
  &__left {
    margin-right: 40px;
  }
  &__toggle {
    font-size: 14px;
    margin-bottom: 16px;
    &-label {
      margin-left: 7px;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #575757;
    }
    &-tooltip {
      display: flex;
      align-items: center;
      &-img {
        min-width: 17px;
        width: 17px;
        height: 17px;
      }
    }
  }
  &__upload-holder {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__headline {
    font-weight: bold;
    font-size: 16px;
    color: #12598d;
    margin-bottom: 10px;
  }
}
</style>
<style lang="scss" scoped>
.media-type {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-width: 168px;
    position: relative;
    &:first-child {
      margin-right: 64px;
    }
    &._dialog-type {
      .media-type__wrapper {
        box-shadow: unset;
      }
    }
  }
  &__check {
    min-width: 20px;
    width: 20px;
    height: 20px;
    background: #1c8439;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    left: 0;
    transform: scale(0);
    transition: 0.3s ease-in-out;
    z-index: 1;
    &._active {
      transition: 0.3s ease-in-out;
      transform: scale(1);
    }
  }
  &__wrapper {
    width: 145px;
    padding: 18px 28px;
    box-sizing: border-box;
    max-width: 100%;
    min-height: 98px;
    border: 1.5px solid #a9a9a9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
  }
  &__icon-holder {
    position: absolute;
    bottom: 7px;
    right: 7px;
  }
  &__title {
    width: 100%;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.3s ease-in-out;
    &:after {
      transition: 0.3s ease-in-out;
      content: '';
      width: 0%;
      height: 1.5px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: #3b86f7;
    }
    &._active {
      color: #3b86f7;
      transition: 0.3s ease-in-out;
      &:after {
        transition: 0.3s ease-in-out;
        width: 100%;
      }
    }
  }
  &__subtype {
    padding: 8px;
    background: #575757;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 41px;
    height: 41px;
    transition: all 0.3s ease-in-out;
    &._video {
      margin-right: 7px;
    }
    &._active {
      background: #3b86f7;
      width: 54px;
      transition: all 0.3s ease-in-out;
    }
  }

  &__upload {
    width: 100%;
    max-width: 109px;
    min-height: 41px;
    background: #575757;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    &._active {
      background: #3b86f7;
    }
  }
}
.display-options {
  &__small-button {
    &-col {
      margin-left: 9.2%;
      @media (max-width: 1440px) {
        margin-left: 0;
      }
    }
    &-holder {
      @media (max-width: 991px) {
        align-items: center;
        justify-content: center;
      }
    }
    &-preview-col {
      @media (max-width: 991px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-img-wrapper {
      border: 1px solid #e6ecf1;
      box-sizing: border-box;
      border-radius: 5px;
      min-width: 125px;
      width: 125px;
      height: 100px;
      padding: 4px 11px;
    }
    &-upload {
      min-width: 125px;
      margin-right: 15px;
      margin-left: 15px;
      @media (max-width: 1440px) {
        margin-bottom: 1.25rem !important;
      }
      &-wrapper {
        @media (max-width: 1440px) {
          flex-direction: column;
        }
      }
    }
  }
}
</style>
