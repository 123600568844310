var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configure-dialog-step-five" }, [
    _c("div", { staticClass: "flex-box-column-start" }, [
      _c(
        "div",
        { staticClass: "vx-row w-full" },
        [
          _c(
            "vs-col",
            {
              staticClass: "flex-box-row-start w-full",
              attrs: { "vs-w": "12" },
            },
            [
              _c("div", { staticClass: "headingfont mr-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.getFeedbackFromCustomer"))),
              ]),
              _c(
                "vx-tooltip",
                {
                  attrs: {
                    text: _vm.$t("info.getFeedbackFromCustomer"),
                    position: "top",
                  },
                },
                [_c("img", { attrs: { src: _vm.infoImg } })]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "flex-view",
        style: {
          "--endscreen-darken-bg-color":
            _vm.feedbackFormSendButtonBackgroundColor,
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "mt-5 mr-6 flex flex-column flex-grow",
            staticStyle: { "max-width": "640px" },
          },
          [
            _vm.canUpdateTextFields
              ? _c("div", [
                  _vm._v(_vm._s(_vm.$t("vue.sessionEndScreenLabelTitle"))),
                ])
              : _vm._e(),
            !_vm.canUpdateTextFields
              ? _c("div", { staticClass: "w-full bold" }, [
                  _vm._v(
                    _vm._s(_vm.$t("vue.sessionEndScreenLabelTitle")) +
                      " (English)"
                  ),
                ])
              : _vm._e(),
            _c("vs-input", {
              staticClass: "w-full mr-2",
              model: {
                value: _vm.titleClosingMessage,
                callback: function ($$v) {
                  _vm.titleClosingMessage = $$v
                },
                expression: "titleClosingMessage",
              },
            }),
            !_vm.canUpdateTextFields
              ? _c("div", { staticClass: "w-full bold" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("vue.sessionEndScreenLabelTitle")) +
                      " (" +
                      _vm._s(this.selectedLanguageForWebConnect.lnFullText) +
                      ")\n      "
                  ),
                ])
              : _vm._e(),
            !_vm.canUpdateTextFields
              ? _c("vs-input", {
                  staticClass: "w-full mr-2",
                  model: {
                    value: _vm.tWebConnectSessionEndScreenDefaultTitle,
                    callback: function ($$v) {
                      _vm.tWebConnectSessionEndScreenDefaultTitle = $$v
                    },
                    expression: "tWebConnectSessionEndScreenDefaultTitle",
                  },
                })
              : _vm._e(),
            _vm.canUpdateTextFields
              ? _c("div", { staticClass: "mt-4 w-full" }, [
                  _vm._v(_vm._s(_vm.$t("vue.sessionEndScreenLabelCopy"))),
                ])
              : _vm._e(),
            !_vm.canUpdateTextFields
              ? _c("div", { staticClass: "mt-4 w-full bold" }, [
                  _vm._v(
                    _vm._s(_vm.$t("vue.sessionEndScreenLabelCopy")) +
                      " (English)"
                  ),
                ])
              : _vm._e(),
            _c("vs-textarea", {
              staticClass: "w-full p-1",
              attrs: { counter: "300", height: "80px" },
              model: {
                value: _vm.copyClosingMessage,
                callback: function ($$v) {
                  _vm.copyClosingMessage = $$v
                },
                expression: "copyClosingMessage",
              },
            }),
            !_vm.canUpdateTextFields
              ? _c("div", { staticClass: "w-ful bold" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("vue.sessionEndScreenLabelCopy")) +
                      " (" +
                      _vm._s(this.selectedLanguageForWebConnect.lnFullText) +
                      ")\n      "
                  ),
                ])
              : _vm._e(),
            !_vm.canUpdateTextFields
              ? _c("vs-textarea", {
                  staticClass: "w-full p-1",
                  attrs: { counter: "300", height: "80px" },
                  model: {
                    value: _vm.tWebConnectSessionEndScreenDefaultCopy,
                    callback: function ($$v) {
                      _vm.tWebConnectSessionEndScreenDefaultCopy = $$v
                    },
                    expression: "tWebConnectSessionEndScreenDefaultCopy",
                  },
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "feedback-form-colors flex flex-row flex-wrap justify-between w-full",
              },
              [
                _c("div", { staticClass: "flex flex-column flex-grow" }, [
                  _c("div", [_vm._v(_vm._s(_vm.$t("vue.backgroundColor")))]),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.backgroundColor,
                          expression: "backgroundColor",
                        },
                      ],
                      staticClass: "pa-color-picker",
                      class: { "pa-color-picker--mobile-app": _vm.isMobileApp },
                      attrs: {
                        type: "color",
                        name: "sessionEndScreenConfirmButtonColor",
                      },
                      domProps: { value: _vm.backgroundColor },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.backgroundColor = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "flex flex-column flex-grow" }, [
                  _c("div", [_vm._v(_vm._s(_vm.$t("vue.fontColor")))]),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.fontColor,
                          expression: "fontColor",
                        },
                      ],
                      staticClass: "pa-color-picker",
                      class: { "pa-color-picker--mobile-app": _vm.isMobileApp },
                      attrs: {
                        type: "color",
                        name: "sessionEndScreenFontColor",
                      },
                      domProps: { value: _vm.fontColor },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.fontColor = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "flex flex-row items-center" },
              [
                _c(
                  "div",
                  { on: { click: _vm.toggleShowFeedbackForm } },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        enabled: _vm.dialog.isShowFeedbackForm,
                        width: 40,
                        height: 40,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "mx-2",
                    staticStyle: { "font-size": "1.2rem" },
                  },
                  [_vm._v(_vm._s(_vm.$t("vue.feedbackForm")))]
                ),
                _vm.HAS_ACCESS
                  ? _c(
                      "vx-tooltip",
                      {
                        attrs: {
                          text: _vm.$t("vue.feedbackFormTooltip"),
                          position: "top",
                        },
                      },
                      [_c("img", { attrs: { src: _vm.infoImg } })]
                    )
                  : _c(
                      "vx-tooltip",
                      {
                        staticStyle: { height: "20px" },
                        attrs: {
                          text: _vm.$t("upgrade-messages.locked-feature"),
                          position: "top",
                        },
                      },
                      [_c("small-lock-icon")],
                      1
                    ),
              ],
              1
            ),
            _vm.dialog.isShowFeedbackForm
              ? [
                  _vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full" }, [
                        _vm._v(
                          _vm._s(_vm.$t("vue.feedbackFormQuestion")) + " 1"
                        ),
                      ])
                    : _vm._e(),
                  !_vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full bold" }, [
                        _vm._v(
                          _vm._s(_vm.$t("vue.feedbackFormQuestion")) +
                            " 1: (English)"
                        ),
                      ])
                    : _vm._e(),
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      placeholder: _vm.$t("vue.feedbackFormQuestion") + " 1",
                      name: "questionFirst",
                      "data-vv-as": _vm.$t("vue.feedbackFormQuestion") + " 1",
                    },
                    model: {
                      value: _vm.feedbackFormQuestionFirst,
                      callback: function ($$v) {
                        _vm.feedbackFormQuestionFirst = $$v
                      },
                      expression: "feedbackFormQuestionFirst",
                    },
                  }),
                  !_vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full bold" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("vue.feedbackFormQuestion")) +
                            " 1: (" +
                            _vm._s(
                              this.selectedLanguageForWebConnect.lnFullText
                            ) +
                            ")\n        "
                        ),
                      ])
                    : _vm._e(),
                  !_vm.canUpdateTextFields
                    ? _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          placeholder:
                            _vm.$t("vue.feedbackFormQuestion") + " 1",
                          name: "questionFirstTranslation",
                          "data-vv-as":
                            _vm.$t("vue.feedbackFormQuestion") + " 1",
                        },
                        model: {
                          value: _vm.tWebConnectFeedbackFormQuestionFirst,
                          callback: function ($$v) {
                            _vm.tWebConnectFeedbackFormQuestionFirst = $$v
                          },
                          expression: "tWebConnectFeedbackFormQuestionFirst",
                        },
                      })
                    : _vm._e(),
                  _vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full mt-4" }, [
                        _vm._v(
                          _vm._s(_vm.$t("vue.feedbackFormQuestion")) + " 2"
                        ),
                      ])
                    : _vm._e(),
                  !_vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full mt-4 bold" }, [
                        _vm._v(
                          _vm._s(_vm.$t("vue.feedbackFormQuestion")) +
                            " 2: (English)"
                        ),
                      ])
                    : _vm._e(),
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      placeholder: _vm.$t("vue.feedbackFormQuestion") + " 2",
                      name: "questionSecond",
                      "data-vv-as": _vm.$t("vue.feedbackFormQuestion") + " 2",
                    },
                    model: {
                      value: _vm.feedbackFormQuestionSecond,
                      callback: function ($$v) {
                        _vm.feedbackFormQuestionSecond = $$v
                      },
                      expression: "feedbackFormQuestionSecond",
                    },
                  }),
                  !_vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full bold" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("vue.feedbackFormQuestion")) +
                            " 2: (" +
                            _vm._s(
                              this.selectedLanguageForWebConnect.lnFullText
                            ) +
                            ")\n        "
                        ),
                      ])
                    : _vm._e(),
                  !_vm.canUpdateTextFields
                    ? _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          placeholder:
                            _vm.$t("vue.feedbackFormQuestion") + " 2",
                          name: "questionSecondTranslation",
                          "data-vv-as":
                            _vm.$t("vue.feedbackFormQuestion") + " 2",
                        },
                        model: {
                          value: _vm.tWebConnectFeedbackFormQuestionSecond,
                          callback: function ($$v) {
                            _vm.tWebConnectFeedbackFormQuestionSecond = $$v
                          },
                          expression: "tWebConnectFeedbackFormQuestionSecond",
                        },
                      })
                    : _vm._e(),
                  _vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full mt-4" }, [
                        _vm._v(
                          _vm._s(_vm.$t("vue.feedbackFormQuestion")) + " 3"
                        ),
                      ])
                    : _vm._e(),
                  !_vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full mt-4 bold" }, [
                        _vm._v(
                          _vm._s(_vm.$t("vue.feedbackFormQuestion")) +
                            " 3: (English)"
                        ),
                      ])
                    : _vm._e(),
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      placeholder: _vm.$t("vue.feedbackFormQuestion") + " 3",
                      name: "questionThird",
                      "data-vv-as": _vm.$t("vue.feedbackFormQuestion") + " 3",
                    },
                    model: {
                      value: _vm.feedbackFormQuestionThird,
                      callback: function ($$v) {
                        _vm.feedbackFormQuestionThird = $$v
                      },
                      expression: "feedbackFormQuestionThird",
                    },
                  }),
                  !_vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full bold" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("vue.feedbackFormQuestion")) +
                            " 3: (" +
                            _vm._s(
                              this.selectedLanguageForWebConnect.lnFullText
                            ) +
                            ")\n        "
                        ),
                      ])
                    : _vm._e(),
                  !_vm.canUpdateTextFields
                    ? _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          placeholder:
                            _vm.$t("vue.feedbackFormQuestion") + " 3",
                          name: "questionThird",
                          "data-vv-as":
                            _vm.$t("vue.feedbackFormQuestion") + " 3",
                        },
                        model: {
                          value: _vm.tWebConnectFeedbackFormQuestionThird,
                          callback: function ($$v) {
                            _vm.tWebConnectFeedbackFormQuestionThird = $$v
                          },
                          expression: "tWebConnectFeedbackFormQuestionThird",
                        },
                      })
                    : _vm._e(),
                  _vm.dialog.isShowFeedbackForm &&
                  _vm.isAllQuestionsEmpty &&
                  _vm.hasSubmittedForm
                    ? _c("span", { staticClass: "text-danger text-sm mt-2" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "errors.atLeastOneQuestionRequiredForFeedback"
                              )
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm.dialog.isShowFeedbackForm &&
                  !_vm.canUpdateTextFields &&
                  _vm.isAllQuestionsInTranslationsEmpty &&
                  _vm.hasSubmittedForm
                    ? _c("span", { staticClass: "text-danger text-sm mt-2" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "errors.atLeastOneQuestionRequiredForFeedback"
                              )
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "flex flex-row items-center" }, [
                    _c(
                      "div",
                      { on: { click: _vm.toggleSendFeedbackViaMessage } },
                      [
                        _c("toggle-switch-icon", {
                          attrs: {
                            enabled: _vm.dialog.isSendFeedbackViaMessage,
                            width: 40,
                            height: 40,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mx-2",
                        staticStyle: { "font-size": "1.2rem" },
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.feedbackSendViaMessage")))]
                    ),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          {
            staticClass: "flex flex-column relative",
            staticStyle: { "margin-left": "auto" },
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  position: "absolute",
                  right: "20px",
                  top: "20px",
                  cursor: "pointer",
                },
              },
              [_c("close-icon", { attrs: { width: 25, height: 25 } })],
              1
            ),
            _c(
              "div",
              {
                staticClass: "flex justify-center items-center overflow-hidden",
                style: _vm.feedbackFormStyle,
              },
              [
                _c(
                  "scroll-view",
                  {
                    ref: "chatScrollArea",
                    staticClass: "endscreen-scroll-area",
                    staticStyle: { "max-height": "586px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "endscreen-feedback__container" },
                      [
                        _c(
                          "div",
                          { staticClass: "endscreen-feedback__container-head" },
                          [
                            _c(
                              "div",
                              { staticClass: "endscreen-feedback__title" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.dialogSessionEndScreenTitleClosingMessage
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mt-4 endscreen-feedback__subtitle",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dialogSessionEndScreenCopyClosingMessage
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm.dialog.isShowFeedbackForm
                          ? _c(
                              "div",
                              { staticClass: "endscreen-feedback__form" },
                              [
                                _vm.dialogFeedbackFormQuestionFirst
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "endscreen-feedback__field",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "endscreen-feedback__field-title",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dialogFeedbackFormQuestionFirst
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("rating-stars", {
                                          attrs: {
                                            grade: 5,
                                            maxStars: 5,
                                            disabled: true,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.dialogFeedbackFormQuestionSecond
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "endscreen-feedback__field",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "endscreen-feedback__field-title",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dialogFeedbackFormQuestionSecond
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("rating-stars", {
                                          attrs: {
                                            grade: 0,
                                            maxStars: 5,
                                            disabled: true,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.dialogFeedbackFormQuestionThird
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "endscreen-feedback__field",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "endscreen-feedback__field-title",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dialogFeedbackFormQuestionThird
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("rating-stars", {
                                          attrs: {
                                            grade: 0,
                                            maxStars: 5,
                                            disabled: true,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.dialog.isSendFeedbackViaMessage
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "endscreen-feedback__message",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "upper-case" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("inputs.message")) +
                                                "..."
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.dialog.isShowFeedbackForm &&
                                _vm.isAnyFeedbackOptionActive
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "endscreen-feedback__action-button flex items-center justify-center",
                                          style:
                                            _vm.feedbackFormSendButtonBackgroundStyle,
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("vue.sendMessage")
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "endscreen-feedback__cancel-button mt-4 flex items-center justify-center",
                                          style:
                                            _vm.feedbackFormCloseButtonBackgroundStyle,
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("vue.close")) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("powered-by"),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }