var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showOwnMedia
        ? _c(
            "vs-popup",
            {
              staticClass: "media-popup",
              attrs: {
                fullscreen: "",
                active: _vm.showOwnMedia,
                "button-close-hidden": "",
              },
              on: {
                "update:active": function ($event) {
                  _vm.showOwnMedia = $event
                },
              },
            },
            [
              _c("div", { key: _vm.ownMediaKey, staticClass: "relative" }, [
                _c(
                  "div",
                  {
                    staticClass: "popover-action flex justify-end items-center",
                    attrs: { disabled: "" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "popover-action-button mr-2",
                        on: { click: _vm.onOwnMediaSave },
                      },
                      [
                        _c("check-icon", {
                          attrs: { color: "#00ff00", width: 35, height: 35 },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "popover-action-button ml-2",
                        on: {
                          click: function ($event) {
                            _vm.showOwnMedia = false
                          },
                        },
                      },
                      [
                        _c("close-icon", {
                          attrs: { color: "#ff0000", width: 35, height: 35 },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", [
                  _c("div", { staticClass: "media-popup__wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "media-popup__left" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-row items-center media-popup__toggle",
                          },
                          [
                            _c(
                              "div",
                              {
                                on: { click: _vm.toggleUseOneMediaForAllSteps },
                              },
                              [
                                _c("toggle-switch-icon", {
                                  attrs: {
                                    enabled: _vm.useOneMediaForAllSteps,
                                    width: 40,
                                    height: 40,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "media-popup__toggle-label" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("uploadMedia.useOneImageForAllSteps")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "vx-tooltip",
                              {
                                staticClass: "media-popup__toggle-tooltip",
                                attrs: {
                                  text: _vm.$t(
                                    "uploadMedia.useOneImageForAllStepsInfoIcon"
                                  ),
                                  position: "top",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass:
                                    "media-popup__toggle-tooltip-img",
                                  attrs: { src: _vm.infoImg },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.useOneMediaForAllSteps
                          ? [
                              _c(
                                "div",
                                { staticClass: "media-popup__upload-holder" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "media-popup__headline" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("uploadMedia.allSteps")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  !_vm.dialog.allMedia
                                    ? _c("upload-image-or-video", {
                                        attrs: { uploadType: "allMedia" },
                                        on: {
                                          "upload-image-or-video":
                                            _vm.onUploadImageOrVideo,
                                        },
                                      })
                                    : _c("media-preview", {
                                        attrs: {
                                          ownMediaKey: _vm.ownMediaKey,
                                          uploadType: "allMedia",
                                          dialog: _vm.dialog,
                                        },
                                        on: {
                                          "media-delete": function () {
                                            return _vm.deleteMedia("allMedia")
                                          },
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]
                          : [
                              _vm.dialog &&
                              _vm.dialog.type === "button" &&
                              _vm.dialog.useNewButtonPlusTextStyle
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "media-popup__upload-holder",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "media-popup__headline flex items-center",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t("vue.step1Headline2")
                                                ) +
                                                "\n                    "
                                            ),
                                            _c(
                                              "vx-tooltip",
                                              {
                                                staticClass:
                                                  "media-popup__toggle-tooltip ml-2",
                                                attrs: {
                                                  text: _vm.$t(
                                                    "uploadMedia.step1ButtonInfoIcon"
                                                  ),
                                                  position: "top",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass:
                                                    "media-popup__toggle-tooltip-img",
                                                  attrs: { src: _vm.infoImg },
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        !_vm.dialog.buttonPlusTextNewMedia
                                          ? _c("upload-image-or-video", {
                                              attrs: {
                                                uploadType:
                                                  "buttonPlusTextNewMedia",
                                              },
                                              on: {
                                                "upload-image-or-video":
                                                  _vm.onUploadImageOrVideo,
                                              },
                                            })
                                          : _c("media-preview", {
                                              attrs: {
                                                ownMediaKey: _vm.ownMediaKey,
                                                uploadType:
                                                  _vm.useOneMediaForAllSteps
                                                    ? "allMedia"
                                                    : "buttonPlusTextNewMedia",
                                                dialog: _vm.dialog,
                                              },
                                              on: {
                                                "media-delete": function () {
                                                  return _vm.deleteMedia(
                                                    "buttonPlusTextNewMedia"
                                                  )
                                                },
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "media-popup__upload-holder",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "media-popup__headline flex items-center",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.dialog.type ===
                                                    "button-card"
                                                    ? _vm.$t(
                                                        "vue.step1Headline"
                                                      )
                                                    : _vm.$t(
                                                        "vue.step1Headline2"
                                                      )
                                                ) +
                                                "\n                    "
                                            ),
                                            _c(
                                              "vx-tooltip",
                                              {
                                                staticClass:
                                                  "media-popup__toggle-tooltip ml-2",
                                                attrs: {
                                                  text: _vm.$t(
                                                    "uploadMedia.step1ButtonInfoIcon"
                                                  ),
                                                  position: "top",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass:
                                                    "media-popup__toggle-tooltip-img",
                                                  attrs: { src: _vm.infoImg },
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        !_vm.dialog.buttonMedia
                                          ? _c("upload-image-or-video", {
                                              attrs: {
                                                uploadType: "buttonMedia",
                                              },
                                              on: {
                                                "upload-image-or-video":
                                                  _vm.onUploadImageOrVideo,
                                              },
                                            })
                                          : _c("media-preview", {
                                              attrs: {
                                                ownMediaKey: _vm.ownMediaKey,
                                                uploadType:
                                                  _vm.useOneMediaForAllSteps
                                                    ? "allMedia"
                                                    : "buttonMedia",
                                                dialog: _vm.dialog,
                                              },
                                              on: {
                                                "media-delete": function () {
                                                  return _vm.deleteMedia(
                                                    "buttonMedia"
                                                  )
                                                },
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "media-popup__upload-holder",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "media-popup__headline",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "vue.step1SmallHeadline"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        !_vm.dialog.smallButtonMedia
                                          ? _c("upload-image-or-video", {
                                              attrs: {
                                                uploadType: "smallButtonMedia",
                                              },
                                              on: {
                                                "upload-image-or-video":
                                                  _vm.onUploadImageOrVideo,
                                              },
                                            })
                                          : _c("media-preview", {
                                              attrs: {
                                                ownMediaKey: _vm.ownMediaKey,
                                                uploadType:
                                                  _vm.useOneMediaForAllSteps
                                                    ? "allMedia"
                                                    : "smallButtonMedia",
                                                dialog: _vm.dialog,
                                              },
                                              on: {
                                                "media-delete": function () {
                                                  return _vm.deleteMedia(
                                                    "smallButtonMedia"
                                                  )
                                                },
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                              _c(
                                "div",
                                { staticClass: "media-popup__upload-holder" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "media-popup__headline" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("vue.step2Headline"))
                                      ),
                                    ]
                                  ),
                                  !_vm.dialog.welcomeScreenMedia
                                    ? _c("upload-image-or-video", {
                                        attrs: {
                                          uploadType: "welcomeScreenMedia",
                                        },
                                        on: {
                                          "upload-image-or-video":
                                            _vm.onUploadImageOrVideo,
                                        },
                                      })
                                    : _c("media-preview", {
                                        attrs: {
                                          ownMediaKey: _vm.ownMediaKey,
                                          uploadType: _vm.useOneMediaForAllSteps
                                            ? "allMedia"
                                            : "welcomeScreenMedia",
                                          dialog: _vm.dialog,
                                        },
                                        on: {
                                          "media-delete": function () {
                                            return _vm.deleteMedia(
                                              "welcomeScreenMedia"
                                            )
                                          },
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    ),
                    _c("div", { staticClass: "media-popup__right" }, [
                      _c("div", { staticClass: "media-popup__title" }, [
                        _vm._v("Preview"),
                      ]),
                      _c("div", { staticClass: "flex w-full" }, [
                        _c("div", { staticClass: "flex items-start" }, [
                          _vm.dialog &&
                          _vm.dialog.type === "button" &&
                          _vm.dialog.useNewButtonPlusTextStyle
                            ? _c(
                                "div",
                                { staticClass: "flex flex-col mr-6" },
                                [
                                  _c("button-plus-text-new-preview", {
                                    attrs: {
                                      uploadType: _vm.useOneMediaForAllSteps
                                        ? "allMedia"
                                        : "buttonPlusTextNewMedia",
                                      uploadSubtype:
                                        _vm.useOneMediaForAllSteps &&
                                        typeof _vm.dialog.allMedia ===
                                          "object" &&
                                        _vm.dialog.allMedia !== null
                                          ? "buttonPlusTextNewMedia"
                                          : "",
                                      dialog: _vm.dialog,
                                      imageURL: _vm.defaultImageURL,
                                      embedVideoURL: _vm.defaultVideoURL,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("div", { staticClass: "flex flex-col mr-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-col mb-8" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "media-popup__headline" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dialog.type === "button-card"
                                              ? _vm.$t("vue.step1Headline")
                                              : _vm.$t("vue.step1Headline2")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-col" },
                                      [
                                        _c("button-preview", {
                                          attrs: {
                                            ownMediaKey: _vm.ownMediaKey,
                                            uploadType:
                                              _vm.useOneMediaForAllSteps
                                                ? "allMedia"
                                                : "buttonMedia",
                                            uploadSubtype:
                                              _vm.useOneMediaForAllSteps &&
                                              typeof _vm.dialog.allMedia ===
                                                "object" &&
                                              _vm.dialog.allMedia !== null
                                                ? "buttonMedia"
                                                : "",
                                            dialog: _vm.dialog,
                                            imageURL: _vm.defaultImageURL,
                                            embedVideoURL: _vm.defaultVideoURL,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex flex-col" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "media-popup__headline" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("vue.step1SmallHeadline")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("button-preview", {
                                      attrs: {
                                        ownMediaKey: _vm.ownMediaKey,
                                        previewType: "small",
                                        uploadType: _vm.useOneMediaForAllSteps
                                          ? "allMedia"
                                          : "smallButtonMedia",
                                        uploadSubtype:
                                          _vm.useOneMediaForAllSteps &&
                                          typeof _vm.dialog.allMedia ===
                                            "object" &&
                                          _vm.dialog.allMedia !== null
                                            ? "smallButtonMedia"
                                            : "",
                                        dialog: _vm.dialog,
                                        imageURL: _vm.defaultImageURL,
                                        embedVideoURL: _vm.defaultVideoURL,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                          _c(
                            "div",
                            { staticClass: "flex flex-col" },
                            [
                              _c(
                                "div",
                                { staticClass: "media-popup__headline" },
                                [_vm._v(_vm._s(_vm.$t("vue.step2Headline")))]
                              ),
                              _vm.isWelcomeScreenMediaVideo
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-row justify-start items-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: _vm.toggleActivateVideoSound,
                                          },
                                        },
                                        [
                                          _c("toggle-switch-icon", {
                                            attrs: {
                                              enabled: _vm.activateVideoSound,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c("volume-icon", {
                                            staticClass: "ml-2",
                                            attrs: {
                                              height: 12,
                                              stroke: _vm.activateVideoSound
                                                ? "#3B86F7"
                                                : "#d3d3d3",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "ml-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("vue.activateVideoSound")
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ml-2",
                                          staticStyle: { height: "17px" },
                                        },
                                        [
                                          _c(
                                            "vx-tooltip",
                                            {
                                              attrs: {
                                                text: _vm.$t(
                                                  "info.activateVideoSound"
                                                ),
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.infoImg,
                                                  width: "17",
                                                  height: "17",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("welcome-screen-preview", {
                                attrs: {
                                  id: _vm.id + "-display-options",
                                  uploadType: _vm.useOneMediaForAllSteps
                                    ? "allMedia"
                                    : "welcomeScreenMedia",
                                  uploadSubtype:
                                    _vm.useOneMediaForAllSteps &&
                                    typeof _vm.dialog.allMedia === "object" &&
                                    _vm.dialog.allMedia !== null
                                      ? "welcomeScreenMedia"
                                      : "",
                                  dialog: _vm.dialog,
                                  imageURL: _vm.defaultImageURL,
                                  embedVideoURL: _vm.defaultVideoURL,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "vs-popup",
        {
          staticClass: "image-popup",
          attrs: {
            title: _vm.$t("vue.cropImage"),
            active: _vm.showCroppa,
            "button-close-hidden": "",
          },
          on: {
            "update:active": function ($event) {
              _vm.showCroppa = $event
            },
          },
        },
        [
          _c("div", { staticClass: "relative" }, [
            _c(
              "div",
              {
                staticClass: "popover-action flex justify-end items-center",
                attrs: { disabled: "" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "popover-action-button mr-2",
                    on: { click: _vm.onCroppaSave },
                  },
                  [
                    _c("check-icon", {
                      attrs: { color: "#00ff00", width: 35, height: 35 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "popover-action-button ml-2",
                    on: {
                      click: function ($event) {
                        _vm.showCroppa = false
                      },
                    },
                  },
                  [
                    _c("close-icon", {
                      attrs: { color: "#ff0000", width: 35, height: 35 },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "croppa-uploaded-pics" },
              [
                _vm.uploadType === "allMedia"
                  ? _vm._l(_vm.allMediaTypes, function (type, i) {
                      return _c("croppa", {
                        key: i,
                        staticClass: "resizable-croppa",
                        attrs: {
                          width: type.croppaWidth,
                          height: type.croppaHeight,
                          placeholder: "Click here",
                          "show-remove-button": false,
                          "canvas-color": "transparent",
                          "prevent-white-space": "",
                          "initial-image": _vm.initialImage,
                          "image-border-radius": 10,
                          accept: ".jpeg,.jpg,.gif,.png",
                          "disable-drag-to-move": false,
                          "file-size-limit": 2048000,
                          "placeholder-font-size": 14,
                          quality: 2,
                          disabled: false,
                        },
                        model: {
                          value: _vm.allMediaTypes[i].myPhoto,
                          callback: function ($$v) {
                            _vm.$set(_vm.allMediaTypes[i], "myPhoto", $$v)
                          },
                          expression: "allMediaTypes[i].myPhoto",
                        },
                      })
                    })
                  : [
                      _c("croppa", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.myPhoto.img,
                            expression: "myPhoto.img",
                          },
                        ],
                        staticClass: "resizable-croppa",
                        attrs: {
                          width: _vm.croppaWidth,
                          height: _vm.croppaHeight,
                          placeholder: "Click here",
                          "show-remove-button": false,
                          "canvas-color": "transparent",
                          "prevent-white-space": "",
                          "initial-image": _vm.initialImage,
                          "image-border-radius": 10,
                          accept: ".jpeg,.jpg,.gif,.png",
                          "disable-drag-to-move": false,
                          "file-size-limit": 2048000,
                          "placeholder-font-size": 14,
                          quality: 2,
                          disabled: false,
                        },
                        model: {
                          value: _vm.myPhoto,
                          callback: function ($$v) {
                            _vm.myPhoto = $$v
                          },
                          expression: "myPhoto",
                        },
                      }),
                    ],
              ],
              2
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "vx-row w-full mt-5 ml-2" }, [
        _c("div", { staticClass: "vs-col w-full mb-2" }, [
          _c("div", { staticClass: "flex flex-row items-center" }, [
            _c("img", {
              staticClass: "pa-number",
              attrs: { src: require("@/assets/images/elements/first.svg") },
            }),
            _c(
              "div",
              { staticClass: "mx-2", staticStyle: { "font-size": "1.2rem" } },
              [_vm._v(_vm._s(_vm.$t("vue.buttonStyle")))]
            ),
          ]),
        ]),
        _c("div", { staticClass: "vx-row w-full mt-2 ml-2" }, [
          _c(
            "div",
            { staticClass: "flex flex-col justify-between items-center mt-5" },
            [
              _c(
                "div",
                { staticClass: "media-type w-full" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "media-type__item _dialog-type flex flex-col",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "media-type__check",
                          class: {
                            _active: _vm.dialog.type === "button-card",
                          },
                        },
                        [
                          _c("check-icon", {
                            attrs: { width: 15, height: 15 },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "media-type__wrapper",
                          on: {
                            click: function ($event) {
                              return _vm.updateDialogType("button-card")
                            },
                          },
                        },
                        [
                          _c("dialog-type-image-icon", {
                            staticClass: "absolute",
                            staticStyle: { top: "-2px" },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "media-type__icon-holder flex items-center",
                            },
                            [
                              _c("button-dialog-icon", {
                                attrs: {
                                  color:
                                    _vm.dialog.type === "button-card"
                                      ? "#3B86F7"
                                      : "#575757",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "media-type__title",
                          class: {
                            _active: _vm.dialog.type === "button-card",
                          },
                        },
                        [_vm._v("\n              Button\n            ")]
                      ),
                    ]
                  ),
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "cursor-pointer",
                      staticStyle: { "max-width": "168px" },
                      attrs: { "vs-trigger-click": "" },
                    },
                    [
                      _c("small", { staticClass: "flex cursor-pointer" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "media-type__item _dialog-type flex flex-col",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "media-type__check",
                                class: {
                                  _active: _vm.dialog.type === "button",
                                },
                              },
                              [
                                _c("check-icon", {
                                  attrs: { width: 15, height: 15 },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "media-type__wrapper",
                                on: {
                                  click: function ($event) {
                                    return _vm.updateDialogType("button")
                                  },
                                },
                              },
                              [
                                _c("dialog-type-image-icon", {
                                  staticClass: "absolute",
                                  staticStyle: { top: "-2px" },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "media-type__icon-holder flex items-center",
                                  },
                                  [
                                    _c("talk-icon", {
                                      attrs: {
                                        color:
                                          _vm.dialog.type === "button"
                                            ? "#3B86F7"
                                            : "#575757",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "media-type__title",
                                class: {
                                  _active: _vm.dialog.type === "button",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  Button + Text\n                "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "vs-dropdown-menu",
                        { staticClass: "w-32" },
                        [
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function () {
                                  return _vm.updateNewButtonPlusTextStyle(false)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("vue.buttonOldStyle")))]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function () {
                                  return _vm.updateNewButtonPlusTextStyle(true)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("vue.buttonNewStyle")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "mt-5 w-full flex flex-row items-center" },
            [
              _c(
                "div",
                { staticClass: "mx-2", staticStyle: { "font-size": "1.2rem" } },
                [_vm._v(_vm._s(_vm.$t("vue.position")) + ":")]
              ),
              _c(
                "vs-radio",
                {
                  staticClass: "mr-4",
                  attrs: { "vs-value": "left", "vs-name": "widget-position" },
                  model: {
                    value: _vm.widgetPosition,
                    callback: function ($$v) {
                      _vm.widgetPosition = $$v
                    },
                    expression: "widgetPosition",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("vue.widgetLeftPosition")))]
              ),
              _c(
                "vs-radio",
                {
                  staticClass: "mr-4",
                  attrs: { "vs-value": "right", "vs-name": "widget-position" },
                  model: {
                    value: _vm.widgetPosition,
                    callback: function ($$v) {
                      _vm.widgetPosition = $$v
                    },
                    expression: "widgetPosition",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("vue.widgetRightPosition")))]
              ),
            ],
            1
          ),
          !_vm.dialog.useNewButtonPlusTextStyle
            ? _c(
                "div",
                { staticClass: "mt-2 w-full flex flex-row items-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mx-2",
                      staticStyle: { "font-size": "1.2rem" },
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.squareButton")))]
                  ),
                  _c(
                    "div",
                    { on: { click: _vm.toggleAlwaysShowButton } },
                    [
                      _c("toggle-switch-icon", {
                        attrs: {
                          enabled: _vm.isAlwaysShowButtonEnabled,
                          width: 40,
                          height: 40,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: _vm.$t("info.alwaysVisibileSmallButtonInfo"),
                        position: "top",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.infoImg } })]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "vx-row w-full mt-5 ml-2" }, [
        _c("div", { staticClass: "vs-col w-full mb-2" }, [
          _c(
            "div",
            { staticClass: "flex flex-row items-center" },
            [
              _c("img", {
                staticClass: "pa-number",
                attrs: { src: require("@/assets/images/elements/second.svg") },
              }),
              _c(
                "div",
                { staticClass: "mx-2", staticStyle: { "font-size": "1.2rem" } },
                [_vm._v(_vm._s(_vm.$t("uploadMedia.media")))]
              ),
              _c(
                "vx-tooltip",
                {
                  attrs: {
                    text: _vm.$t("uploadMedia.mediaInfoIcon"),
                    position: "top",
                  },
                },
                [_c("img", { attrs: { src: _vm.infoImg } })]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "flex flex-col justify-between items-center mt-2 ml-2",
          },
          [
            _c("div", { staticClass: "media-type w-full" }, [
              _c("div", { staticClass: "media-type__item flex flex-col" }, [
                _c(
                  "div",
                  {
                    staticClass: "media-type__check",
                    class: {
                      _active: _vm.dialog.showDefaultMedia,
                    },
                  },
                  [_c("check-icon", { attrs: { width: 15, height: 15 } })],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "media-type__wrapper",
                    on: {
                      click: function ($event) {
                        return _vm.updateShowMedia(true)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "flex items-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "media-type__subtype _video flex-center pointer",
                          class: {
                            _active:
                              _vm.dialog.showVideo &&
                              _vm.dialog.showDefaultMedia,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.updateShowVideo(true)
                            },
                          },
                        },
                        [_c("video-call-icon")],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "media-type__subtype _images flex-center pointer",
                          class: {
                            _active:
                              !_vm.dialog.showVideo &&
                              _vm.dialog.showDefaultMedia,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.updateShowVideo(false)
                            },
                          },
                        },
                        [_c("image-icon")],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "media-type__title",
                    class: {
                      _active: _vm.dialog.showDefaultMedia,
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("uploadMedia.useDefaultMedia")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "media-type__item flex flex-col" }, [
                _c(
                  "div",
                  {
                    staticClass: "media-type__check",
                    class: {
                      _active: !_vm.dialog.showDefaultMedia,
                    },
                  },
                  [_c("check-icon", { attrs: { width: 15, height: 15 } })],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "media-type__wrapper",
                    on: {
                      click: function ($event) {
                        return _vm.updateShowMedia(false)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "media-type__upload",
                        class: {
                          _active: !_vm.dialog.showDefaultMedia,
                        },
                      },
                      [
                        _vm.isAnyOwnMediaUploaded
                          ? _c("edit-icon", {
                              attrs: { width: 25, height: 25 },
                            })
                          : _c("upload-icon", {
                              attrs: { width: 25, height: 25 },
                            }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "media-type__title",
                    class: {
                      _active: !_vm.dialog.showDefaultMedia,
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.isAnyOwnMediaUploaded
                            ? _vm.$t("uploadMedia.editYourOwnMedia")
                            : _vm.$t("uploadMedia.uploadYourOwnMedia")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _vm.dialog.type === "button-card"
        ? _c("div", { staticClass: "vx-row w-full mt-5 ml-2" }, [
            _c("div", { staticClass: "vs-col w-full mb-2" }, [
              _c("div", { staticClass: "flex items-center" }, [
                _c("img", {
                  staticClass: "pa-number",
                  attrs: { src: require("@/assets/images/elements/third.svg") },
                }),
                _c(
                  "div",
                  { staticClass: "flex flex-row justify-center items-center" },
                  [
                    _c(
                      "div",
                      { on: { click: _vm.toggleMinimizeSmallButton } },
                      [
                        _c("toggle-switch-icon", {
                          attrs: {
                            enabled: _vm.dialog.isMinimizeSmallButtonEnabled,
                            width: 40,
                            height: 40,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mx-2",
                        staticStyle: { "font-size": "1.2rem" },
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.minimizeSmallButtonText")))]
                    ),
                    _c(
                      "vx-tooltip",
                      {
                        attrs: {
                          text: _vm.$t("info.minimizeSmallButtonInfo"),
                          position: "top",
                        },
                      },
                      [_c("img", { attrs: { src: _vm.infoImg } })]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.dialog.isMinimizeSmallButtonEnabled,
                    expression: "dialog.isMinimizeSmallButtonEnabled",
                  },
                ],
                staticClass: "vs-col w-full display-options__small-button-col",
              },
              [
                _c(
                  "div",
                  { staticClass: "vx-row w-full" },
                  [
                    _c("vs-col", { attrs: { "vs-w": "12" } }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-col display-options__small-button-holder",
                        },
                        [
                          _c("div", { staticClass: "flex" }, [
                            _c(
                              "div",
                              { staticClass: "flex flex-col" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "display-options__small-button-img-wrapper",
                                  },
                                  [
                                    _c("img", {
                                      staticClass:
                                        "display-options__small-button-img w-full h-full pointer",
                                      attrs: {
                                        slot: "initial",
                                        crossorigin: "anonymous",
                                        src:
                                          _vm.dialog.smallButtonImage ||
                                          _vm.defaultSmallButtonImage,
                                      },
                                      slot: "initial",
                                    }),
                                  ]
                                ),
                                _vm.dialog.smallButtonImage
                                  ? _c(
                                      "vs-button",
                                      {
                                        staticClass:
                                          "delete-btn-padding mt-6 ml-auto mr-auto",
                                        attrs: {
                                          type: "border",
                                          color: "danger",
                                          size: "large",
                                          "icon-pack": "material-icons",
                                          icon: "photo",
                                          "icon-after": "",
                                        },
                                        on: {
                                          click: _vm.removeSmallButtonImage,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.$t("vue.delete")) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center display-options__small-button-upload-wrapper",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "upload-photo mr-2 sm:mb-0 mb-2 upload p-0 pointer display-options__small-button-upload",
                                      attrs: { for: "svg-file-input" },
                                    },
                                    [
                                      _c("input", {
                                        staticStyle: { display: "none" },
                                        attrs: {
                                          type: "file",
                                          id: "svg-file-input",
                                          accept:
                                            "image/png, image/jpeg, image/svg+xml",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.selectMinimizedSmallButtonImage(
                                              $event
                                            )
                                          },
                                          click: _vm.onFileInputClick,
                                        },
                                      }),
                                      _c("div", { staticClass: "con-upload" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "con-img-upload mt-0",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "con-input-upload",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-input",
                                                    staticStyle: {
                                                      color: "#000",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "vue.selectPicture"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-upload-all vs-upload--button-upload",
                                                    attrs: {
                                                      disabled: "disabled",
                                                      type: "button",
                                                      title: "Upload",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "material-icons notranslate",
                                                        attrs: {
                                                          translate:
                                                            "translate",
                                                        },
                                                      },
                                                      [_vm._v(" cloud_upload ")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "max-width": "116px",
                                        "padding-left": "3px",
                                        "font-size": "0.75rem",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "vue.uploadSVGSmallButtonIconText"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-row w-full mt-5 -ml-4" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vs-col w-full mb-2 display-options__small-button-preview-col",
                    },
                    [
                      _c("div", { staticClass: "flex flex-row items-center" }, [
                        _c(
                          "div",
                          { on: { click: _vm.toggleSmallButtonPreview } },
                          [
                            _c("toggle-switch-icon", {
                              attrs: {
                                enabled: _vm.dialog.isSmallButtonPreviewVisible,
                                width: 40,
                                height: 40,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mx-2",
                            staticStyle: { "font-size": "1.2rem" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("vue.showSmallButtonPreviewText"))
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }