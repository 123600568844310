var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "feather feather-image",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        fill: "none",
        stroke: _vm.color,
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
      },
    },
    [
      _c("rect", {
        attrs: { x: "3", y: "3", width: "18", height: "18", rx: "2", ry: "2" },
      }),
      _c("circle", { attrs: { cx: "8.5", cy: "8.5", r: "1.5" } }),
      _c("polyline", { attrs: { points: "21 15 16 10 5 21" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }