var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        color: _vm.color,
        viewBox: "0 0 18 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8.15659 2.68652H2.59035C2.16857 2.68652 1.76405 2.85408 1.4658 3.15233C1.16755 3.45058 1 3.8551 1 4.27689V15.4094C1 15.8312 1.16755 16.2358 1.4658 16.534C1.76405 16.8323 2.16857 16.9998 2.59035 16.9998H13.7228C14.1446 16.9998 14.5491 16.8323 14.8474 16.534C15.1456 16.2358 15.3132 15.8312 15.3132 15.4094V9.84317",
          stroke: "white",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.1205 1.49406C14.4368 1.17772 14.8659 1 15.3132 1C15.7606 1 16.1897 1.17772 16.506 1.49406C16.8223 1.81041 17.0001 2.23946 17.0001 2.68684C17.0001 3.13421 16.8223 3.56327 16.506 3.87961L8.95182 11.4338L5.77112 12.229L6.56629 9.0483L14.1205 1.49406Z",
          stroke: "white",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }