<template>
  <div class="media-preview">
    <div class="media-preview__delete" @click="$emit('media-delete')">
      <vx-tooltip class="flex items-center justify-center w-full h-full" :text="$t('uploadMedia.delete')" position="top">
        <trash-icon :width="12" :height="12" />
      </vx-tooltip>
    </div>

    <template v-if="mediaImg">
      <div v-if="mediaImg.indexOf('.webm') > -1 || mediaImg.indexOf('.mp4') > -1" class="media-preview__video-container">
        <video class="media-preview__video" playsinline autoplay muted loop :key="mediaKey">
          <source v-if="mediaImg.indexOf('.webm') > -1" :src="mediaImg" :type="webm" />
          <source v-if="mediaImg.indexOf('.mp4') > -1" :src="mediaImg" :type="mp4" />
        </video>
      </div>
      <div v-else class="media-preview__img-container">
        <img class="media-preview__img" :src="mediaImg" />
      </div>
    </template>
  </div>
</template>

<script>
import TrashIcon from '@/components/icons/TrashIcon'
export default {
  name: 'MediaPreview',
  components: {
    TrashIcon
  },
  props: {
    ownMediaKey: {
      type: String
    },
    uploadType: {
      type: String
    },
    dialog: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      webm: 'video/webm',
      mp4: 'video/mp4'
    }
  },
  computed: {
    mediaKey() {
      return `${this.ownMediaKey}_${this.uploadType}`
    },
    mediaImg() {
      if (this.dialog && this.dialog[this.uploadType]) {
        if (typeof this.dialog[this.uploadType] !== 'object') {
          return `${this.dialog[this.uploadType]}`
        } else {
          return `${this.dialog[this.uploadType].buttonMedia}`
        }
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.media-preview {
  height: 100px;
  min-width: 160px;
  width: 160px;
  position: relative;
  &__delete {
    min-width: 25px;
    width: 25px;
    height: 25px;
    background: #f05541;
    border: 1.5px solid #f05541;
    box-sizing: border-box;
    border-radius: 6px;
    position: absolute;
    top: -12.5px;
    right: -12.5px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__img-container,
  &__video-container {
    height: 100px;
    min-width: 160px;
    width: 160px;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    img,
    video {
      width: 160px;
      height: 100px;
      object-fit: cover;
    }
  }
}
</style>
