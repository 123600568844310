<template>
  <div class="configure-dialog-step-three" :key="tabKey">
    <div class="flex-box-column-start">
      <div class="vx-row w-full">
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="headingfont">{{ $t('vue.customizeYourDialog') }}</div>
        </vs-col>
        <!-- <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="translation--container">
            <div class="mr-4"><info-icon fill="#01AAF4" color="#fff" :width="20" :height="20" /></div>
            <div v-html="$t('info.translation')"></div>
          </div>
        </vs-col> -->
      </div>
    </div>

    <div class="mt-4 flex flex-row">
      <form class="flex flex-column vx-col lg:w-6/12 w-full mt-2 mr-4">
        <div class="step-headline">
          {{ dialog.type === 'button-card' ? $t('vue.step1Headline') : $t('vue.step1Headline2') }}
        </div>

        <div v-if="dialog.type === 'popup'" class="vx-col w-full mt-5">
          <div v-if="!canUpdateTextFields" class="w-full bold">English</div>
          <vs-input class="w-full" :label="$t('inputs.title')" v-model="dialogTitle" maxlength="70" />
          <div v-if="!canUpdateTextFields" class="w-full bold">{{ this.selectedLanguageForWebConnect.lnFullText }}</div>
          <vs-input v-if="!canUpdateTextFields" class="w-full" :label="$t('inputs.title')" v-model="tWebConnectDialogTitleDefault" maxlength="70" />
        </div>
        <div class="vs-col w-full mt-5" v-if="dialog.type === 'popup'">
          <div v-if="!canUpdateTextFields" class="w-full bold">English</div>
          <vs-textarea counter="300" height="100px" :label="$t('vue.popupText')" :counter-danger.sync="counterDangerPopupText" v-model="dialogText" />
          <div v-if="!canUpdateTextFields" class="w-full bold">{{ this.selectedLanguageForWebConnect.lnFullText }}</div>
          <vs-textarea
            v-if="!canUpdateTextFields"
            counter="300"
            height="100px"
            :label="$t('vue.popupText')"
            :counter-danger.sync="counterDangerPopupText"
            v-model="tWebConnectPopupTextDefault"
          />
        </div>
        <div class="vs-col w-full mt-5" v-if="dialog.type === 'button'">
          <div v-if="!canUpdateTextFields" class="w-full bold">English</div>
          <vs-textarea counter="300" height="100px" :label="$t('vue.speechBubbleText')" :counter-danger.sync="counterDangerPopupText" v-model="dialogText" />
          <div v-if="!canUpdateTextFields" class="w-full bold">{{ this.selectedLanguageForWebConnect.lnFullText }}</div>
          <vs-textarea
            v-if="!canUpdateTextFields"
            counter="300"
            height="100px"
            :label="$t('vue.speechBubbleText')"
            :counter-danger.sync="counterDangerPopupText"
            v-model="tWebConnectPopupTextDefault"
          />
        </div>

        <div class="mb-4" v-if="dialog.type === 'button' && dialog.useNewButtonPlusTextStyle">
          <div class="mt-2">
            <label class="call-to-action">{{ $t('vue.buttonColor') }}</label>
          </div>
          <input class="pa-color-picker" type="color" v-model="buttonPlusTextNewMediaIconColor" name="buttonPlusTextNewMediaIconColor" />
        </div>

        <div class="vs-col w-full" v-if="dialog.type === 'button' && !dialog.useNewButtonPlusTextStyle">
          <div v-if="!canUpdateTextFields" class="w-full bold">{{ $t('inputs.callToAction') }} (English)</div>
          <vs-input class="w-full" v-model="confirmButtonText" />
          <div v-if="!canUpdateTextFields" class="w-full bold">{{ $t('inputs.callToAction') }} ({{ this.selectedLanguageForWebConnect.lnFullText }})</div>
          <vs-input v-if="!canUpdateTextFields" class="w-full" v-model="tWebConnectConfirmButtonTextDefault" />
        </div>
        <div class="vs-col w-full md:w-1/12" v-if="dialog.type === 'popup'">&nbsp;</div>
        <div class="vs-col w-full md:w-5/12" v-if="dialog.type === 'popup'">
          <div v-if="!canUpdateTextFields" class="w-full">{{ $t('inputs.rejectButton') }} (English)</div>
          <vs-input class="w-full" v-model="cancelButtonText" />
          <div v-if="!canUpdateTextFields" class="w-full">{{ $t('inputs.callToAction') }} ({{ this.selectedLanguageForWebConnect.lnFullText }})</div>
          <vs-input class="w-full" v-model="tWebConnectCancelButtonTextDefault" />
        </div>
        <div class="mt-4 w-full" v-if="(dialog.type === 'button' && !dialog.useNewButtonPlusTextStyle) || dialog.type === 'button-card'">
          <div class="mb-2 flex items-start">
            <div class="ml-4" v-show="!hasWhiteLabel">
              <vx-tooltip :text="$t('vue.step2SloganLockInfo')" position="top">
                <img :src="require('@/assets/images/elements/lock.svg')" alt="lock-icon" />
              </vx-tooltip>
            </div>
          </div>
          <div v-if="!canUpdateTextFields" class="w-full bold">English</div>
          <div class="flex w-full slogan__headline">
            <div class="w-full">
              <vs-textarea
                class="w-full"
                counter="14"
                maxLength="14"
                height="39.59px"
                :placeholder="$t('vue.step2SloganHeadlinePlaceholder')"
                :counter-danger.sync="counterDangerSloganTitle"
                v-model="sloganTitle"
                :disabled="!hasWhiteLabel"
              />
            </div>
            <div class="ml-2">
              <vx-tooltip :text="$t('vue.step2SloganInputInfo')" position="top">
                <img :src="infoImg" />
              </vx-tooltip>
            </div>
          </div>

          <!-- Translation -->
          <div v-if="!canUpdateTextFields" class="w-full bold">{{ this.selectedLanguageForWebConnect.lnFullText }}</div>
          <div v-if="!canUpdateTextFields" class="flex w-full slogan__headline">
            <div class="w-full">
              <vs-textarea
                class="w-full"
                counter="14"
                maxLength="14"
                height="39.59px"
                :placeholder="$t('vue.step2SloganHeadlinePlaceholder')"
                :counter-danger.sync="counterDangerSloganTitle"
                v-model="tWebConnectDialogTitleDefault"
                :disabled="!hasWhiteLabel"
              />
            </div>
            <div class="ml-2">
              <vx-tooltip :text="$t('vue.step2SloganInputInfo')" position="top">
                <img :src="infoImg" />
              </vx-tooltip>
            </div>
          </div>
        </div>
        <div class="mt-4 step-headline">
          {{ $t('vue.step2Headline') }}
        </div>
        <div class="flex flex-row justify-center items-center">
          <div @click="toggleActivateFontShadow">
            <toggle-switch-icon :enabled="activateFontShadow" :width="40" :height="40" />
          </div>

          <div class="ml-2">{{ $t('vue.activateFontShadow') }}</div>
        </div>
        <div v-if="!canUpdateTextFields" class="w-full bold">English</div>
        <div class="w-full">
          <quill-editor v-model="textStep2" ref="quillEditorA" :options="editorOption"> </quill-editor>
        </div>
        <div v-if="!canUpdateTextFields" class="w-full bold">{{ this.selectedLanguageForWebConnect.lnFullText }}</div>
        <div class="w-full" v-if="!canUpdateTextFields">
          <quill-editor v-model="tWebConnectTextStep2DefaultText" ref="quillEditorA" :options="editorOption"> </quill-editor>
        </div>

        <div class="flex flex-row justify-center items-center mt-4">
          <div @click="toggleMoreInformation">
            <toggle-switch-icon :enabled="isMoreInformationEnabled" :width="40" :height="40" />
          </div>

          <div class="ml-2">{{ $t('vue.customizableMoreInformation') }}</div>
          <vx-tooltip :text="$t('info.moreInformation')" position="top">
            <img :src="infoImg" />
          </vx-tooltip>
        </div>

        <div v-if="isMoreInformationEnabled" class="mt-4 w-full">
          <div v-if="canUpdateTextFields" class="w-full">{{ $t('vue.textMoreInformation') }}</div>
          <div v-if="!canUpdateTextFields" class="w-full bold">{{ $t('vue.textMoreInformation') }} (English)</div>
          <vs-input class="w-full" name="moreInformationButtonText" v-validate="'required'" v-model="moreInformationButtonText" />
          <span class="text-danger text-sm">{{ errors.first('moreInformationButtonText') }}</span>

          <div v-if="!canUpdateTextFields" class="w-full bold">{{ $t('vue.textMoreInformation') }} ({{ this.selectedLanguageForWebConnect.lnFullText }})</div>
          <vs-input
            v-if="!canUpdateTextFields"
            class="w-full"
            name="moreInformationButtonTextTranslation"
            v-validate="'required'"
            v-model="tWebConnectMoreInformation"
          />
          <span class="text-danger text-sm">{{ errors.first('moreInformationButtonTextTranslation') }}</span>
        </div>

        <div v-if="isMoreInformationEnabled" class="mt-4 w-full">
          <vs-input
            :label="$t('vue.link')"
            class="w-full"
            name="moreInformationLink"
            :disabled="!isMoreInformationEnabled"
            v-validate="'required|isURLValid'"
            v-model="moreInformationLink"
            @blur="checkMoreInformationLink"
          />
          <span class="text-danger text-sm">{{ errors.first('moreInformationLink') }}</span>
        </div>
        <div class="mt-4">
          <div class="mt-2">
            <label class="call-to-action">{{ $t('vue.buttonColor') }}</label>
          </div>
          <input class="pa-color-picker" type="color" v-model="dialogStep2ButtonColor" name="confirmButtonColor" />
        </div>
        <div class="mt-4">
          <div class="mt-2">
            <label class="call-to-action">{{ $t('vue.fontColor') }}</label>
          </div>
          <input class="pa-color-picker" type="color" v-model="dialogStep2TextColor" name="step2TextColor" />
        </div>
        <div class="mt-4">
          <div class="mt-2">
            <label class="call-to-action">{{ $t('vue.iconColor') }}</label>
          </div>
          <input class="pa-color-picker" type="color" v-model="dialogStep2IconColor" name="step2IconColor" />
        </div>

        <div class="mt-4 step-headline flex items-center">
          {{ $t('vue.step3Headline') }}
          <vx-tooltip class="ml-4" :text="$t('info.welomeMessageToolTip')" position="top">
            <img :src="infoImg" />
          </vx-tooltip>
        </div>

        <div class="vx-col w-full mt-5">
          <div v-if="!canUpdateTextFields" class="w-full bold">English</div>
          <quill-editor :value="waitingRoomMessage" @input="updateWaitingRoomMessage" ref="quillEditorA" :options="welcomeMessagaEditorOption"> </quill-editor>
        </div>
        <div v-if="!canUpdateTextFields" class="vx-col w-full">
          <div v-if="!canUpdateTextFields" class="w-full bold">{{ this.selectedLanguageForWebConnect.lnFullText }}</div>
          <quill-editor v-model="tWebConnectWaitingRoomDefaultMessage" ref="quillEditorA" :options="welcomeMessagaEditorOption"> </quill-editor>
        </div>
      </form>
      <div class="flex">
        <div class="vx-col lg:w-6/12 w-full mt-2 ml-4">
          <div class="flex flex-column">
            <div class="mb-3" style="align-self: flex-start">{{ $t('vue.preview') }}</div>
            <div class="preview-button-card">
              <div class="flex flex-view">
                <div class="flex flex-column">
                  <div class="step-headline">
                    {{ dialog.type === 'button-card' ? $t('vue.step1Headline') : $t('vue.step1Headline2') }}
                  </div>
                  <button-plus-text-new-preview
                    v-if="dialog && dialog.type === 'button' && dialog.useNewButtonPlusTextStyle"
                    :uploadType="dialog.useOneMediaForAllSteps ? 'allMedia' : 'buttonPlusTextNewMedia'"
                    :uploadSubtype="
                      dialog.useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'buttonPlusTextNewMedia' : ''
                    "
                    :dialog="dialog"
                    :imageURL="defaultImageURL"
                    :embedVideoURL="embedVideoURL"
                  />
                  <button-preview
                    v-else
                    :uploadType="dialog.useOneMediaForAllSteps ? 'allMedia' : 'buttonMedia'"
                    :uploadSubtype="dialog.useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'buttonMedia' : ''"
                    :dialog="dialog"
                    :isForPreviewScreen="false"
                    :canShowMinimizeIcon="canShowMinimizeIcon"
                    :imageURL="defaultImageURL"
                    :embedVideoURL="embedVideoURL"
                  />
                </div>

                <div class="flex flex-column">
                  <div class="step-headline">
                    {{ $t('vue.step2Headline') }}
                  </div>
                  <welcome-screen-preview
                    id="text-and-dialog-type"
                    :uploadType="dialog.useOneMediaForAllSteps ? 'allMedia' : 'welcomeScreenMedia'"
                    :uploadSubtype="
                      dialog.useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'welcomeScreenMedia' : ''
                    "
                    :dialog="dialog"
                    :imageURL="defaultImageURL"
                    :embedVideoURL="embedVideoURL"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
import VuePlainClipboard from 'vue-quill-plain-clipboard'
Quill.register('modules/clipboard', VuePlainClipboard, true)

import { mapGetters, mapActions } from 'vuex'

import ButtonPreview from '@/components/dialog/media/ButtonPreview'
import ButtonPlusTextNewPreview from '@/components/dialog/media/ButtonPlusTextNewPreview'
import WelcomeScreenPreview from '@/components/dialog/media/WelcomeScreenPreview'
import ToggleSwitchIcon from '../icons/ToggleSwitchIcon.vue'
//import InfoIcon from '@/components/icons/InfoIcon.vue'

// VeeValidate
import { Validator } from 'vee-validate'
// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '../../validations/validationDictionary'

export default {
  components: {
    quillEditor,
    ButtonPreview,
    ButtonPlusTextNewPreview,
    WelcomeScreenPreview,
    ToggleSwitchIcon
    //InfoIcon
  },
  props: {
    infoImg: {
      type: String,
      required: true
    },
    dialogId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogTitle: '',
      dialogText: '',
      buttonPlusTextNewMediaIconColor: '',
      confirmButtonText: '',
      cancelButtonText: '',
      sloganTitle: '',
      activateFontShadow: false,
      textStep2: '',
      moreInformationButtonText: '',
      moreInformationLink: '',
      dialogStep2ButtonColor: '',
      dialogStep2TextColor: '',
      dialogStep2IconColor: '',
      isMoreInformationEnabled: false,
      waitingRoomMessage: '',
      counterDangerSloganTitle: false,
      counterDangerSloganSubtitle: false,
      counterDangerPopupText: false,
      defaultSloganTitle: "Let's Connect!",
      imageURL: require('@/assets/images/dialog/contact-form-background.jpeg'),
      defaultImageURL: require('@/assets/images/dialog/contact-form-background.jpeg'),
      videoURL: null,
      transcodedURL: null,
      webm: 'video/webm',
      mp4: 'video/mp4',
      editorOption: {
        modules: {
          toolbar: {
            container: [['bold', 'italic', 'underline', { size: ['small', false, 'large', 'huge'] }], ['link'], [{ color: [] }, { background: [] }], ['clean']]
          }
        }
      },
      welcomeMessagaEditorOption: {
        modules: {
          toolbar: [['bold', 'italic', 'underline']]
        }
      },
      tabKey: Math.random().toString(36).substring(2, 15),

      tWebConnectDialogTitleDefault: '',
      tWebConnectTextStep2DefaultText: '',
      tWebConnectMoreInformation: '',
      tWebConnectWaitingRoomDefaultMessage: '',
      tWebConnectPopupTextDefault: '',
      tWebConnectConfirmButtonTextDefault: '',
      tWebConnectCancelButtonTextDefault: ''
    }
  },
  computed: {
    ...mapGetters({
      hasWhiteLabel: 'hasWhiteLabel',
      dialog: 'dialog',
      selectedLanguageForWebConnect: 'widgetTranslation/selectedLanguageForWebConnect',
      selectedWidgetTranslation: 'widgetTranslation/selectedWidgetTranslation',
      hasLoadedWidgetTranslation: 'widgetTranslation/hasLoadedWidgetTranslation'
    }),
    canUpdateTextFields() {
      if (!this.selectedLanguageForWebConnect || this.selectedLanguageForWebConnect.code === 'en') return true

      return false
    },
    canShowMinimizeIcon() {
      if (((this.dialog && !this.dialog.isAlwaysShowButtonEnabled) || this.dialog.isMinimizeSmallButtonEnabled) && !this.dialog.isSmallButtonPreviewVisible) {
        return true
      }
      return false
    },
    dialogSloganTitle() {
      return this.dialog && this.hasWhiteLabel ? this.dialog.sloganTitle : this.defaultSloganTitle
    },
    embedVideoURL() {
      return this.defaultVideoURL
    },
    step2ButtonColor() {
      return {
        backgroundColor: this.dialogStep2ButtonColor
      }
    },
    defaultVideoURL() {
      return '/Mann-Winken-SD.mp4'
    },
    moreInformationColor() {
      return {
        border: `1px solid ${this.dialogStep2ButtonColor}`,
        color: this.dialogStep2ButtonColor
      }
    },
    step2TextColor() {
      return {
        color: this.dialogStep2TextColor
      }
    }
  },
  watch: {
    hasWhiteLabel() {
      if (this.dialog && !this.hasWhiteLabel) {
        this.updateDialogPartial({ sloganTitle: this.defaultSloganTitle })
      }
    },
    dialogTitle() {
      this.updateDialogPartial({ title: this.dialogTitle })
    },
    dialogText() {
      this.updateDialogPartial({ text: this.dialogText })
    },
    buttonPlusTextNewMediaIconColor() {
      this.updateDialogPartial({ buttonPlusTextNewMediaIconColor: this.buttonPlusTextNewMediaIconColor })
    },
    confirmButtonText() {
      this.updateDialogPartial({ confirmButtonText: this.confirmButtonText })
    },
    cancelButtonText() {
      this.updateDialogPartial({ cancelButtonText: this.cancelButtonText })
    },
    sloganTitle() {
      this.updateDialogPartial({ sloganTitle: this.sloganTitle })
    },
    textStep2() {
      this.updateDialogPartial({ textStep2: this.decodeHTML() })
    },
    moreInformationButtonText() {
      this.updateDialogPartial({ moreInformationButtonText: this.moreInformationButtonText })
    },
    moreInformationLink() {
      this.updateDialogPartial({ moreInformationLink: this.moreInformationLink })
    },
    dialogStep2ButtonColor() {
      this.updateDialogPartial({ step2ButtonColor: this.dialogStep2ButtonColor })
    },
    dialogStep2TextColor() {
      this.updateDialogPartial({ step2TextColor: this.dialogStep2TextColor })
    },
    dialogStep2IconColor() {
      this.updateDialogPartial({ step2IconColor: this.dialogStep2IconColor })
    },
    isMoreInformationEnabled() {
      this.updateDialogPartial({ isMoreInformationEnabled: this.isMoreInformationEnabled })
    },
    hasLoadedWidgetTranslation(newVal) {
      if (newVal) {
        this.loadTranslation()
      }
      this.$nextTick(() => {
        this.errors.clear()
        this.$validator.reset()
      })
    },

    tWebConnectDialogTitleDefault() {
      if (this.tWebConnectDialogTitleDefault !== this.selectedWidgetTranslation.dialogTitleDefault) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ dialogTitleDefault: this.tWebConnectDialogTitleDefault })
      }
    },
    tWebConnectPopupTextDefault() {
      if (this.tWebConnectPopupTextDefault !== this.selectedWidgetTranslation.popupTextDefault) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ popupTextDefault: this.tWebConnectPopupTextDefault })
      }
    },
    tWebConnectConfirmButtonTextDefault() {
      if (this.tWebConnectConfirmButtonTextDefault !== this.selectedWidgetTranslation.confirmButtonTextDefault) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ confirmButtonTextDefault: this.tWebConnectConfirmButtonTextDefault })
      }
    },
    tWebConnectCancelButtonTextDefault() {
      if (this.tWebConnectCancelButtonTextDefault !== this.selectedWidgetTranslation.cancelButtonTextDefault) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ cancelButtonTextDefault: this.tWebConnectCancelButtonTextDefault })
      }
    },

    tWebConnectTextStep2DefaultText() {
      if (this.tWebConnectTextStep2DefaultText !== this.selectedWidgetTranslation.textStep2DefaultText) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ textStep2DefaultText: this.tWebConnectTextStep2DefaultText })
      }
    },
    tWebConnectMoreInformation() {
      if (this.tWebConnectMoreInformation !== this.selectedWidgetTranslation.moreInformation) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ moreInformation: this.tWebConnectMoreInformation })
      }
    },
    tWebConnectWaitingRoomDefaultMessage() {
      if (this.tWebConnectWaitingRoomDefaultMessage !== this.selectedWidgetTranslation.waitingRoomDefaultMessage) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ waitingRoomDefaultMessage: this.tWebConnectWaitingRoomDefaultMessage })
      }
    }
  },
  beforeDestroy() {
    this.$serverBus.$off('validate-step-3')
  },
  mounted() {
    /* Localize validator */
    if (this.dialog) {
      this.dialogTitle = this.dialog.title
      this.dialogText = this.dialog.text
      this.buttonPlusTextNewMediaIconColor = this.dialog.buttonPlusTextNewMediaIconColor || ''
      this.confirmButtonText = this.dialog.confirmButtonText || ''
      this.cancelButtonText = this.dialog.cancelButtonText || ''
      this.sloganTitle = this.dialog.sloganTitle || this.defaultSloganTitle
      this.activateFontShadow = this.dialog.activateFontShadow
      this.textStep2 = this.dialog.textStep2 || ''
      this.moreInformationButtonText = this.dialog.moreInformationButtonText || ''
      this.moreInformationLink = this.dialog.moreInformationLink || null
      this.dialogStep2ButtonColor = this.dialog.step2ButtonColor || ''
      this.dialogStep2TextColor = this.dialog.step2TextColor || ''
      this.dialogStep2IconColor = this.dialog.step2IconColor || ''
      this.isMoreInformationEnabled = Boolean(this.dialog.isMoreInformationEnabled)
      this.waitingRoomMessage = this.dialog.waitingRoomMessage || ''
    }
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
    this.tabKey = Math.random().toString(36).substring(2, 15)
    this.$serverBus.$on('validate-step-3', async () => {
      await this.$validator.validateAll()
    })

    if (this.dialog && typeof this.dialog.activateFontShadow === 'undefined') {
      this.activateFontShadow = true
    }
  },
  methods: {
    ...mapActions({
      updateDialogPartial: 'updateDialogPartial',
      setHasWidgetTranslationUpdated: 'widgetTranslation/setHasWidgetTranslationUpdated',
      updateWidgetTranslation: 'widgetTranslation/updateWidgetTranslation'
    }),

    loadTranslation() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        this.tWebConnectDialogTitleDefault = this.selectedWidgetTranslation.dialogTitleDefault
        this.tWebConnectPopupTextDefault = this.selectedWidgetTranslation.popupTextDefault
        this.tWebConnectConfirmButtonTextDefault = this.selectedWidgetTranslation.confirmButtonTextDefault
        this.tWebConnectCancelButtonTextDefault = this.selectedWidgetTranslation.cancelButtonTextDefault
        this.tWebConnectTextStep2DefaultText = this.selectedWidgetTranslation.textStep2DefaultText
        this.tWebConnectMoreInformation = this.selectedWidgetTranslation.moreInformation
        this.tWebConnectWaitingRoomDefaultMessage = this.selectedWidgetTranslation.waitingRoomDefaultMessage
      }
    },

    updateWaitingRoomMessage(e) {
      this.updateDialogPartial({ waitingRoomMessage: e })
    },
    decodeHTML() {
      const txt = document.createElement('textarea')
      txt.innerHTML = this.textStep2
      return txt.value
    },
    toggleActivateFontShadow() {
      this.activateFontShadow = !this.activateFontShadow
      this.updateDialogPartial({ activateFontShadow: this.activateFontShadow })
    },
    toggleMoreInformation() {
      this.isMoreInformationEnabled = !this.isMoreInformationEnabled
    },
    checkMoreInformationLink() {
      if (this.moreInformationLink.toLowerCase().indexOf('mailto') > -1) {
        return
      }
      if (this.moreInformationLink.toLowerCase().indexOf('tel') > -1) {
        return
      }
      if (!/^https?:\/\//i.test(this.moreInformationLink)) {
        if (this.moreInformationLink) {
          this.moreInformationLink = `https://${this.moreInformationLink}`
        }
      }
    }
  }
}
</script>

<style lang="scss">
.translation {
  &--container {
    background-color: #f4fbff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 54px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border-radius: 6px;
    padding: 5px 15px;
    margin: 10px 0px;
  }
  &--link:before {
    content: '';
    display: inline-block;
    background: url('/img/icons/globe.svg') no-repeat;
    width: 16px;
    height: 16px;
    margin: 0 4px 0 0;
  }
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}
.ql-size-small {
  font-size: 0.75em;
}
.ql-showHtml:after {
  content: '[source]';
}
button.ql-showHtml {
  width: 100% !important;
}
.configure-dialog-step-three {
  .flex-view {
    display: flex;
    @media only screen and (max-width: 1920px) {
      flex-direction: column !important;
    }
    @media only screen and (min-width: 1921px) {
      flex-direction: row !important;
    }
  }

  .pa-button-container {
    min-width: 430px;
  }

  .step-headline {
    color: #262629;
    font-weight: 600;
    font-size: 18px;
  }
  .close-box {
    width: 25px;
    height: 25px;
    background-color: rgba(var(--vs-secondary), 1);
    border-radius: 4px;
  }

  .quill-editor {
    height: 150px;
    .ql-toolbar {
      max-height: 42px;
    }
    .ql-disabled {
      background-color: #eee;
    }
    .ql-container {
      max-height: 108px;
      .ql-editor {
        font-family: 'Lato';
      }
    }
  }

  .flex-align-right {
    margin-left: auto;
  }
  .pa-color-picker {
    width: 300px;
    min-width: 300px;
    max-width: 100%;
    height: 40px;
    cursor: pointer;
  }

  &-image-bk {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      border-radius: 13px;
    }
  }
  &-video-bk {
    position: relative;
    height: 180px;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      border-radius: 13px;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &-image-cover {
    object-fit: cover;
    width: 280px !important;
    height: 180px !important;
    border-radius: 13px !important;
  }
  .button-card-container {
    max-width: 280px !important;
    max-height: 180px !important;
    object-fit: cover;
    border-radius: 13px;
    position: relative;

    video {
      border-radius: 5px;
      object-fit: cover;
      width: 280px;
      height: 180px;
      position: relative;
      top: 0px;
      left: 0px;
    }

    &-online-container {
      z-index: 999;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 2px;
      left: 5px;
      &-online {
        width: 6px;
        height: 6px;
        background-color: #1c8439;
        border-radius: 20px;
      }
    }
    &-close-container {
      z-index: 999;
      width: 18px;
      height: 18px;
      background-color: rgba(var(--vs-secondary), 1);
      position: absolute;
      top: 5px;
      right: 5px;
      border-radius: 3px;
    }
    &-powered-by-container {
      z-index: 999;
      position: absolute;
      bottom: 3px;
      right: 5px;
      text-align: right;
      color: #fff;
      &-header {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 19px;
      }
      &-company {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
      }
    }
  }
}
.vs-input {
  &.slogan-input__headline {
    .vs-input--label {
      padding-left: 0;
    }
  }
  &.slogan-input__subline {
    padding-right: 28px;
  }
}
.slogan__subline {
  padding-right: 28px;
}
.vs-textarea {
  overflow: hidden;
  transition: all 0.3s ease;
  &.disabled,
  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
  &:focus {
    resize: none !important;
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  }
}
</style>

<style lang="scss" scoped>
.ql-disabled {
  background-color: #ccc;
}

.preview-button-card {
  &-right {
    height: 566px;
    width: 280px;
    position: relative;
    border-radius: 13px;

    &-image {
      justify-content: center;
      display: flex;
      flex-direction: row;
      border-radius: 13px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
        border-radius: 13px;
      }
      img {
        min-width: 280px;
        min-height: 566px;
        border-radius: 13px;
        object-fit: cover;
      }
    }

    &-video {
      justify-content: center;
      display: flex;
      flex-direction: row;
      border-radius: 13px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
        border-radius: 13px;
      }
    }

    &-content {
      position: absolute;
      bottom: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &-step2 {
        padding: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        display: inline-block !important;
        margin-bottom: 20px;
      }

      &-action-button {
        width: 75px;
        height: 75px;
        background: #3b86f7;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        color: #fff;
        cursor: pointer;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
      }
      &-action-button:hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
      }

      &-more-information {
        width: 253px;
        height: 35px;
        left: 18px;
        top: 708px;
        border: 1px solid #3b86f7;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 6px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        background: linear-gradient(179.98deg, rgba(0, 0, 0, 0) 0.27%, rgba(0, 0, 0, 0.6) 99.99%);
        cursor: pointer;
      }
      &-more-information:hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
      }

      &-powered-by-container {
        text-align: right;
        color: #fff;
        position: absolute;
        display: flex;
        bottom: 10px;
        flex-direction: column;
        right: 0;
        &-header {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 18px;
          margin-right: 10px;
        }
        &-company {
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
