<template>
  <div class="upload-image-or-video">
    <input
      type="file"
      name="file"
      ref="uploadInput"
      :multiple="false"
      accept=".jpeg,.jpg,.gif,.png, .webm, .mp4"
      @change="detectFile"
      @click="onFileInputClick"
    />
    <div class="upload-image-or-video__wrapper">
      <div class="upload-image-or-video__title">{{ $t('uploadMedia.uploadYourImageOrVideo') }}</div>
      <upload-icon color="#3B86F7" :width="20" :height="20" />
    </div>
  </div>
</template>

<script>
import UploadIcon from '@/components/icons/UploadIcon.vue'
export default {
  name: 'UploadImageOrVideo',
  components: {
    UploadIcon
  },
  props: {
    uploadType: {
      type: String
    }
  },
  methods: {
    detectFile(event) {
      this.$emit('upload-image-or-video', event, this.uploadType)
    },
    onFileInputClick(event) {
      /* makes it possible to send the same file twice,
      one after another
      (the browser caches the change event for performance purposes) */
      event.target.value = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-image-or-video {
  width: 100%;
  max-width: 360px;
  min-height: 100px;
  background: #f5f5f5;
  border: 1px dashed #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &__title {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #575757;
  }
}
</style>
