<template>
  <div class="configure-dialog-step-five">
    <div class="flex-box-column-start">
      <div class="vx-row w-full">
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="headingfont mr-2">{{ $t('vue.getFeedbackFromCustomer') }}</div>
          <vx-tooltip :text="$t('info.getFeedbackFromCustomer')" position="top">
            <img :src="infoImg" />
          </vx-tooltip>
        </vs-col>
        <!-- <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="translation--container">
            <div class="mr-4">
              <info-icon fill="#01AAF4" color="#fff" :width="20" :height="20" />
            </div>
            <div v-html="$t('info.translation')"></div>
          </div>
        </vs-col> -->
      </div>
    </div>
    <div
      class="flex-view"
      :style="{
        '--endscreen-darken-bg-color': feedbackFormSendButtonBackgroundColor
      }"
    >
      <div class="mt-5 mr-6 flex flex-column flex-grow" style="max-width: 640px">
        <div v-if="canUpdateTextFields">{{ $t('vue.sessionEndScreenLabelTitle') }}</div>
        <div v-if="!canUpdateTextFields" class="w-full bold">{{ $t('vue.sessionEndScreenLabelTitle') }} (English)</div>
        <vs-input class="w-full mr-2" v-model="titleClosingMessage" />
        <div v-if="!canUpdateTextFields" class="w-full bold">
          {{ $t('vue.sessionEndScreenLabelTitle') }} ({{ this.selectedLanguageForWebConnect.lnFullText }})
        </div>
        <vs-input v-if="!canUpdateTextFields" class="w-full mr-2" v-model="tWebConnectSessionEndScreenDefaultTitle" />

        <div v-if="canUpdateTextFields" class="mt-4 w-full">{{ $t('vue.sessionEndScreenLabelCopy') }}</div>
        <div v-if="!canUpdateTextFields" class="mt-4 w-full bold">{{ $t('vue.sessionEndScreenLabelCopy') }} (English)</div>
        <vs-textarea counter="300" height="80px" v-model="copyClosingMessage" class="w-full p-1" />
        <div v-if="!canUpdateTextFields" class="w-ful bold">
          {{ $t('vue.sessionEndScreenLabelCopy') }} ({{ this.selectedLanguageForWebConnect.lnFullText }})
        </div>
        <vs-textarea v-if="!canUpdateTextFields" counter="300" height="80px" v-model="tWebConnectSessionEndScreenDefaultCopy" class="w-full p-1" />

        <div class="feedback-form-colors flex flex-row flex-wrap justify-between w-full">
          <div class="flex flex-column flex-grow">
            <div>{{ $t('vue.backgroundColor') }}</div>
            <div>
              <input
                class="pa-color-picker"
                :class="{ 'pa-color-picker--mobile-app': isMobileApp }"
                type="color"
                v-model="backgroundColor"
                name="sessionEndScreenConfirmButtonColor"
              />
            </div>
          </div>
          <div class="flex flex-column flex-grow">
            <div>{{ $t('vue.fontColor') }}</div>
            <div>
              <input
                class="pa-color-picker"
                :class="{ 'pa-color-picker--mobile-app': isMobileApp }"
                type="color"
                v-model="fontColor"
                name="sessionEndScreenFontColor"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-row items-center">
          <div @click="toggleShowFeedbackForm">
            <toggle-switch-icon :enabled="dialog.isShowFeedbackForm" :width="40" :height="40" />
          </div>
          <div class="mx-2" style="font-size: 1.2rem">{{ $t('vue.feedbackForm') }}</div>
          <vx-tooltip v-if="HAS_ACCESS" :text="$t('vue.feedbackFormTooltip')" position="top">
            <img :src="infoImg" />
          </vx-tooltip>

          <vx-tooltip v-else :text="$t('upgrade-messages.locked-feature')" position="top" style="height: 20px">
            <small-lock-icon />
          </vx-tooltip>
        </div>
        <template v-if="dialog.isShowFeedbackForm">
          <div v-if="canUpdateTextFields" class="w-full">{{ $t('vue.feedbackFormQuestion') }} 1</div>
          <div v-if="!canUpdateTextFields" class="w-full bold">{{ $t('vue.feedbackFormQuestion') }} 1: (English)</div>
          <vs-input
            :placeholder="`${$t('vue.feedbackFormQuestion')} 1`"
            class="w-full"
            name="questionFirst"
            :data-vv-as="`${$t('vue.feedbackFormQuestion')} 1`"
            v-model="feedbackFormQuestionFirst"
          />
          <div v-if="!canUpdateTextFields" class="w-full bold">
            {{ $t('vue.feedbackFormQuestion') }} 1: ({{ this.selectedLanguageForWebConnect.lnFullText }})
          </div>
          <vs-input
            v-if="!canUpdateTextFields"
            :placeholder="`${$t('vue.feedbackFormQuestion')} 1`"
            class="w-full"
            name="questionFirstTranslation"
            :data-vv-as="`${$t('vue.feedbackFormQuestion')} 1`"
            v-model="tWebConnectFeedbackFormQuestionFirst"
          />

          <div v-if="canUpdateTextFields" class="w-full mt-4">{{ $t('vue.feedbackFormQuestion') }} 2</div>
          <div v-if="!canUpdateTextFields" class="w-full mt-4 bold">{{ $t('vue.feedbackFormQuestion') }} 2: (English)</div>
          <vs-input
            :placeholder="`${$t('vue.feedbackFormQuestion')} 2`"
            class="w-full"
            name="questionSecond"
            :data-vv-as="`${$t('vue.feedbackFormQuestion')} 2`"
            v-model="feedbackFormQuestionSecond"
          />
          <div v-if="!canUpdateTextFields" class="w-full bold">
            {{ $t('vue.feedbackFormQuestion') }} 2: ({{ this.selectedLanguageForWebConnect.lnFullText }})
          </div>
          <vs-input
            v-if="!canUpdateTextFields"
            :placeholder="`${$t('vue.feedbackFormQuestion')} 2`"
            class="w-full"
            name="questionSecondTranslation"
            :data-vv-as="`${$t('vue.feedbackFormQuestion')} 2`"
            v-model="tWebConnectFeedbackFormQuestionSecond"
          />
          <div v-if="canUpdateTextFields" class="w-full mt-4">{{ $t('vue.feedbackFormQuestion') }} 3</div>
          <div v-if="!canUpdateTextFields" class="w-full mt-4 bold">{{ $t('vue.feedbackFormQuestion') }} 3: (English)</div>
          <vs-input
            :placeholder="`${$t('vue.feedbackFormQuestion')} 3`"
            class="w-full"
            name="questionThird"
            :data-vv-as="`${$t('vue.feedbackFormQuestion')} 3`"
            v-model="feedbackFormQuestionThird"
          />
          <div v-if="!canUpdateTextFields" class="w-full bold">
            {{ $t('vue.feedbackFormQuestion') }} 3: ({{ this.selectedLanguageForWebConnect.lnFullText }})
          </div>
          <vs-input
            v-if="!canUpdateTextFields"
            :placeholder="`${$t('vue.feedbackFormQuestion')} 3`"
            class="w-full"
            name="questionThird"
            :data-vv-as="`${$t('vue.feedbackFormQuestion')} 3`"
            v-model="tWebConnectFeedbackFormQuestionThird"
          />
          <span v-if="dialog.isShowFeedbackForm && isAllQuestionsEmpty && hasSubmittedForm" class="text-danger text-sm mt-2">
            {{ $t('errors.atLeastOneQuestionRequiredForFeedback') }}
          </span>

          <span
            v-if="dialog.isShowFeedbackForm && !canUpdateTextFields && isAllQuestionsInTranslationsEmpty && hasSubmittedForm"
            class="text-danger text-sm mt-2"
          >
            {{ $t('errors.atLeastOneQuestionRequiredForFeedback') }}
          </span>
          <div class="flex flex-row items-center">
            <div @click="toggleSendFeedbackViaMessage">
              <toggle-switch-icon :enabled="dialog.isSendFeedbackViaMessage" :width="40" :height="40" />
            </div>
            <div class="mx-2" style="font-size: 1.2rem">{{ $t('vue.feedbackSendViaMessage') }}</div>
          </div>
        </template>
      </div>
      <div class="flex flex-column relative" style="margin-left: auto">
        <div style="position: absolute; right: 20px; top: 20px; cursor: pointer">
          <close-icon :width="25" :height="25"></close-icon>
        </div>

        <div class="flex justify-center items-center overflow-hidden" :style="feedbackFormStyle">
          <scroll-view ref="chatScrollArea" class="endscreen-scroll-area" style="max-height: 586px">
            <div class="endscreen-feedback__container">
              <div class="endscreen-feedback__container-head">
                <div class="endscreen-feedback__title">
                  {{ dialogSessionEndScreenTitleClosingMessage }}
                </div>
                <div class="mt-4 endscreen-feedback__subtitle">
                  <span>{{ dialogSessionEndScreenCopyClosingMessage }}</span>
                </div>
              </div>
              <div class="endscreen-feedback__form" v-if="dialog.isShowFeedbackForm">
                <div class="endscreen-feedback__field" v-if="dialogFeedbackFormQuestionFirst">
                  <div class="endscreen-feedback__field-title">{{ dialogFeedbackFormQuestionFirst }}</div>
                  <rating-stars :grade="5" :maxStars="5" :disabled="true" />
                </div>
                <div class="endscreen-feedback__field" v-if="dialogFeedbackFormQuestionSecond">
                  <div class="endscreen-feedback__field-title">{{ dialogFeedbackFormQuestionSecond }}</div>
                  <rating-stars :grade="0" :maxStars="5" :disabled="true" />
                </div>
                <div class="endscreen-feedback__field" v-if="dialogFeedbackFormQuestionThird">
                  <div class="endscreen-feedback__field-title">{{ dialogFeedbackFormQuestionThird }}</div>
                  <rating-stars :grade="0" :maxStars="5" :disabled="true" />
                </div>

                <div class="endscreen-feedback__message" v-if="dialog.isSendFeedbackViaMessage">
                  <div class="upper-case">{{ $t('inputs.message') }}...</div>
                </div>
                <template v-if="dialog.isShowFeedbackForm && isAnyFeedbackOptionActive">
                  <div class="endscreen-feedback__action-button flex items-center justify-center" :style="feedbackFormSendButtonBackgroundStyle">
                    {{ $t('vue.sendMessage') }}
                  </div>
                  <div class="endscreen-feedback__cancel-button mt-4 flex items-center justify-center" :style="feedbackFormCloseButtonBackgroundStyle">
                    {{ $t('vue.close') }}
                  </div>
                </template>
              </div>
            </div>
          </scroll-view>
        </div>

        <powered-by />
      </div>
    </div>
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

import { mapGetters, mapActions } from 'vuex'

import { Device } from '@capacitor/device'

import ScrollView from '@blackbp/vue-smooth-scrollbar'
import RatingStars from '@/components/rating-stars/RatingStars.vue'
import CloseIcon from '../icons/CloseIcon.vue'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
import PoweredBy from '@/components/dialog/PoweredBy.vue'
import SmallLockIcon from '@/components/icons/SmallLockIcon.vue'
//import InfoIcon from '@/components/icons/InfoIcon.vue'

export default {
  components: {
    ScrollView,
    CloseIcon,
    ToggleSwitchIcon,
    RatingStars,
    PoweredBy,
    SmallLockIcon
    //InfoIcon
  },
  props: {
    infoImg: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isMobileApp: false,
      logo: require('@/assets/images/logo/logo.svg'),
      titleClosingMessage: '',
      copyClosingMessage: '',
      backgroundColor: '#3B86F7',
      fontColor: '#FFFFFF',
      feedbackFormQuestionFirst: '',
      wasFeedbackFormQuestionFirstEmpty: true,
      feedbackFormQuestionSecond: '',
      wasFeedbackFormQuestionSecondEmpty: true,
      feedbackFormQuestionThird: '',
      wasFeedbackFormQuestionThirdEmpty: true,
      tWebConnectSessionEndScreenDefaultTitle: '',
      tWebConnectSessionEndScreenDefaultCopy: '',
      tWebConnectFeedbackFormQuestionFirst: '',
      tWebConnectFeedbackFormQuestionSecond: '',
      tWebConnectFeedbackFormQuestionThird: '',
      hasSubmittedForm: false
    }
  },
  computed: {
    ...mapGetters({
      hasWhiteLabel: 'hasWhiteLabel',
      dialog: 'dialog',
      company: 'company',
      selectedLanguageForWebConnect: 'widgetTranslation/selectedLanguageForWebConnect',
      selectedWidgetTranslation: 'widgetTranslation/selectedWidgetTranslation',
      hasLoadedWidgetTranslation: 'widgetTranslation/hasLoadedWidgetTranslation'
    }),

    dialogFeedbackFormQuestionFirst() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog && this.dialog.feedbackFormQuestionFirst) return this.dialog.feedbackFormQuestionFirst
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.feedbackFormQuestionFirst
      }

      return ''
    },
    dialogFeedbackFormQuestionSecond() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog && this.dialog.feedbackFormQuestionSecond) return this.dialog.feedbackFormQuestionSecond
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.feedbackFormQuestionSecond
      }

      return ''
    },
    dialogFeedbackFormQuestionThird() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog && this.dialog.feedbackFormQuestionThird) return this.dialog.feedbackFormQuestionThird
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.feedbackFormQuestionThird
      }

      return ''
    },

    dialogSessionEndScreenTitleClosingMessage() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog && this.dialog.sessionEndScreen) return this.dialog.sessionEndScreen.titleClosingMessage
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.sessionEndScreenDefaultTitle
      }

      return ''
    },

    dialogSessionEndScreenCopyClosingMessage() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog && this.dialog.sessionEndScreen) return this.dialog.sessionEndScreen.copyClosingMessage
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.sessionEndScreenDefaultCopy
      }

      return ''
    },

    canUpdateTextFields() {
      if (!this.selectedLanguageForWebConnect || this.selectedLanguageForWebConnect.code === 'en') return true

      return false
    },

    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('contact-form') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    isFirstQuestionEmpty() {
      if (this.dialog.feedbackFormQuestionFirst === '') {
        return true
      }
      return false
    },
    isFirstQuestionTranslationEmpty() {
      if (this.tWebConnectFeedbackFormQuestionFirst === '') {
        return true
      }
      return false
    },
    isSecondQuestionEmpty() {
      if (this.dialog.feedbackFormQuestionSecond === '') {
        return true
      }
      return false
    },
    isSecondQuestionTranslationEmpty() {
      if (this.tWebConnectFeedbackFormQuestionSecond === '') {
        return true
      }
      return false
    },
    isThirdQuestionEmpty() {
      if (this.dialog.feedbackFormQuestionThird === '') {
        return true
      }
      return false
    },
    isThirdQuestionTranslationEmpty() {
      if (this.tWebConnectFeedbackFormQuestionThird === '') {
        return true
      }
      return false
    },

    isAllQuestionsEmpty() {
      return (this.isFirstQuestionEmpty && this.isSecondQuestionEmpty && this.isThirdQuestionEmpty) || false
    },

    isAllQuestionsInTranslationsEmpty() {
      return (this.isFirstQuestionTranslationEmpty && this.isSecondQuestionTranslationEmpty && this.isThirdQuestionTranslationEmpty) || false
    },

    feedbackFormStyle() {
      return {
        width: '280px',
        height: '586px',
        backgroundColor: this.dialog.sessionEndScreen.backgroundColor,
        color: this.dialog.sessionEndScreen.fontColor,
        backgroundPosition: '-280px 0px',
        borderRadius: this.hasWhiteLabel ? '13px' : '13px 13px 0 0',
        '--darken-background': this.dialog.sessionEndScreen.fontColor
      }
    },
    isAnyFeedbackOptionActive() {
      return (
        this.dialog.feedbackFormQuestionFirst ||
        this.dialog.feedbackFormQuestionSecond ||
        this.dialog.feedbackFormQuestionThird ||
        this.dialog.isSendFeedbackViaMessage
      )
    },
    feedbackFormSendButtonBackgroundStyle() {
      return {
        marginTop: '10px',
        backgroundColor: this.feedbackFormSendButtonBackgroundColor
      }
    },
    feedbackFormSendButtonBackgroundColor() {
      return this.lightenDarkenColor(this.dialog.sessionEndScreen.backgroundColor, -16)
    },
    feedbackFormCloseButtonBackgroundStyle() {
      return {
        marginTop: '10px',
        color: this.dialog.sessionEndScreen.backgroundColor,
        backgroundColor: this.feedbackFormCloseButtonBackgroundColor
      }
    },
    feedbackFormCloseButtonBackgroundColor() {
      return this.lightenDarkenColor(this.dialog.sessionEndScreen.fontColor, -16)
    }
  },
  watch: {
    titleClosingMessage() {
      let sessionEndScreen = { ...this.dialog.sessionEndScreen }
      if (!sessionEndScreen) sessionEndScreen = {}
      sessionEndScreen.titleClosingMessage = this.titleClosingMessage
      this.updateDialogPartial({ sessionEndScreen })
    },
    copyClosingMessage() {
      let sessionEndScreen = { ...this.dialog.sessionEndScreen }
      if (!sessionEndScreen) sessionEndScreen = {}
      sessionEndScreen.copyClosingMessage = this.copyClosingMessage
      this.updateDialogPartial({ sessionEndScreen })
    },
    backgroundColor() {
      let sessionEndScreen = { ...this.dialog.sessionEndScreen }
      if (!sessionEndScreen) sessionEndScreen = {}
      sessionEndScreen.backgroundColor = this.backgroundColor
      this.updateDialogPartial({ sessionEndScreen })
    },
    fontColor() {
      let sessionEndScreen = { ...this.dialog.sessionEndScreen }
      if (!sessionEndScreen) sessionEndScreen = {}
      sessionEndScreen.fontColor = this.fontColor
      this.updateDialogPartial({ sessionEndScreen })
    },
    feedbackFormQuestionFirst() {
      this.updateDialogPartial({ feedbackFormQuestionFirst: this.feedbackFormQuestionFirst })
    },
    feedbackFormQuestionSecond() {
      this.updateDialogPartial({ feedbackFormQuestionSecond: this.feedbackFormQuestionSecond })
    },
    feedbackFormQuestionThird() {
      this.updateDialogPartial({ feedbackFormQuestionThird: this.feedbackFormQuestionThird })
    },
    hasLoadedWidgetTranslation(newVal) {
      if (newVal) {
        this.loadTranslation()
      }
      this.$nextTick(() => {
        this.errors.clear()
        this.$validator.reset()
      })
    },
    tWebConnectSessionEndScreenDefaultTitle() {
      if (this.tWebConnectSessionEndScreenDefaultTitle !== this.selectedWidgetTranslation.sessionEndScreenDefaultTitle) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ sessionEndScreenDefaultTitle: this.tWebConnectSessionEndScreenDefaultTitle })
      }
    },
    tWebConnectSessionEndScreenDefaultCopy() {
      if (this.tWebConnectSessionEndScreenDefaultCopy !== this.selectedWidgetTranslation.sessionEndScreenDefaultCopy) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ sessionEndScreenDefaultCopy: this.tWebConnectSessionEndScreenDefaultCopy })
      }
    },
    tWebConnectFeedbackFormQuestionFirst() {
      if (this.tWebConnectFeedbackFormQuestionFirst !== this.selectedWidgetTranslation.feedbackFormQuestionFirst) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ feedbackFormQuestionFirst: this.tWebConnectFeedbackFormQuestionFirst })
      }
    },
    tWebConnectFeedbackFormQuestionSecond() {
      if (this.tWebConnectFeedbackFormQuestionSecond !== this.selectedWidgetTranslation.feedbackFormQuestionSecond) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ feedbackFormQuestionSecond: this.tWebConnectFeedbackFormQuestionSecond })
      }
    },
    tWebConnectFeedbackFormQuestionThird() {
      if (this.tWebConnectFeedbackFormQuestionThird !== this.selectedWidgetTranslation.feedbackFormQuestionThird) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ feedbackFormQuestionThird: this.tWebConnectFeedbackFormQuestionThird })
      }
    }
  },

  created() {
    this.checkIsMobileApp()
  },

  mounted() {
    this.$serverBus.$on('validate-step-5', () => {
      this.$validator.validateAll()
      this.hasSubmittedForm = true
    })

    if (this.dialog) {
      if (this.dialog.sessionEndScreen) {
        this.titleClosingMessage = this.dialog.sessionEndScreen.titleClosingMessage || ''
        this.copyClosingMessage = this.dialog.sessionEndScreen.copyClosingMessage || ''
        this.backgroundColor = this.dialog.sessionEndScreen.backgroundColor || this.backgroundColor
        this.fontColor = this.dialog.sessionEndScreen.fontColor || this.fontColor
      }
      this.feedbackFormQuestionFirst = this.dialog.feedbackFormQuestionFirst || ''
      this.feedbackFormQuestionSecond = this.dialog.feedbackFormQuestionSecond || ''
      this.feedbackFormQuestionThird = this.dialog.feedbackFormQuestionThird || ''

      if (this.feedbackFormQuestionFirst.length > 0) {
        this.wasFeedbackFormQuestionFirstEmpty = false
      }
      if (this.feedbackFormQuestionSecond.length > 0) {
        this.wasFeedbackFormQuestionSecondEmpty = false
      }
      if (this.feedbackFormQuestionThird.length > 0) {
        this.wasFeedbackFormQuestionThirdEmpty = false
      }
    }
  },
  beforeDestroy() {
    this.$serverBus.$off('validate-step-5')
  },
  methods: {
    ...mapActions({
      updateDialogPartial: 'updateDialogPartial',
      setLoadedWidgetTranslation: 'widgetTranslation/setLoadedWidgetTranslation',
      setHasWidgetTranslationUpdated: 'widgetTranslation/setHasWidgetTranslationUpdated',
      updateWidgetTranslation: 'widgetTranslation/updateWidgetTranslation'
    }),
    loadTranslation() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        this.tWebConnectSessionEndScreenDefaultTitle = this.selectedWidgetTranslation.sessionEndScreenDefaultTitle
        this.tWebConnectSessionEndScreenDefaultCopy = this.selectedWidgetTranslation.sessionEndScreenDefaultCopy
        this.tWebConnectFeedbackFormQuestionFirst = this.selectedWidgetTranslation.feedbackFormQuestionFirst
        this.tWebConnectFeedbackFormQuestionSecond = this.selectedWidgetTranslation.feedbackFormQuestionSecond
        this.tWebConnectFeedbackFormQuestionThird = this.selectedWidgetTranslation.feedbackFormQuestionThird
      }
    },
    async checkIsMobileApp() {
      try {
        const { platform } = await Device.getInfo()

        if (['ios', 'android'].includes(platform)) {
          this.isMobileApp = true
        }
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    toggleShowFeedbackForm() {
      if (!this.HAS_ACCESS) {
        return
      }
      const _update = {
        isShowFeedbackForm: !this.dialog.isShowFeedbackForm
      }
      this.updateDialogPartial(_update)
    },
    toggleSendFeedbackViaMessage() {
      const _update = {
        isSendFeedbackViaMessage: !this.dialog.isSendFeedbackViaMessage
      }
      this.updateDialogPartial(_update)
    },
    lightenDarkenColor(color, percent) {
      if (color[0] === '#') {
        color = color.slice(1)
      }

      const num = parseInt(color, 16)
      const amt = Math.round(2.55 * percent)
      const R = (num >> 16) + amt
      const B = ((num >> 8) & 0x00ff) + amt
      const G = (num & 0x0000ff) + amt
      /* eslint-disable no-mixed-operators */
      return `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255))
        .toString(16)
        .slice(1)}`
    }
  }
}
</script>
<style lang="scss">
.endscreen-scroll-area {
  .scrollbar-thumb {
    background: var(--endscreen-darken-bg-color) !important;
  }
}
</style>
<style lang="scss">
.configure-dialog-step-five {
  .pa-color-picker {
    width: 310px;
    min-width: 310px;
    max-width: 100%;
    height: 40px;
    cursor: pointer;

    &--mobile-app {
      width: 40px;
      min-width: 40px;
    }
  }

  .flex-view {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .message-container {
    width: 100%;
    height: auto;

    margin-top: 200px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;

    padding: 10px;
    font-size: 14px;
    line-height: 18px;

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
    }

    &-copy span {
      white-space: pre-wrap;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.endscreen-feedback {
  &__container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 120px 30px 20px 30px;
    font-size: 14px;
    line-height: 18px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(230, 236, 241, 1);
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: var(--darken-background);
      border-radius: 6px;
    }

    &-head {
      margin-bottom: 40px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
  }

  &__subtitle {
    white-space: pre-wrap;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    display: flex;
  }

  &__field {
    margin-bottom: 30px;

    &-title {
      margin-bottom: 20px;
      font-weight: 600;
      color: #fff;
      font-size: 16px;
    }
  }

  &__message {
    display: flex;
    height: 100px;
    width: 100%;
    background-color: rgba(230, 236, 241, 0.8);
    border-radius: 6px;
    color: #12598d;
    padding: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  &__action-button,
  &__cancel-button {
    height: 35px;
    border-radius: 6px;

    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    }
  }

  &__cancel-button {
    box-shadow: unset;
    color: #12598d;
    background: transparent;
  }
}
</style>
