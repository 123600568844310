<template>
  <svg :width="width" :height="height" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="23" rx="6" :fill="color" />
    <path
      d="M25 23V21C25 19.9391 24.5786 18.9217 23.8284 18.1716C23.0783 17.4214 22.0609 17 21 17H13C11.9391 17 10.9217 17.4214 10.1716 18.1716C9.42143 18.9217 9 19.9391 9 21V23"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 13C19.2091 13 21 11.2091 21 9C21 6.79086 19.2091 5 17 5C14.7909 5 13 6.79086 13 9C13 11.2091 14.7909 13 17 13Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ButtonDialogIcon',
  props: {
    color: {
      type: String,
      default: '#575757'
    },
    width: {
      type: Number,
      default: 34
    },
    height: {
      type: Number,
      default: 24
    }
  }
}
</script>
