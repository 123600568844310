var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.tabKey, staticClass: "configure-dialog-step-three" },
    [
      _c("div", { staticClass: "flex-box-column-start" }, [
        _c(
          "div",
          { staticClass: "vx-row w-full" },
          [
            _c(
              "vs-col",
              {
                staticClass: "flex-box-row-start w-full",
                attrs: { "vs-w": "12" },
              },
              [
                _c("div", { staticClass: "headingfont" }, [
                  _vm._v(_vm._s(_vm.$t("vue.customizeYourDialog"))),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "mt-4 flex flex-row" }, [
        _c(
          "form",
          { staticClass: "flex flex-column vx-col lg:w-6/12 w-full mt-2 mr-4" },
          [
            _c("div", { staticClass: "step-headline" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.dialog.type === "button-card"
                      ? _vm.$t("vue.step1Headline")
                      : _vm.$t("vue.step1Headline2")
                  ) +
                  "\n      "
              ),
            ]),
            _vm.dialog.type === "popup"
              ? _c(
                  "div",
                  { staticClass: "vx-col w-full mt-5" },
                  [
                    !_vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full bold" }, [
                          _vm._v("English"),
                        ])
                      : _vm._e(),
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: _vm.$t("inputs.title"), maxlength: "70" },
                      model: {
                        value: _vm.dialogTitle,
                        callback: function ($$v) {
                          _vm.dialogTitle = $$v
                        },
                        expression: "dialogTitle",
                      },
                    }),
                    !_vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full bold" }, [
                          _vm._v(
                            _vm._s(
                              this.selectedLanguageForWebConnect.lnFullText
                            )
                          ),
                        ])
                      : _vm._e(),
                    !_vm.canUpdateTextFields
                      ? _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label: _vm.$t("inputs.title"),
                            maxlength: "70",
                          },
                          model: {
                            value: _vm.tWebConnectDialogTitleDefault,
                            callback: function ($$v) {
                              _vm.tWebConnectDialogTitleDefault = $$v
                            },
                            expression: "tWebConnectDialogTitleDefault",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.dialog.type === "popup"
              ? _c(
                  "div",
                  { staticClass: "vs-col w-full mt-5" },
                  [
                    !_vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full bold" }, [
                          _vm._v("English"),
                        ])
                      : _vm._e(),
                    _c("vs-textarea", {
                      attrs: {
                        counter: "300",
                        height: "100px",
                        label: _vm.$t("vue.popupText"),
                        "counter-danger": _vm.counterDangerPopupText,
                      },
                      on: {
                        "update:counterDanger": function ($event) {
                          _vm.counterDangerPopupText = $event
                        },
                        "update:counter-danger": function ($event) {
                          _vm.counterDangerPopupText = $event
                        },
                      },
                      model: {
                        value: _vm.dialogText,
                        callback: function ($$v) {
                          _vm.dialogText = $$v
                        },
                        expression: "dialogText",
                      },
                    }),
                    !_vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full bold" }, [
                          _vm._v(
                            _vm._s(
                              this.selectedLanguageForWebConnect.lnFullText
                            )
                          ),
                        ])
                      : _vm._e(),
                    !_vm.canUpdateTextFields
                      ? _c("vs-textarea", {
                          attrs: {
                            counter: "300",
                            height: "100px",
                            label: _vm.$t("vue.popupText"),
                            "counter-danger": _vm.counterDangerPopupText,
                          },
                          on: {
                            "update:counterDanger": function ($event) {
                              _vm.counterDangerPopupText = $event
                            },
                            "update:counter-danger": function ($event) {
                              _vm.counterDangerPopupText = $event
                            },
                          },
                          model: {
                            value: _vm.tWebConnectPopupTextDefault,
                            callback: function ($$v) {
                              _vm.tWebConnectPopupTextDefault = $$v
                            },
                            expression: "tWebConnectPopupTextDefault",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.dialog.type === "button"
              ? _c(
                  "div",
                  { staticClass: "vs-col w-full mt-5" },
                  [
                    !_vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full bold" }, [
                          _vm._v("English"),
                        ])
                      : _vm._e(),
                    _c("vs-textarea", {
                      attrs: {
                        counter: "300",
                        height: "100px",
                        label: _vm.$t("vue.speechBubbleText"),
                        "counter-danger": _vm.counterDangerPopupText,
                      },
                      on: {
                        "update:counterDanger": function ($event) {
                          _vm.counterDangerPopupText = $event
                        },
                        "update:counter-danger": function ($event) {
                          _vm.counterDangerPopupText = $event
                        },
                      },
                      model: {
                        value: _vm.dialogText,
                        callback: function ($$v) {
                          _vm.dialogText = $$v
                        },
                        expression: "dialogText",
                      },
                    }),
                    !_vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full bold" }, [
                          _vm._v(
                            _vm._s(
                              this.selectedLanguageForWebConnect.lnFullText
                            )
                          ),
                        ])
                      : _vm._e(),
                    !_vm.canUpdateTextFields
                      ? _c("vs-textarea", {
                          attrs: {
                            counter: "300",
                            height: "100px",
                            label: _vm.$t("vue.speechBubbleText"),
                            "counter-danger": _vm.counterDangerPopupText,
                          },
                          on: {
                            "update:counterDanger": function ($event) {
                              _vm.counterDangerPopupText = $event
                            },
                            "update:counter-danger": function ($event) {
                              _vm.counterDangerPopupText = $event
                            },
                          },
                          model: {
                            value: _vm.tWebConnectPopupTextDefault,
                            callback: function ($$v) {
                              _vm.tWebConnectPopupTextDefault = $$v
                            },
                            expression: "tWebConnectPopupTextDefault",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.dialog.type === "button" && _vm.dialog.useNewButtonPlusTextStyle
              ? _c("div", { staticClass: "mb-4" }, [
                  _c("div", { staticClass: "mt-2" }, [
                    _c("label", { staticClass: "call-to-action" }, [
                      _vm._v(_vm._s(_vm.$t("vue.buttonColor"))),
                    ]),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.buttonPlusTextNewMediaIconColor,
                        expression: "buttonPlusTextNewMediaIconColor",
                      },
                    ],
                    staticClass: "pa-color-picker",
                    attrs: {
                      type: "color",
                      name: "buttonPlusTextNewMediaIconColor",
                    },
                    domProps: { value: _vm.buttonPlusTextNewMediaIconColor },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.buttonPlusTextNewMediaIconColor =
                          $event.target.value
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.dialog.type === "button" &&
            !_vm.dialog.useNewButtonPlusTextStyle
              ? _c(
                  "div",
                  { staticClass: "vs-col w-full" },
                  [
                    !_vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full bold" }, [
                          _vm._v(
                            _vm._s(_vm.$t("inputs.callToAction")) + " (English)"
                          ),
                        ])
                      : _vm._e(),
                    _c("vs-input", {
                      staticClass: "w-full",
                      model: {
                        value: _vm.confirmButtonText,
                        callback: function ($$v) {
                          _vm.confirmButtonText = $$v
                        },
                        expression: "confirmButtonText",
                      },
                    }),
                    !_vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full bold" }, [
                          _vm._v(
                            _vm._s(_vm.$t("inputs.callToAction")) +
                              " (" +
                              _vm._s(
                                this.selectedLanguageForWebConnect.lnFullText
                              ) +
                              ")"
                          ),
                        ])
                      : _vm._e(),
                    !_vm.canUpdateTextFields
                      ? _c("vs-input", {
                          staticClass: "w-full",
                          model: {
                            value: _vm.tWebConnectConfirmButtonTextDefault,
                            callback: function ($$v) {
                              _vm.tWebConnectConfirmButtonTextDefault = $$v
                            },
                            expression: "tWebConnectConfirmButtonTextDefault",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.dialog.type === "popup"
              ? _c("div", { staticClass: "vs-col w-full md:w-1/12" }, [
                  _vm._v(" "),
                ])
              : _vm._e(),
            _vm.dialog.type === "popup"
              ? _c(
                  "div",
                  { staticClass: "vs-col w-full md:w-5/12" },
                  [
                    !_vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full" }, [
                          _vm._v(
                            _vm._s(_vm.$t("inputs.rejectButton")) + " (English)"
                          ),
                        ])
                      : _vm._e(),
                    _c("vs-input", {
                      staticClass: "w-full",
                      model: {
                        value: _vm.cancelButtonText,
                        callback: function ($$v) {
                          _vm.cancelButtonText = $$v
                        },
                        expression: "cancelButtonText",
                      },
                    }),
                    !_vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full" }, [
                          _vm._v(
                            _vm._s(_vm.$t("inputs.callToAction")) +
                              " (" +
                              _vm._s(
                                this.selectedLanguageForWebConnect.lnFullText
                              ) +
                              ")"
                          ),
                        ])
                      : _vm._e(),
                    _c("vs-input", {
                      staticClass: "w-full",
                      model: {
                        value: _vm.tWebConnectCancelButtonTextDefault,
                        callback: function ($$v) {
                          _vm.tWebConnectCancelButtonTextDefault = $$v
                        },
                        expression: "tWebConnectCancelButtonTextDefault",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            (_vm.dialog.type === "button" &&
              !_vm.dialog.useNewButtonPlusTextStyle) ||
            _vm.dialog.type === "button-card"
              ? _c("div", { staticClass: "mt-4 w-full" }, [
                  _c("div", { staticClass: "mb-2 flex items-start" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.hasWhiteLabel,
                            expression: "!hasWhiteLabel",
                          },
                        ],
                        staticClass: "ml-4",
                      },
                      [
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("vue.step2SloganLockInfo"),
                              position: "top",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/elements/lock.svg"),
                                alt: "lock-icon",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  !_vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full bold" }, [
                        _vm._v("English"),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "flex w-full slogan__headline" }, [
                    _c(
                      "div",
                      { staticClass: "w-full" },
                      [
                        _c("vs-textarea", {
                          staticClass: "w-full",
                          attrs: {
                            counter: "14",
                            maxLength: "14",
                            height: "39.59px",
                            placeholder: _vm.$t(
                              "vue.step2SloganHeadlinePlaceholder"
                            ),
                            "counter-danger": _vm.counterDangerSloganTitle,
                            disabled: !_vm.hasWhiteLabel,
                          },
                          on: {
                            "update:counterDanger": function ($event) {
                              _vm.counterDangerSloganTitle = $event
                            },
                            "update:counter-danger": function ($event) {
                              _vm.counterDangerSloganTitle = $event
                            },
                          },
                          model: {
                            value: _vm.sloganTitle,
                            callback: function ($$v) {
                              _vm.sloganTitle = $$v
                            },
                            expression: "sloganTitle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-2" },
                      [
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t("vue.step2SloganInputInfo"),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImg } })]
                        ),
                      ],
                      1
                    ),
                  ]),
                  !_vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full bold" }, [
                        _vm._v(
                          _vm._s(this.selectedLanguageForWebConnect.lnFullText)
                        ),
                      ])
                    : _vm._e(),
                  !_vm.canUpdateTextFields
                    ? _c(
                        "div",
                        { staticClass: "flex w-full slogan__headline" },
                        [
                          _c(
                            "div",
                            { staticClass: "w-full" },
                            [
                              _c("vs-textarea", {
                                staticClass: "w-full",
                                attrs: {
                                  counter: "14",
                                  maxLength: "14",
                                  height: "39.59px",
                                  placeholder: _vm.$t(
                                    "vue.step2SloganHeadlinePlaceholder"
                                  ),
                                  "counter-danger":
                                    _vm.counterDangerSloganTitle,
                                  disabled: !_vm.hasWhiteLabel,
                                },
                                on: {
                                  "update:counterDanger": function ($event) {
                                    _vm.counterDangerSloganTitle = $event
                                  },
                                  "update:counter-danger": function ($event) {
                                    _vm.counterDangerSloganTitle = $event
                                  },
                                },
                                model: {
                                  value: _vm.tWebConnectDialogTitleDefault,
                                  callback: function ($$v) {
                                    _vm.tWebConnectDialogTitleDefault = $$v
                                  },
                                  expression: "tWebConnectDialogTitleDefault",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-2" },
                            [
                              _c(
                                "vx-tooltip",
                                {
                                  attrs: {
                                    text: _vm.$t("vue.step2SloganInputInfo"),
                                    position: "top",
                                  },
                                },
                                [_c("img", { attrs: { src: _vm.infoImg } })]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _c("div", { staticClass: "mt-4 step-headline" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("vue.step2Headline")) + "\n      "
              ),
            ]),
            _c(
              "div",
              { staticClass: "flex flex-row justify-center items-center" },
              [
                _c(
                  "div",
                  { on: { click: _vm.toggleActivateFontShadow } },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        enabled: _vm.activateFontShadow,
                        width: 40,
                        height: 40,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "ml-2" }, [
                  _vm._v(_vm._s(_vm.$t("vue.activateFontShadow"))),
                ]),
              ]
            ),
            !_vm.canUpdateTextFields
              ? _c("div", { staticClass: "w-full bold" }, [_vm._v("English")])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("quill-editor", {
                  ref: "quillEditorA",
                  attrs: { options: _vm.editorOption },
                  model: {
                    value: _vm.textStep2,
                    callback: function ($$v) {
                      _vm.textStep2 = $$v
                    },
                    expression: "textStep2",
                  },
                }),
              ],
              1
            ),
            !_vm.canUpdateTextFields
              ? _c("div", { staticClass: "w-full bold" }, [
                  _vm._v(_vm._s(this.selectedLanguageForWebConnect.lnFullText)),
                ])
              : _vm._e(),
            !_vm.canUpdateTextFields
              ? _c(
                  "div",
                  { staticClass: "w-full" },
                  [
                    _c("quill-editor", {
                      ref: "quillEditorA",
                      attrs: { options: _vm.editorOption },
                      model: {
                        value: _vm.tWebConnectTextStep2DefaultText,
                        callback: function ($$v) {
                          _vm.tWebConnectTextStep2DefaultText = $$v
                        },
                        expression: "tWebConnectTextStep2DefaultText",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "flex flex-row justify-center items-center mt-4" },
              [
                _c(
                  "div",
                  { on: { click: _vm.toggleMoreInformation } },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        enabled: _vm.isMoreInformationEnabled,
                        width: 40,
                        height: 40,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "ml-2" }, [
                  _vm._v(_vm._s(_vm.$t("vue.customizableMoreInformation"))),
                ]),
                _c(
                  "vx-tooltip",
                  {
                    attrs: {
                      text: _vm.$t("info.moreInformation"),
                      position: "top",
                    },
                  },
                  [_c("img", { attrs: { src: _vm.infoImg } })]
                ),
              ],
              1
            ),
            _vm.isMoreInformationEnabled
              ? _c(
                  "div",
                  { staticClass: "mt-4 w-full" },
                  [
                    _vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full" }, [
                          _vm._v(_vm._s(_vm.$t("vue.textMoreInformation"))),
                        ])
                      : _vm._e(),
                    !_vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full bold" }, [
                          _vm._v(
                            _vm._s(_vm.$t("vue.textMoreInformation")) +
                              " (English)"
                          ),
                        ])
                      : _vm._e(),
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { name: "moreInformationButtonText" },
                      model: {
                        value: _vm.moreInformationButtonText,
                        callback: function ($$v) {
                          _vm.moreInformationButtonText = $$v
                        },
                        expression: "moreInformationButtonText",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(
                        _vm._s(_vm.errors.first("moreInformationButtonText"))
                      ),
                    ]),
                    !_vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full bold" }, [
                          _vm._v(
                            _vm._s(_vm.$t("vue.textMoreInformation")) +
                              " (" +
                              _vm._s(
                                this.selectedLanguageForWebConnect.lnFullText
                              ) +
                              ")"
                          ),
                        ])
                      : _vm._e(),
                    !_vm.canUpdateTextFields
                      ? _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: {
                            name: "moreInformationButtonTextTranslation",
                          },
                          model: {
                            value: _vm.tWebConnectMoreInformation,
                            callback: function ($$v) {
                              _vm.tWebConnectMoreInformation = $$v
                            },
                            expression: "tWebConnectMoreInformation",
                          },
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(
                        _vm._s(
                          _vm.errors.first(
                            "moreInformationButtonTextTranslation"
                          )
                        )
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isMoreInformationEnabled
              ? _c(
                  "div",
                  { staticClass: "mt-4 w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|isURLValid",
                          expression: "'required|isURLValid'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        label: _vm.$t("vue.link"),
                        name: "moreInformationLink",
                        disabled: !_vm.isMoreInformationEnabled,
                      },
                      on: { blur: _vm.checkMoreInformationLink },
                      model: {
                        value: _vm.moreInformationLink,
                        callback: function ($$v) {
                          _vm.moreInformationLink = $$v
                        },
                        expression: "moreInformationLink",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("moreInformationLink"))),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "mt-4" }, [
              _c("div", { staticClass: "mt-2" }, [
                _c("label", { staticClass: "call-to-action" }, [
                  _vm._v(_vm._s(_vm.$t("vue.buttonColor"))),
                ]),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.dialogStep2ButtonColor,
                    expression: "dialogStep2ButtonColor",
                  },
                ],
                staticClass: "pa-color-picker",
                attrs: { type: "color", name: "confirmButtonColor" },
                domProps: { value: _vm.dialogStep2ButtonColor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.dialogStep2ButtonColor = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "mt-4" }, [
              _c("div", { staticClass: "mt-2" }, [
                _c("label", { staticClass: "call-to-action" }, [
                  _vm._v(_vm._s(_vm.$t("vue.fontColor"))),
                ]),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.dialogStep2TextColor,
                    expression: "dialogStep2TextColor",
                  },
                ],
                staticClass: "pa-color-picker",
                attrs: { type: "color", name: "step2TextColor" },
                domProps: { value: _vm.dialogStep2TextColor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.dialogStep2TextColor = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "mt-4" }, [
              _c("div", { staticClass: "mt-2" }, [
                _c("label", { staticClass: "call-to-action" }, [
                  _vm._v(_vm._s(_vm.$t("vue.iconColor"))),
                ]),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.dialogStep2IconColor,
                    expression: "dialogStep2IconColor",
                  },
                ],
                staticClass: "pa-color-picker",
                attrs: { type: "color", name: "step2IconColor" },
                domProps: { value: _vm.dialogStep2IconColor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.dialogStep2IconColor = $event.target.value
                  },
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "mt-4 step-headline flex items-center" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("vue.step3Headline")) +
                    "\n        "
                ),
                _c(
                  "vx-tooltip",
                  {
                    staticClass: "ml-4",
                    attrs: {
                      text: _vm.$t("info.welomeMessageToolTip"),
                      position: "top",
                    },
                  },
                  [_c("img", { attrs: { src: _vm.infoImg } })]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full mt-5" },
              [
                !_vm.canUpdateTextFields
                  ? _c("div", { staticClass: "w-full bold" }, [
                      _vm._v("English"),
                    ])
                  : _vm._e(),
                _c("quill-editor", {
                  ref: "quillEditorA",
                  attrs: {
                    value: _vm.waitingRoomMessage,
                    options: _vm.welcomeMessagaEditorOption,
                  },
                  on: { input: _vm.updateWaitingRoomMessage },
                }),
              ],
              1
            ),
            !_vm.canUpdateTextFields
              ? _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    !_vm.canUpdateTextFields
                      ? _c("div", { staticClass: "w-full bold" }, [
                          _vm._v(
                            _vm._s(
                              this.selectedLanguageForWebConnect.lnFullText
                            )
                          ),
                        ])
                      : _vm._e(),
                    _c("quill-editor", {
                      ref: "quillEditorA",
                      attrs: { options: _vm.welcomeMessagaEditorOption },
                      model: {
                        value: _vm.tWebConnectWaitingRoomDefaultMessage,
                        callback: function ($$v) {
                          _vm.tWebConnectWaitingRoomDefaultMessage = $$v
                        },
                        expression: "tWebConnectWaitingRoomDefaultMessage",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "vx-col lg:w-6/12 w-full mt-2 ml-4" }, [
            _c("div", { staticClass: "flex flex-column" }, [
              _c(
                "div",
                {
                  staticClass: "mb-3",
                  staticStyle: { "align-self": "flex-start" },
                },
                [_vm._v(_vm._s(_vm.$t("vue.preview")))]
              ),
              _c("div", { staticClass: "preview-button-card" }, [
                _c("div", { staticClass: "flex flex-view" }, [
                  _c(
                    "div",
                    { staticClass: "flex flex-column" },
                    [
                      _c("div", { staticClass: "step-headline" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.dialog.type === "button-card"
                                ? _vm.$t("vue.step1Headline")
                                : _vm.$t("vue.step1Headline2")
                            ) +
                            "\n                "
                        ),
                      ]),
                      _vm.dialog &&
                      _vm.dialog.type === "button" &&
                      _vm.dialog.useNewButtonPlusTextStyle
                        ? _c("button-plus-text-new-preview", {
                            attrs: {
                              uploadType: _vm.dialog.useOneMediaForAllSteps
                                ? "allMedia"
                                : "buttonPlusTextNewMedia",
                              uploadSubtype:
                                _vm.dialog.useOneMediaForAllSteps &&
                                typeof _vm.dialog.allMedia === "object" &&
                                _vm.dialog.allMedia !== null
                                  ? "buttonPlusTextNewMedia"
                                  : "",
                              dialog: _vm.dialog,
                              imageURL: _vm.defaultImageURL,
                              embedVideoURL: _vm.embedVideoURL,
                            },
                          })
                        : _c("button-preview", {
                            attrs: {
                              uploadType: _vm.dialog.useOneMediaForAllSteps
                                ? "allMedia"
                                : "buttonMedia",
                              uploadSubtype:
                                _vm.dialog.useOneMediaForAllSteps &&
                                typeof _vm.dialog.allMedia === "object" &&
                                _vm.dialog.allMedia !== null
                                  ? "buttonMedia"
                                  : "",
                              dialog: _vm.dialog,
                              isForPreviewScreen: false,
                              canShowMinimizeIcon: _vm.canShowMinimizeIcon,
                              imageURL: _vm.defaultImageURL,
                              embedVideoURL: _vm.embedVideoURL,
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex flex-column" },
                    [
                      _c("div", { staticClass: "step-headline" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$t("vue.step2Headline")) +
                            "\n                "
                        ),
                      ]),
                      _c("welcome-screen-preview", {
                        attrs: {
                          id: "text-and-dialog-type",
                          uploadType: _vm.dialog.useOneMediaForAllSteps
                            ? "allMedia"
                            : "welcomeScreenMedia",
                          uploadSubtype:
                            _vm.dialog.useOneMediaForAllSteps &&
                            typeof _vm.dialog.allMedia === "object" &&
                            _vm.dialog.allMedia !== null
                              ? "welcomeScreenMedia"
                              : "",
                          dialog: _vm.dialog,
                          imageURL: _vm.defaultImageURL,
                          embedVideoURL: _vm.embedVideoURL,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }