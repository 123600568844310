<template>
  <svg width="153" height="107" viewBox="0 0 153 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_4861_2)">
      <rect x="4" width="145" height="98" rx="6" fill="white" />
      <rect x="4.75" y="0.75" width="143.5" height="96.5" rx="5.25" stroke="#A9A9A9" stroke-width="1.5" />
    </g>
    <line x1="4" y1="21.25" x2="149" y2="21.25" stroke="#A9A9A9" stroke-width="1.5" />
    <circle cx="18" cy="11" r="3" fill="#A9A9A9" />
    <circle cx="18" cy="11" r="3" fill="#A9A9A9" />
    <circle cx="34" cy="11" r="3" fill="#A9A9A9" />
    <circle cx="34" cy="11" r="3" fill="#A9A9A9" />
    <circle cx="50" cy="11" r="3" fill="#A9A9A9" />
    <circle cx="50" cy="11" r="3" fill="#A9A9A9" />
    <defs>
      <filter id="filter0_d_4861_2" x="0" y="0" width="153" height="106" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4861_2" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4861_2" result="shape" />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'DialogTypeImageIcon',
  props: {
    color: {
      type: String,
      default: '#A9A9A9'
    },
    width: {
      type: Number,
      default: 153
    },
    height: {
      type: Number,
      default: 107
    }
  }
}
</script>
