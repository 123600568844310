<template>
  <div>
    <div
      id="paPreviewEmbedCode"
      class="pa-dialog-popup-container w-full"
      :key="previewKey"
      :style="`background-image: url(${previewUrl});min-width:${buttonContainerMinWidth}px;position:relative;`"
    >
      <div class="blur-img"></div>
      <div class="pa-button-container">
        <button-plus-text-new-preview
          v-if="dialog && dialog.type === 'button' && dialog.useNewButtonPlusTextStyle"
          :isForPreviewScreen="true"
          :uploadType="uploadType"
          :uploadSubtype="uploadSubtype"
          :dialog="dialog"
          :canShowMinimizeIcon="canShowMinimizeIcon"
          :imageURL="imageURL"
          :embedVideoURL="embedVideoURL"
          :smallButtonImage="smallButtonImage"
          :isDisplayOptions="true"
        />
        <button-preview
          v-else
          :isForPreviewScreen="true"
          :uploadType="uploadType"
          :uploadSubtype="uploadSubtype"
          :dialog="dialog"
          :canShowMinimizeIcon="canShowMinimizeIcon"
          :imageURL="imageURL"
          :embedVideoURL="embedVideoURL"
          :smallButtonImage="smallButtonImage"
          :isDisplayOptions="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ButtonPreview from '@/components/dialog/media/ButtonPreview'
import ButtonPlusTextNewPreview from '@/components/dialog/media/ButtonPlusTextNewPreview'
export default {
  components: {
    ButtonPreview,
    ButtonPlusTextNewPreview
  },
  props: {
    dialog: {
      type: Object,
      required: true
    },
    buttonContainerMinWidth: {
      type: Number,
      required: true
    },
    embedVideoURL: {
      type: String,
      required: false,
      default: null
    },
    imageURL: {
      type: String,
      required: false,
      default: null
    },
    smallButtonImage: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      previewUrl: require('@/assets/images/dialog/preview-url.png'),
      previewKey: Math.random().toString(36).substring(2, 15)
    }
  },
  computed: {
    canShowMinimizeIcon() {
      if (((this.dialog && !this.dialog.isAlwaysShowButtonEnabled) || this.dialog.isMinimizeSmallButtonEnabled) && !this.dialog.isSmallButtonPreviewVisible) {
        return true
      }
      return false
    },
    uploadSubtype() {
      if (this.dialog && !this.dialog.showDefaultMedia) {
        if (
          this.dialog &&
          this.dialog.useOneMediaForAllSteps &&
          this.dialog.allMedia &&
          typeof this.dialog.allMedia === 'object' &&
          this.dialog.allMedia !== null
        ) {
          if (!this.dialog.useNewButtonPlusTextStyle) {
            if (!this.dialog.isAlwaysShowButtonEnabled && !this.dialog.isSmallButtonPreviewVisible) {
              return 'buttonMedia'
            } else if (this.dialog.isAlwaysShowButtonEnabled && !this.dialog.isSmallButtonPreviewVisible) {
              return 'smallButtonMedia'
            }
          } else {
            return 'buttonPlusTextNewMedia'
          }
        }
      }
      return null
    },
    uploadType() {
      if (this.dialog && !this.dialog.showDefaultMedia) {
        if (this.dialog && this.dialog.useOneMediaForAllSteps && this.dialog.allMedia) {
          return 'allMedia'
        } else if (this.dialog && !this.dialog.useOneMediaForAllSteps) {
          if (!this.dialog.useNewButtonPlusTextStyle) {
            if (!this.dialog.isAlwaysShowButtonEnabled && this.dialog.buttonMedia) {
              return 'buttonMedia'
            } else if (this.dialog.isAlwaysShowButtonEnabled && this.dialog.smallButtonMedia) {
              return 'smallButtonMedia'
            }
          } else {
            return 'buttonPlusTextNewMedia'
          }
        }
        return null
      }
      return null
    }
  },
  mounted() {
    this.$serverBus.$on('update-dialog-show-media', () => {
      this.previewKey = Math.random().toString(36).substring(2, 15)
    })
    this.$serverBus.$on('update-button-preview', () => {
      this.previewKey = Math.random().toString(36).substring(2, 15)
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('update-dialog-show-media')
    this.$serverBus.$off('update-button-preview')
  }
}
</script>
