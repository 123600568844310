var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "153",
        height: "107",
        viewBox: "0 0 153 107",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { filter: "url(#filter0_d_4861_2)" } }, [
        _c("rect", {
          attrs: { x: "4", width: "145", height: "98", rx: "6", fill: "white" },
        }),
        _c("rect", {
          attrs: {
            x: "4.75",
            y: "0.75",
            width: "143.5",
            height: "96.5",
            rx: "5.25",
            stroke: "#A9A9A9",
            "stroke-width": "1.5",
          },
        }),
      ]),
      _c("line", {
        attrs: {
          x1: "4",
          y1: "21.25",
          x2: "149",
          y2: "21.25",
          stroke: "#A9A9A9",
          "stroke-width": "1.5",
        },
      }),
      _c("circle", { attrs: { cx: "18", cy: "11", r: "3", fill: "#A9A9A9" } }),
      _c("circle", { attrs: { cx: "18", cy: "11", r: "3", fill: "#A9A9A9" } }),
      _c("circle", { attrs: { cx: "34", cy: "11", r: "3", fill: "#A9A9A9" } }),
      _c("circle", { attrs: { cx: "34", cy: "11", r: "3", fill: "#A9A9A9" } }),
      _c("circle", { attrs: { cx: "50", cy: "11", r: "3", fill: "#A9A9A9" } }),
      _c("circle", { attrs: { cx: "50", cy: "11", r: "3", fill: "#A9A9A9" } }),
      _c("defs", [
        _c(
          "filter",
          {
            attrs: {
              id: "filter0_d_4861_2",
              x: "0",
              y: "0",
              width: "153",
              height: "106",
              filterUnits: "userSpaceOnUse",
              "color-interpolation-filters": "sRGB",
            },
          },
          [
            _c("feFlood", {
              attrs: { "flood-opacity": "0", result: "BackgroundImageFix" },
            }),
            _c("feColorMatrix", {
              attrs: {
                in: "SourceAlpha",
                type: "matrix",
                values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                result: "hardAlpha",
              },
            }),
            _c("feOffset", { attrs: { dy: "4" } }),
            _c("feGaussianBlur", { attrs: { stdDeviation: "2" } }),
            _c("feComposite", { attrs: { in2: "hardAlpha", operator: "out" } }),
            _c("feColorMatrix", {
              attrs: {
                type: "matrix",
                values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0",
              },
            }),
            _c("feBlend", {
              attrs: {
                mode: "normal",
                in2: "BackgroundImageFix",
                result: "effect1_dropShadow_4861_2",
              },
            }),
            _c("feBlend", {
              attrs: {
                mode: "normal",
                in: "SourceGraphic",
                in2: "effect1_dropShadow_4861_2",
                result: "shape",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }