var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload-image-or-video" }, [
    _c("input", {
      ref: "uploadInput",
      attrs: {
        type: "file",
        name: "file",
        multiple: false,
        accept: ".jpeg,.jpg,.gif,.png, .webm, .mp4",
      },
      on: { change: _vm.detectFile, click: _vm.onFileInputClick },
    }),
    _c(
      "div",
      { staticClass: "upload-image-or-video__wrapper" },
      [
        _c("div", { staticClass: "upload-image-or-video__title" }, [
          _vm._v(_vm._s(_vm.$t("uploadMedia.uploadYourImageOrVideo"))),
        ]),
        _c("upload-icon", {
          attrs: { color: "#3B86F7", width: 20, height: 20 },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }