<template>
  <div class="flex flex-col relative">
    <div class="preview-button-card-right">
      <div style="position: absolute; right: 10px; top: 10px; z-index: 99">
        <div class="close-box flex justify-center items-center" style="background: none">
          <close-icon :width="25" :height="25" :color="dialog.step2ButtonColor"></close-icon>
        </div>
      </div>
      <template v-if="showDialogDefaultMedia">
        <div v-if="dialog && !dialog.showVideo" class="preview-button-card-right-image">
          <img :src="imageURL" />
        </div>
        <div v-else class="preview-button-card-right-video">
          <video
            style="border-radius: 13px; object-fit: cover; width: 280px; height: 566px; position: relative; top: 0; left: 0"
            v-if="embedVideoURL"
            playsinline
            autoplay
            muted
            loop
            :key="mediaKey"
          >
            <source v-if="embedVideoURL.indexOf('.webm') > -1" :src="embedVideoURL" :type="webm" />
            <source v-if="embedVideoURL.indexOf('.mp4') > -1" :src="embedVideoURL" :type="mp4" />
          </video>
        </div>
      </template>
      <template v-else>
        <template v-if="mediaImg">
          <div v-if="mediaImg.indexOf('.webm') > -1 || mediaImg.indexOf('.mp4') > -1" class="preview-button-card-right-video">
            <video
              v-if="id === 's2-display-options' && dialog.activateVideoSound"
              :id="`${id}-welcome-screen`"
              class="welcome-screen-media"
              ref="embedVideo"
              style="border-radius: 13px; object-fit: cover; width: 280px; height: 566px; position: relative; top: 0; left: 0"
              autoplay
              :key="uploadType"
            >
              <source v-if="mediaImg.indexOf('.webm') > -1" :src="mediaImg" :type="webm" />
              <source v-if="mediaImg.indexOf('.mp4') > -1" :src="mediaImg" :type="mp4" />
            </video>
            <video
              v-else
              :id="`${id}-welcome-screen`"
              class="welcome-screen-media"
              ref="embedVideo"
              style="border-radius: 13px; object-fit: cover; width: 280px; height: 566px; position: relative; top: 0; left: 0"
              playsinline
              autoplay
              muted
              loop
              :key="uploadType"
            >
              <source v-if="mediaImg.indexOf('.webm') > -1" :src="mediaImg" :type="webm" />
              <source v-if="mediaImg.indexOf('.mp4') > -1" :src="mediaImg" :type="mp4" />
            </video>
          </div>

          <div v-else class="preview-button-card-right-image">
            <img :src="mediaImg" />
          </div>
        </template>
      </template>

      <div class="preview-button-card-right-content" :style="step2TextColor">
        <div class="flex preview-button-card-right-content-step2" :style="chatSelectionStyle" v-html="dialogTextStep2"></div>

        <div v-if="showRoutingScreenPreview" style="width: 280px">
          <div class="preview-button-card-routing" v-for="(row, index) in routeCommunicationOptions" :key="index" style="padding: 0px 17px">
            <div class="button-container">
              <button
                v-for="(item, i) in row"
                :key="i"
                :style="{ width: item.width, ...step2ButtonColor, ...step2IconColor }"
                class="preview-button-card-right-content-action-button"
              >
                {{ item.displayText }}
              </button>
            </div>
          </div>
        </div>

        <div v-else class="flex justify-evenly" style="width: 280px; justify-content: space-evenly; padding: 0px 17px">
          <div
            v-if="!dialog.communicationOptions || dialog.communicationOptions.chat"
            class="preview-button-card-right-content-action-button flex flex-column justify-center items-center"
            :style="step2ButtonColor"
          >
            <div>
              <chat-icon-2 :width="20" :height="20" :color="dialog.step2IconColor"></chat-icon-2>
            </div>
            <div :style="step2IconColor">Chat</div>
          </div>
          <div
            v-if="!dialog.communicationOptions || dialog.communicationOptions.audio"
            class="preview-button-card-right-content-action-button flex flex-column justify-center items-center"
            :style="step2ButtonColor"
          >
            <div>
              <vertical-phone-icon :width="20" :height="20" :color="dialog.step2IconColor"></vertical-phone-icon>
            </div>
            <div :style="step2IconColor">Phone</div>
          </div>

          <div
            v-if="!dialog.communicationOptions || dialog.communicationOptions.video"
            class="preview-button-card-right-content-action-button flex flex-column justify-center items-center"
            :style="step2ButtonColor"
          >
            <div>
              <video-call-icon :width="20" :height="20" :color="dialog.step2IconColor"></video-call-icon>
            </div>
            <div :style="step2IconColor">Video</div>
          </div>
        </div>

        <div class="flex justify-center items-center" style="width: 280px; margin-top: 20px" v-if="dialog.isMoreInformationEnabled">
          <a :href="dialog.moreInformationLink" target="_blank">
            <div class="preview-button-card-right-content-more-information flex justify-center items-center" :style="moreInformationColor">
              {{ dialogMoreInformationButtonText || $t('vue.moreInformation') }}
            </div>
          </a>
        </div>
      </div>
    </div>
    <powered-by class="absolute bottom-0" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ChatIcon2 from '@/components/icons/ChatIcon2.vue'
import VerticalPhoneIcon from '@/components/icons/VerticalPhoneIcon.vue'
import VideoCallIcon from '@/components/icons/VideoCallIcon.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import PoweredBy from '@/components/dialog/PoweredBy.vue'

export default {
  name: 'WelcomeScreenPreview',
  components: {
    ChatIcon2,
    VerticalPhoneIcon,
    VideoCallIcon,
    CloseIcon,
    PoweredBy
  },
  props: {
    id: {
      type: String
    },
    uploadType: {
      type: String
    },
    uploadSubtype: {
      type: String
    },
    imageURL: {
      type: String,
      required: false,
      default: null
    },
    embedVideoURL: {
      type: String,
      required: true
    },
    showRoutingScreenPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      webm: 'video/webm',
      mp4: 'video/mp4'
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog',
      hasWhiteLabel: 'hasWhiteLabel',
      selectedLanguageForWebConnect: 'widgetTranslation/selectedLanguageForWebConnect',
      selectedWidgetTranslation: 'widgetTranslation/selectedWidgetTranslation'
    }),
    dialogMoreInformationButtonText() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog) return this.dialog.moreInformationButtonText
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.moreInformation
      }

      return ''
    },
    dialogTextStep2() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog) return this.dialog.textStep2
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.textStep2DefaultText
      }

      return ''
    },
    chatSelectionStyle() {
      if (this.dialog && (typeof this.dialog.activateFontShadow === 'undefined' || this.dialog.activateFontShadow)) {
        return { textShadow: '0px 0px 8px #000000' }
      }
      return null
    },
    showDialogDefaultMedia() {
      if (this.dialog && this.dialog.showDefaultMedia) {
        return true
      }
      return false
    },
    mediaKey() {
      const showVideo = this.dialog && this.dialog.showVideo
      return `${this.id}_${this.uploadType}_${this.showDialogDefaultMedia}_${showVideo}`
    },
    mediaImg() {
      if (this.dialog) {
        if (this.showDialogDefaultMedia) {
          return this.dialog.showVideo ? this.embedVideoURL : this.imageURL
        }
        if (this.uploadType) {
          if (this.uploadSubtype) {
            return this.dialog[this.uploadType][this.uploadSubtype]
          } else {
            return this.dialog[this.uploadType] ? this.dialog[this.uploadType] : this.imageURL
          }
        }
      }

      return this.imageURL
    },
    dialogSloganTitle() {
      return this.dialog && this.hasWhiteLabel ? this.dialog.sloganTitle : this.defaultSloganTitle
    },
    moreInformationColor() {
      return {
        border: `1px solid ${this.dialog.step2ButtonColor}`,
        color: this.dialog.step2ButtonColor
      }
    },
    step2ButtonColor() {
      return {
        backgroundColor: this.dialog.step2ButtonColor
      }
    },
    step2TextColor() {
      return {
        color: this.dialog.step2TextColor
      }
    },
    step2IconColor() {
      return {
        color: this.dialog.step2IconColor
      }
    },
    routeCommunicationOptions() {
      if (!this.dialog.routeCommunicationOptions || this.dialog.routeCommunicationOptions.length === 0) return []

      const result = []
      const itemWidth = this.dialog.routeCommunicationOptions.length <= 4 ? '236px' : '113px'
      const itemsPerRow = this.dialog.routeCommunicationOptions.length <= 4 ? 1 : 2

      for (let i = 0; i < this.dialog.routeCommunicationOptions.length; i += itemsPerRow) {
        result.push(this.dialog.routeCommunicationOptions.slice(i, i + itemsPerRow).map((name) => ({ ...name, width: itemWidth })))
      }

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.close-box {
  width: 25px;
  height: 25px;
  border-radius: 4px;
}
.preview-button-card {
  &-routing {
    display: flex;
    margin-bottom: 10px;

    .button-container {
      display: flex;
      justify-content: space-between;
      flex: 1;
    }
  }

  &-right {
    height: 566px;
    width: 280px;
    position: relative;
    border-radius: 13px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px 0px;

    &-image {
      justify-content: center;
      display: flex;
      flex-direction: row;
      border-radius: 13px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.178125) 48.44%, rgba(0, 0, 0, 0.6) 100%);
        border-radius: 13px;
      }
      img {
        min-width: 280px;
        min-height: 566px;
        border-radius: 13px;
        object-fit: cover;
      }
    }

    &-video {
      justify-content: center;
      display: flex;
      flex-direction: row;
      border-radius: 13px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.178125) 48.44%, rgba(0, 0, 0, 0.6) 100%);
        border-radius: 13px;
      }
    }

    &-content {
      position: absolute;
      bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &-step2 {
        padding: 10px 20px 20px 20px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        display: inline-block !important;
        margin-bottom: 20px;
        line-height: 1.2;
      }

      &-action-button {
        margin: 5px 5px 0px 5px;
        height: 71px;
        background: #3b86f7;
        border-radius: 13px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        border: none;
        color: #fff;
        cursor: pointer;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        width: 100%;
        max-width: 236px; // fallback width
      }
      &-action-button:hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
      }

      &-more-information {
        width: 235px;
        height: 35px;
        left: 18px;
        top: 708px;
        border: 1px solid #3b86f7;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 6px;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        background: linear-gradient(179.98deg, rgba(0, 0, 0, 0) 0.27%, rgba(0, 0, 0, 0.6) 99.99%);
        cursor: pointer;
      }
      &-more-information:hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
      }

      &-powered-by-container {
        text-align: center;
        color: #fff;
        position: absolute;
        display: flex;
        bottom: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        &-header {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
