var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "media-preview" },
    [
      _c(
        "div",
        {
          staticClass: "media-preview__delete",
          on: {
            click: function ($event) {
              return _vm.$emit("media-delete")
            },
          },
        },
        [
          _c(
            "vx-tooltip",
            {
              staticClass: "flex items-center justify-center w-full h-full",
              attrs: { text: _vm.$t("uploadMedia.delete"), position: "top" },
            },
            [_c("trash-icon", { attrs: { width: 12, height: 12 } })],
            1
          ),
        ],
        1
      ),
      _vm.mediaImg
        ? [
            _vm.mediaImg.indexOf(".webm") > -1 ||
            _vm.mediaImg.indexOf(".mp4") > -1
              ? _c("div", { staticClass: "media-preview__video-container" }, [
                  _c(
                    "video",
                    {
                      key: _vm.mediaKey,
                      staticClass: "media-preview__video",
                      attrs: {
                        playsinline: "",
                        autoplay: "",
                        muted: "",
                        loop: "",
                      },
                      domProps: { muted: true },
                    },
                    [
                      _vm.mediaImg.indexOf(".webm") > -1
                        ? _c("source", {
                            attrs: { src: _vm.mediaImg, type: _vm.webm },
                          })
                        : _vm._e(),
                      _vm.mediaImg.indexOf(".mp4") > -1
                        ? _c("source", {
                            attrs: { src: _vm.mediaImg, type: _vm.mp4 },
                          })
                        : _vm._e(),
                    ]
                  ),
                ])
              : _c("div", { staticClass: "media-preview__img-container" }, [
                  _c("img", {
                    staticClass: "media-preview__img",
                    attrs: { src: _vm.mediaImg },
                  }),
                ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }